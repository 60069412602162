import { startCase, replace } from 'lodash';

export const titleize = (value?: string) => {
  if (!value) return '';
  return startCase(replace(value, '_', ' '));
};

export const sentenceize = (value?: string) => {
  if (!value) return '';
  value.toLowerCase();
  return replace(value, '_', ' ');
};
