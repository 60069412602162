import FoolPng from "app/assets/images/cards/tarotV1/fool.png";
import MagicianPng from "app/assets/images/cards/tarotV1/magician.png";
import HighPriestessPng from "app/assets/images/cards/tarotV1/highPriestess.png";
import EmpressPng from "app/assets/images/cards/tarotV1/empress.png";
import EmperorPng from "app/assets/images/cards/tarotV1/emperor.png";
import HierophantPng from "app/assets/images/cards/tarotV1/hierophant.png";
import LoversPng from "app/assets/images/cards/tarotV1/lovers.png";
import ChariotPng from "app/assets/images/cards/tarotV1/chariot.png";
import StrengthPng from "app/assets/images/cards/tarotV1/strength.png";
import HermitPng from "app/assets/images/cards/tarotV1/hermit.png";
import WheelOfFortunePng from "app/assets/images/cards/tarotV1/wheelOfFortune.png";
import JusticePng from "app/assets/images/cards/tarotV1/justice.png";
import HangedManPng from "app/assets/images/cards/tarotV1/hangedMan.png";
import DeathPng from "app/assets/images/cards/tarotV1/death.png";
import TemperancePng from "app/assets/images/cards/tarotV1/temperance.png";
import DevilPng from "app/assets/images/cards/tarotV1/devil.png";
import TowerPng from "app/assets/images/cards/tarotV1/tower.png";
import StarPng from "app/assets/images/cards/tarotV1/star.png";
import MoonPng from "app/assets/images/cards/tarotV1/moon.png";
import SunPng from "app/assets/images/cards/tarotV1/sun.png";
import JudgementPng from "app/assets/images/cards/tarotV1/judgement.png";
import WorldPng from "app/assets/images/cards/tarotV1/world.png";

// Minor Arcana - Ace to Ten for each suit
import AceOfCupsPng from "app/assets/images/cards/tarotV1/aceOfCups.png";
import AceOfPentaclesPng from "app/assets/images/cards/tarotV1/aceOfPentacles.png";
import AceOfSwordsPng from "app/assets/images/cards/tarotV1/aceOfSwords.png";
import AceOfWandsPng from "app/assets/images/cards/tarotV1/aceOfWands.png";

import TwoOfCupsPng from "app/assets/images/cards/tarotV1/2OfCups.png";
import TwoOfPentaclesPng from "app/assets/images/cards/tarotV1/2OfPentacles.png";
import TwoOfSwordsPng from "app/assets/images/cards/tarotV1/2OfSwords.png";
import TwoOfWandsPng from "app/assets/images/cards/tarotV1/2OfWands.png";

import ThreeOfCupsPng from "app/assets/images/cards/tarotV1/3OfCups.png";
import ThreeOfPentaclesPng from "app/assets/images/cards/tarotV1/3OfPentacles.png";
import ThreeOfSwordsPng from "app/assets/images/cards/tarotV1/3OfSwords.png";
import ThreeOfWandsPng from "app/assets/images/cards/tarotV1/3OfWands.png";

import FourOfCupsPng from "app/assets/images/cards/tarotV1/4OfCups.png";
import FourOfPentaclesPng from "app/assets/images/cards/tarotV1/4OfPentacles.png";
import FourOfSwordsPng from "app/assets/images/cards/tarotV1/4OfSwords.png";
import FourOfWandsPng from "app/assets/images/cards/tarotV1/4OfWands.png";

import FiveOfCupsPng from "app/assets/images/cards/tarotV1/5OfCups.png";
import FiveOfPentaclesPng from "app/assets/images/cards/tarotV1/5OfPentacles.png";
import FiveOfSwordsPng from "app/assets/images/cards/tarotV1/5OfSwords.png";
import FiveOfWandsPng from "app/assets/images/cards/tarotV1/5OfWands.png";

import SixOfCupsPng from "app/assets/images/cards/tarotV1/6OfCups.png";
import SixOfPentaclesPng from "app/assets/images/cards/tarotV1/6OfPentacles.png";
import SixOfSwordsPng from "app/assets/images/cards/tarotV1/6OfSwords.png";
import SixOfWandsPng from "app/assets/images/cards/tarotV1/6OfWands.png";

import SevenOfCupsPng from "app/assets/images/cards/tarotV1/7OfCups.png";
import SevenOfPentaclesPng from "app/assets/images/cards/tarotV1/7OfPentacles.png";
import SevenOfSwordsPng from "app/assets/images/cards/tarotV1/7OfSwords.png";
import SevenOfWandsPng from "app/assets/images/cards/tarotV1/7OfWands.png";

import EightOfCupsPng from "app/assets/images/cards/tarotV1/8OfCups.png";
import EightOfPentaclesPng from "app/assets/images/cards/tarotV1/8OfPentacles.png";
import EightOfSwordsPng from "app/assets/images/cards/tarotV1/8OfSwords.png";
import EightOfWandsPng from "app/assets/images/cards/tarotV1/8OfWands.png";

import NineOfCupsPng from "app/assets/images/cards/tarotV1/9OfCups.png";
import NineOfPentaclesPng from "app/assets/images/cards/tarotV1/9OfPentacles.png";
import NineOfSwordsPng from "app/assets/images/cards/tarotV1/9OfSwords.png";
import NineOfWandsPng from "app/assets/images/cards/tarotV1/9OfWands.png";

import TenOfCupsPng from "app/assets/images/cards/tarotV1/10OfCups.png";
import TenOfPentaclesPng from "app/assets/images/cards/tarotV1/10OfPentacles.png";
import TenOfSwordsPng from "app/assets/images/cards/tarotV1/10OfSwords.png";
import TenOfWandsPng from "app/assets/images/cards/tarotV1/10OfWands.png";

// Minor Arcana - Page, Knight, Queen, King
import PageOfCupsPng from "app/assets/images/cards/tarotV1/pageOfCups.png";
import PageOfPentaclesPng from "app/assets/images/cards/tarotV1/pageOfPentacles.png";
import PageOfSwordsPng from "app/assets/images/cards/tarotV1/pageOfSwords.png";
import PageOfWandsPng from "app/assets/images/cards/tarotV1/pageOfWands.png";

import KnightOfCupsPng from "app/assets/images/cards/tarotV1/knightOfCups.png";
import KnightOfPentaclesPng from "app/assets/images/cards/tarotV1/knightOfPentacles.png";
import KnightOfSwordsPng from "app/assets/images/cards/tarotV1/knightOfSwords.png";
import KnightOfWandsPng from "app/assets/images/cards/tarotV1/knightOfWands.png";

import QueenOfCupsPng from "app/assets/images/cards/tarotV1/queenOfCups.png";
import QueenOfPentaclesPng from "app/assets/images/cards/tarotV1/queenOfPentacles.png";
import QueenOfSwordsPng from "app/assets/images/cards/tarotV1/queenOfSwords.png";
import QueenOfWandsPng from "app/assets/images/cards/tarotV1/queenOfWands.png";

import KingOfCupsPng from "app/assets/images/cards/tarotV1/kingOfCups.png";
import KingOfPentaclesPng from "app/assets/images/cards/tarotV1/kingOfPentacles.png";
import KingOfSwordsPng from "app/assets/images/cards/tarotV1/kingOfSwords.png";
import KingOfWandsPng from "app/assets/images/cards/tarotV1/kingOfWands.png";
import { TFunction } from "i18next";

export const tarotCardDictionary = (
  t: TFunction<"common", "tarotcardDictionary">
) => [
  {
    name: t("the_fool"),
    key: "the_fool",
    number: 0,
    image: FoolPng,
    description: t("the_fool_description"),
  },
  {
    name: t("the_magician"),
    key: "the_magician",
    number: 1,
    image: MagicianPng,
    description: t("the_magician_description"),
  },
  {
    name: t("the_high_priestess"),
    key: "the_high_priestess",
    number: 2,
    image: HighPriestessPng,
    description: t("the_high_priestess_description"),
  },
  {
    name: t("the_empress"),
    key: "the_empress",
    number: 3,
    image: EmpressPng,
    description: t("the_empress_description"),
  },
  {
    name: t("the_emperor"),
    key: "the_emperor",
    number: 4,
    image: EmperorPng,
    description: t("the_emperor_description"),
  },
  {
    name: t("the_hierophant"),
    key: "the_hierophant",
    number: 5,
    image: HierophantPng,
    description: t("the_hierophant_description"),
  },
  {
    name: t("the_lovers"),
    key: "the_lovers",
    number: 6,
    image: LoversPng,
    description: t("the_lovers_description"),
  },
  {
    name: t("the_chariot"),
    key: "the_chariot",
    number: 7,
    image: ChariotPng,
    description: t("the_chariot_description"),
  },
  {
    name: t("strength"),
    key: "strength",
    number: 8,
    image: StrengthPng,
    description: t("strength_description"),
  },
  {
    name: t("the_hermit"),
    key: "the_hermit",
    number: 9,
    image: HermitPng,
    description: t("the_hermit_description"),
  },
  {
    name: t("wheel_of_fortune"),
    key: "wheel_of_fortune",
    number: 10,
    image: WheelOfFortunePng,
    description: t("wheel_of_fortune_description"),
  },
  {
    name: t("justice"),
    key: "justice",
    number: 11,
    image: JusticePng,
    description: t("justice_description"),
  },
  {
    name: t("the_hanged_man"),
    key: "the_hanged_man",
    number: 12,
    image: HangedManPng,
    description: t("the_hanged_man_description"),
  },
  {
    name: t("death"),
    key: "death",
    number: 13,
    image: DeathPng,
    description: t("death_description"),
  },
  {
    name: t("temperance"),
    key: "temperance",
    number: 14,
    image: TemperancePng,
    description: t("temperance_description"),
  },
  {
    name: t("the_devil"),
    key: "the_devil",
    number: 15,
    image: DevilPng,
    description: t("the_devil_description"),
  },
  {
    name: t("the_tower"),
    key: "the_tower",
    number: 16,
    image: TowerPng,
    description: t("the_tower_description"),
  },
  {
    name: t("the_star"),
    key: "the_star",
    number: 17,
    image: StarPng,
    description: t("the_star_description"),
  },
  {
    name: t("the_moon"),
    key: "the_moon",
    number: 18,
    image: MoonPng,
    description: t("the_moon_description"),
  },
  {
    name: t("the_sun"),
    key: "the_sun",
    number: 19,
    image: SunPng,
    description: t("joy"),
  },
  {
    name: t("judgement"),
    key: "judgement",
    number: 20,
    image: JudgementPng,
    description: t("decision"),
  },
  {
    name: t("the_world"),
    key: "the_world",
    number: 21,
    image: WorldPng,
    description: t("completion"),
  },
  {
    name: t("ace_of_wands"),
    key: "ace_of_wands",
    number: 22,
    image: AceOfWandsPng,
    description: t("spark"),
  },
  {
    name: t("two_of_wands"),
    key: "two_of_wands",
    number: 23,
    image: TwoOfWandsPng,
    description: t("planning"),
  },
  {
    name: t("three_of_wands"),
    key: "three_of_wands",
    number: 24,
    image: ThreeOfWandsPng,
    description: t("expansion"),
  },
  {
    name: t("four_of_wands"),
    key: "four_of_wands",
    number: 25,
    image: FourOfWandsPng,
    description: t("celebration"),
  },
  {
    name: t("five_of_wands"),
    key: "five_of_wands",
    number: 26,
    image: FiveOfWandsPng,
    description: t("arguments"),
  },
  {
    name: t("six_of_wands"),
    key: "six_of_wands",
    number: 27,
    image: SixOfWandsPng,
    description: t("victory"),
  },
  {
    name: t("seven_of_wands"),
    key: "seven_of_wands",
    number: 28,
    image: SevenOfWandsPng,
    description: t("stand_up"),
  },
  {
    name: t("eight_of_wands"),
    key: "eight_of_wands",
    number: 29,
    image: EightOfWandsPng,
    description: t("fast"),
  },
  {
    name: t("nine_of_wands"),
    key: "nine_of_wands",
    number: 30,
    image: NineOfWandsPng,
    description: t("protection"),
  },
  {
    name: t("ten_of_wands"),
    key: "ten_of_wands",
    number: 31,
    image: TenOfWandsPng,
    description: t("share_load"),
  },
  {
    name: t("page_of_wands"),
    key: "page_of_wands",
    number: 32,
    image: PageOfWandsPng,
    description: t("message"),
  },
  {
    name: t("knight_of_wands"),
    key: "knight_of_wands",
    number: 33,
    image: KnightOfWandsPng,
    description: t("adventure"),
  },
  {
    name: t("queen_of_wands"),
    key: "queen_of_wands",
    number: 34,
    image: QueenOfWandsPng,
    description: t("attraction"),
  },
  {
    name: t("king_of_wands"),
    key: "king_of_wands",
    number: 35,
    image: KingOfWandsPng,
    description: t("leader"),
  },
  {
    name: t("ace_of_cups"),
    key: "ace_of_cups",
    number: 36,
    image: AceOfCupsPng,
    description: t("new_love"),
  },
  {
    name: t("two_of_cups"),
    key: "two_of_cups",
    number: 37,
    image: TwoOfCupsPng,
    description: t("partnership"),
  },
  {
    name: t("three_of_cups"),
    key: "three_of_cups",
    number: 38,
    image: ThreeOfCupsPng,
    description: t("celebration_friends"),
  },
  {
    name: t("four_of_cups"),
    key: "four_of_cups",
    number: 39,
    image: FourOfCupsPng,
    description: t("missed_opportunity"),
  },
  {
    name: t("five_of_cups"),
    key: "five_of_cups",
    number: 40,
    image: FiveOfCupsPng,
    description: t("sadness_in_love"),
  },
  {
    name: t("six_of_cups"),
    key: "six_of_cups",
    number: 41,
    image: SixOfCupsPng,
    description: t("sweet_love_from_past"),
  },
  {
    name: t("seven_of_cups"),
    key: "seven_of_cups",
    number: 42,
    image: SevenOfCupsPng,
    description: t("lots_of_choices_in_love"),
  },
  {
    name: t("eight_of_cups"),
    key: "eight_of_cups",
    number: 43,
    image: EightOfCupsPng,
    description: t("walking_away_from_unhappy_love"),
  },
  {
    name: t("nine_of_cups"),
    key: "nine_of_cups",
    number: 44,
    image: NineOfCupsPng,
    description: t("fulfilling_love_life"),
  },
  {
    name: t("ten_of_cups"),
    key: "ten_of_cups",
    number: 45,
    image: TenOfCupsPng,
    description: t("happy_family_and_love"),
  },
  {
    name: t("page_of_cups"),
    key: "page_of_cups",
    number: 46,
    image: PageOfCupsPng,
    description: t("sweet_message"),
  },
  {
    name: t("knight_of_cups"),
    key: "knight_of_cups",
    number: 47,
    image: KnightOfCupsPng,
    description: t("charming_romantic_offer"),
  },
  {
    name: t("queen_of_cups"),
    key: "queen_of_cups",
    number: 48,
    image: QueenOfCupsPng,
    description: t("caring_heart_attracks_love"),
  },
  {
    name: t("king_of_cups"),
    key: "king_of_cups",
    number: 49,
    image: KingOfCupsPng,
    description: t("emotionally_mature_person"),
  },
  {
    name: t("ace_of_swords"),
    key: "ace_of_swords",
    number: 50,
    image: AceOfSwordsPng,
    description: t("clarity_and_truth_needed"),
  },
  {
    name: t("two_of_swords"),
    key: "two_of_swords",
    number: 51,
    image: TwoOfSwordsPng,
    description: t("choice_in_love"),
  },
  {
    name: t("three_of_swords"),
    key: "three_of_swords",
    number: 52,
    image: ThreeOfSwordsPng,
    description: t("heartbreak_is_painful"),
  },
  {
    name: t("four_of_swords"),
    key: "four_of_swords",
    number: 53,
    image: FourOfSwordsPng,
    description: t("take_a_break_to_heal"),
  },
  {
    name: t("five_of_swords"),
    key: "five_of_swords",
    number: 54,
    image: FiveOfSwordsPng,
    description: t("arguments_hurt_love"),
  },
  {
    name: t("six_of_swords"),
    key: "six_of_swords",
    number: 55,
    image: SixOfSwordsPng,
    description: t("moving_to_better_love"),
  },
  {
    name: t("seven_of_swords"),
    key: "seven_of_swords",
    number: 56,
    image: SevenOfSwordsPng,
    description: t("seven_of_swords_description"),
  },
  {
    name: t("eight_of_swords"),
    key: "eight_of_swords",
    number: 57,
    image: EightOfSwordsPng,
    description: t("eight_of_swords_description"),
  },
  {
    name: t("nine_of_swords"),
    key: "nine_of_swords",
    number: 58,
    image: NineOfSwordsPng,
    description: t("nine_of_swords_description"),
  },
  {
    name: t("ten_of_swords"),
    key: "ten_of_swords",
    number: 59,
    image: TenOfSwordsPng,
    description: t("ten_of_swords_description"),
  },
  {
    name: t("page_of_swords"),
    key: "page_of_swords",
    number: 60,
    image: PageOfSwordsPng,
    description: t("page_of_swords_description"),
  },
  {
    name: t("knight_of_swords"),
    key: "knight_of_swords",
    number: 61,
    image: KnightOfSwordsPng,
    description: t("knight_of_swords_description"),
  },
  {
    name: t("queen_of_swords"),
    key: "queen_of_swords",
    number: 62,
    image: QueenOfSwordsPng,
    description: t("queen_of_swords_description"),
  },
  {
    name: t("king_of_swords"),
    key: "king_of_swords",
    number: 63,
    image: KingOfSwordsPng,
    description: t("king_of_swords_description"),
  },
  {
    name: t("ace_of_pentacles"),
    key: "ace_of_pentacles",
    number: 64,
    image: AceOfPentaclesPng,
    description: t("ace_of_pentacles_description"),
  },
  {
    name: t("two_of_pentacles"),
    key: "two_of_pentacles",
    number: 65,
    image: TwoOfPentaclesPng,
    description: t("two_of_pentacles_description"),
  },
  {
    name: t("three_of_pentacles"),
    key: "three_of_pentacles",
    number: 66,
    image: ThreeOfPentaclesPng,
    description: t("three_of_pentacles_description"),
  },
  {
    name: t("four_of_pentacles"),
    key: "four_of_pentacles",
    number: 67,
    image: FourOfPentaclesPng,
    description: t("four_of_pentacles_description"),
  },
  {
    name: t("five_of_pentacles"),
    key: "five_of_pentacles",
    number: 68,
    image: FiveOfPentaclesPng,
    description: t("five_of_pentacles_description"),
  },
  {
    name: t("six_of_pentacles"),
    key: "six_of_pentacles",
    number: 69,
    image: SixOfPentaclesPng,
    description: t("six_of_pentacles_description"),
  },
  {
    name: t("seven_of_pentacles"),
    key: "seven_of_pentacles",
    number: 70,
    image: SevenOfPentaclesPng,
    description: t("seven_of_pentacles_description"),
  },
  {
    name: t("eight_of_pentacles"),
    key: "eight_of_pentacles",
    number: 71,
    image: EightOfPentaclesPng,
    description: t("eight_of_pentacles_description"),
  },
  {
    name: t("nine_of_pentacles"),
    key: "nine_of_pentacles",
    number: 72,
    image: NineOfPentaclesPng,
    description: t("nine_of_pentacles_description"),
  },
  {
    name: t("ten_of_pentacles"),
    key: "ten_of_pentacles",
    number: 73,
    image: TenOfPentaclesPng,
    description: t("ten_of_pentacles_description"),
  },
  {
    name: t("page_of_pentacles"),
    key: "page_of_pentacles",
    number: 74,
    image: PageOfPentaclesPng,
    description: t("page_of_pentacles_description"),
  },
  {
    name: t("knight_of_pentacles"),
    key: "knight_of_pentacles",
    number: 75,
    image: KnightOfPentaclesPng,
    description: t("knight_of_pentacles_description"),
  },
  {
    name: t("queen_of_pentacles"),
    key: "queen_of_pentacles",
    number: 76,
    image: QueenOfPentaclesPng,
    description: t("queen_of_pentacles_description"),
  },
  {
    name: t("king_of_pentacles"),
    key: "king_of_pentacles",
    number: 77,
    image: KingOfPentaclesPng,
    description: t("king_of_pentacles_description"),
  },
];

export const tarotCardDictionary2 = (
  t: TFunction<"common", "tarotcardDictionary">
) => [
  {
    name: t("the_fool"),
    key: "the_fool",
    number: 0,
    description: t("tarotGuidance.the_fool"),
  },
  {
    name: t("the_magician"),
    key: "the_magician",
    number: 1,
    description: t("tarotGuidance.the_magician"),
  },
  {
    name: t("the_high_priestess"),
    key: "the_high_priestess",
    number: 2,
    description: t("tarotGuidance.the_high_priestess"),
  },
  {
    name: t("the_empress"),
    key: "the_empress",
    number: 3,
    description: t("tarotGuidance.the_empress"),
  },
  {
    name: t("the_emperor"),
    key: "the_emperor",
    number: 4,
    description: t("tarotGuidance.the_emperor"),
  },
  {
    name: t("the_hierophant"),
    key: "the_hierophant",
    number: 5,
    description: t("tarotGuidance.the_hierophant"),
  },
  {
    name: t("the_lovers"),
    key: "the_lovers",
    number: 6,
    description: t("tarotGuidance.the_lovers"),
  },
  {
    name: t("the_chariot"),
    key: "the_chariot",
    number: 7,
    description: t("tarotGuidance.the_chariot"),
  },
  {
    name: t("strength"),
    key: "strength",
    number: 8,
    description: t("tarotGuidance.strength"),
  },
  {
    name: t("the_hermit"),
    key: "the_hermit",
    number: 9,
    description: t("tarotGuidance.the_hermit"),
  },
  {
    name: t("wheel_of_fortune"),
    key: "wheel_of_fortune",
    number: 10,
    description: t("tarotGuidance.wheel_of_fortune"),
  },
  {
    name: t("justice"),
    key: "justice",
    number: 11,
    description: t("tarotGuidance.justice"),
  },
  {
    name: t("the_hanged_man"),
    key: "the_hanged_man",
    number: 12,
    description: t("tarotGuidance.the_hanged_man"),
  },
  {
    name: t("death"),
    key: "death",
    number: 13,
    description: t("tarotGuidance.death"),
  },
  {
    name: t("temperance"),
    key: "temperance",
    number: 14,
    description: t("tarotGuidance.temperance"),
  },
  {
    name: t("the_devil"),
    key: "the_devil",
    number: 15,
    description: t("tarotGuidance.the_devil"),
  },
  {
    name: t("the_tower"),
    key: "the_tower",
    number: 16,
    description: t("tarotGuidance.the_tower"),
  },
  {
    name: t("the_star"),
    key: "the_star",
    number: 17,
    description: t("tarotGuidance.the_star"),
  },
  {
    name: t("the_moon"),
    key: "the_moon",
    number: 18,
    description: t("tarotGuidance.the_moon"),
  },
  {
    name: t("the_sun"),
    key: "the_sun",
    number: 19,
    description: t("tarotGuidance.the_sun"),
  },
  {
    name: t("judgement"),
    key: "judgement",
    number: 20,
    description: t("tarotGuidance.judgement"),
  },
  {
    name: t("the_world"),
    key: "the_world",
    number: 21,
    description: t("tarotGuidance.the_world"),
  },
  {
    name: t("ace_of_wands"),
    key: "ace_of_wands",
    number: 22,
    description: t("wands.ace_of_wands"),
  },
  {
    name: t("two_of_wands"),
    key: "two_of_wands",
    number: 23,
    description: t("wands.two_of_wands"),
  },
  {
    name: t("three_of_wands"),
    key: "three_of_wands",
    number: 24,
    description: t("wands.three_of_wands"),
  },
  {
    name: t("four_of_wands"),
    key: "four_of_wands",
    number: 25,
    description: t("wands.four_of_wands"),
  },
  {
    name: t("five_of_wands"),
    key: "five_of_wands",
    number: 26,
    description: t("wands.five_of_wands"),
  },
  {
    name: t("six_of_wands"),
    key: "six_of_wands",
    number: 27,
    description: t("wands.six_of_wands"),
  },
  {
    name: t("seven_of_wands"),
    key: "seven_of_wands",
    number: 28,
    description: t("wands.seven_of_wands"),
  },
  {
    name: t("eight_of_wands"),
    key: "eight_of_wands",
    number: 29,
    description: t("wands.eight_of_wands"),
  },
  {
    name: t("nine_of_wands"),
    key: "nine_of_wands",
    number: 30,
    description: t("wands.nine_of_wands"),
  },
  {
    name: t("ten_of_wands"),
    key: "ten_of_wands",
    number: 31,
    description: t("wands.ten_of_wands"),
  },
  {
    name: t("page_of_wands"),
    key: "page_of_wands",
    number: 32,
    description: t("wands.page_of_wands"),
  },
  {
    name: t("knight_of_wands"),
    key: "knight_of_wands",
    number: 33,
    description: t("wands.knight_of_wands"),
  },
  {
    name: t("queen_of_wands"),
    key: "queen_of_wands",
    number: 34,
    description: t("wands.queen_of_wands"),
  },
  {
    name: t("king_of_wands"),
    key: "king_of_wands",
    number: 35,
    description: t("wands.king_of_wands"),
  },
  // Cups
  {
    name: t("ace_of_cups"),
    key: "ace_of_cups",
    number: 36,
    description: t("cups.ace_of_cups"),
  },
  {
    name: t("two_of_cups"),
    key: "two_of_cups",
    number: 37,
    description: t("cups.two_of_cups"),
  },
  {
    name: t("three_of_cups"),
    key: "three_of_cups",
    number: 38,
    description: t("cups.three_of_cups"),
  },
  {
    name: t("four_of_cups"),
    key: "four_of_cups",
    number: 39,
    description: t("cups.four_of_cups"),
  },
  {
    name: t("five_of_cups"),
    key: "five_of_cups",
    number: 40,
    description: t("cups.five_of_cups"),
  },
  {
    name: t("six_of_cups"),
    key: "six_of_cups",
    number: 41,
    description: t("cups.six_of_cups"),
  },
  {
    name: t("seven_of_cups"),
    key: "seven_of_cups",
    number: 42,
    description: t("cups.seven_of_cups"),
  },
  {
    name: t("eight_of_cups"),
    key: "eight_of_cups",
    number: 43,
    description: t("cups.eight_of_cups"),
  },
  {
    name: t("nine_of_cups"),
    key: "nine_of_cups",
    number: 44,
    description: t("cups.nine_of_cups"),
  },
  {
    name: t("ten_of_cups"),
    key: "ten_of_cups",
    number: 45,
    description: t("cups.ten_of_cups"),
  },
  {
    name: t("page_of_cups"),
    key: "page_of_cups",
    number: 46,
    description: t("cups.page_of_cups"),
  },
  {
    name: t("knight_of_cups"),
    key: "knight_of_cups",
    number: 47,
    description: t("cups.knight_of_cups"),
  },
  {
    name: t("queen_of_cups"),
    key: "queen_of_cups",
    number: 48,
    description: t("cups.queen_of_cups"),
  },
  {
    name: t("king_of_cups"),
    key: "king_of_cups",
    number: 49,
    description: t("cups.king_of_cups"),
  },
  // Swords
  {
    name: t("ace_of_swords"),
    key: "ace_of_swords",
    number: 50,
    description: t("swords.ace_of_swords"),
  },
  {
    name: t("two_of_swords"),
    key: "two_of_swords",
    number: 51,
    description: t("swords.two_of_swords"),
  },
  {
    name: t("three_of_swords"),
    key: "three_of_swords",
    number: 52,
    description: t("swords.three_of_swords"),
  },
  {
    name: t("four_of_swords"),
    key: "four_of_swords",
    number: 53,
    description: t("swords.four_of_swords"),
  },
  {
    name: t("five_of_swords"),
    key: "five_of_swords",
    number: 54,
    description: t("swords.five_of_swords"),
  },
  {
    name: t("six_of_swords"),
    key: "six_of_swords",
    number: 55,
    description: t("swords.six_of_swords"),
  },
  {
    name: t("seven_of_swords"),
    key: "seven_of_swords",
    number: 56,
    description: t("swords.seven_of_swords"),
  },
  {
    name: t("eight_of_swords"),
    key: "eight_of_swords",
    number: 57,
    description: t("swords.eight_of_swords"),
  },
  {
    name: t("nine_of_swords"),
    key: "nine_of_swords",
    number: 58,
    description: t("swords.nine_of_swords"),
  },
  {
    name: t("ten_of_swords"),
    key: "ten_of_swords",
    number: 59,
    description: t("swords.ten_of_swords"),
  },
  {
    name: t("page_of_swords"),
    key: "page_of_swords",
    number: 60,
    description: t("swords.page_of_swords"),
  },
  {
    name: t("knight_of_swords"),
    key: "knight_of_swords",
    number: 61,
    description: t("swords.knight_of_swords"),
  },
  {
    name: t("queen_of_swords"),
    key: "queen_of_swords",
    number: 62,
    description: t("swords.queen_of_swords"),
  },
  {
    name: t("king_of_swords"),
    key: "king_of_swords",
    number: 63,
    description: t("swords.king_of_swords"),
  },
  // Pentacles
  {
    name: t("ace_of_pentacles"),
    key: "ace_of_pentacles",
    number: 64,
    description: t("pentacles.ace_of_pentacles"),
  },
  {
    name: t("two_of_pentacles"),
    key: "two_of_pentacles",
    number: 65,
    description: t("pentacles.two_of_pentacles"),
  },
  {
    name: t("three_of_pentacles"),
    key: "three_of_pentacles",
    number: 66,
    description: t("pentacles.three_of_pentacles"),
  },
  {
    name: t("four_of_pentacles"),
    key: "four_of_pentacles",
    number: 67,
    description: t("pentacles.four_of_pentacles"),
  },
  {
    name: t("five_of_pentacles"),
    key: "five_of_pentacles",
    number: 68,
    description: t("pentacles.five_of_pentacles"),
  },
  {
    name: t("six_of_pentacles"),
    key: "six_of_pentacles",
    number: 69,
    description: t("pentacles.six_of_pentacles"),
  },
  {
    name: t("seven_of_pentacles"),
    key: "seven_of_pentacles",
    number: 70,
    description: t("pentacles.seven_of_pentacles"),
  },
  {
    name: t("eight_of_pentacles"),
    key: "eight_of_pentacles",
    number: 71,
    description: t("pentacles.eight_of_pentacles"),
  },
  {
    name: t("nine_of_pentacles"),
    key: "nine_of_pentacles",
    number: 72,
    description: t("pentacles.nine_of_pentacles"),
  },
  {
    name: t("ten_of_pentacles"),
    key: "ten_of_pentacles",
    number: 73,
    description: t("pentacles.ten_of_pentacles"),
  },
  {
    name: t("page_of_pentacles"),
    key: "page_of_pentacles",
    number: 74,
    description: t("pentacles.page_of_pentacles"),
  },
  {
    name: t("knight_of_pentacles"),
    key: "knight_of_pentacles",
    number: 75,
    description: t("pentacles.knight_of_pentacles"),
  },
  {
    name: t("queen_of_pentacles"),
    key: "queen_of_pentacles",
    number: 76,
    description: t("pentacles.queen_of_pentacles"),
  },
  {
    name: t("king_of_pentacles"),
    key: "king_of_pentacles",
    number: 77,
    description: t("pentacles.king_of_pentacles"),
  },
];
