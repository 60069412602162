import { useQuery } from "app/utils/useQuery";
import * as React from "react";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router";
import { QuizTypes } from "app/quiz/useQuiz";
import { usePostHog } from "posthog-js/react";
import CryptoJS from "crypto-js";

export const usePaymentSuccessScreen = () => {
  const posthog = usePostHog();
  const navigate = useNavigate();
  const { type }: { type?: QuizTypes } = useParams();
  const query = useQuery();
  const fromCanceled = query.get("fromCanceled") === "true";
  const trialPlan = {
    title: query.get("title"),
    priceId: query.get("priceId"),
    value: Number(query.get("value")),
  };
  const uid = query.get("uid");
  const email = query.get("email");
  const subscriptionValue = query.get("subscriptionValue");

  useEffect(() => {
    if (query.get("CompletePayment") === "true") {
      return;
    }

    if (process.env.NODE_ENV === "production") {
      const hashedEmail = CryptoJS.SHA256(email!).toString();
      try {
        window.ttq.track("CompletePayment", {
          contents: [
            {
              content_id: trialPlan.priceId,
              content_type: "product",
              content_name: `${trialPlan.title} paid trial`,
            },
          ],
          email: hashedEmail,
          value: trialPlan.value,
          currency: "USD",
        });
      } catch (err) {
        console.error("Error with TikTok Pixel track:", err);
      }
      try {
        posthog?.capture("quiz_2_complete_payment", {
          email: email || null,
          trial_value: trialPlan.value,
          subscription_value: subscriptionValue,
          uid,
        });
      } catch (err) {
        console.error("Error capturing event with PostHog:", err);
      }
      try {
        window.fbq(
          "track",
          "Purchase",
          {
            currency: "USD",
            value: trialPlan.value,
            em: hashedEmail,
          },
          {
            eventID: uid,
          }
        );
      } catch (err) {
        console.error("Error capturing event with facebook:", err);
      }
    }
    query.set("CompletePayment", "true");
    navigate(window.location.pathname + "?" + query.toString());
  }, []);

  return {
    trialPlan,
    email,
    fromCanceled,
  };
};
