import { Path } from "app/path";
import * as React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next"; // Import the useTranslation hook

export interface NotFoundProps {}

export const NotFound: React.FC<NotFoundProps> = (props) => {
  const { t } = useTranslation(); // Initialize the translation function

  return (
    <section className="w-full">
      <div className="py-8 px-4 mx-auto max-w-screen-xl lg:py-16 lg:px-6">
        <div className="mx-auto max-w-screen-sm text-center">
          <h1 className="mb-4 text-7xl tracking-tight font-extrabold lg:text-9xl text-[#DBD2BE] font-aquino">
            {t("notFound.title")}
          </h1>
          <p className="mb-4 text-3xl font-public tracking-tight font-bold text-[#DBD2BE] md:text-3xl dark:text-white">
            {t("notFound.message")}
          </p>
          <Link to={Path.HOME} className="!text-[#DBD2BE]">
            {t("notFound.linkText")}
          </Link>
        </div>
      </div>
    </section>
  );
};
