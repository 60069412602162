import { useEffect, useMemo, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { Path } from "app/path";
import { toast } from "react-toastify";
import { debounce, find, set } from "lodash";
import { useQuery } from "app/utils/useQuery";
import { localStorageEnums } from "app/dictionaries/localStorageDictionary";
import { delay } from "app/utils/delay";
import http from "app/axios/axiosInstance";
import { readingModelDictionary } from "app/dictionaries/readingModelsDictionary";
import { useTranslation } from "react-i18next";

export enum ChatRole {
  USER = "user",
  ASSISTANT = "assistant",
  SYSTEM = "system",
}

export const useChat = ({ setShowPaywall }: { setShowPaywall?: any }) => {
  const navigate = useNavigate();
  const query = useQuery();
  const { id } = useParams();
  const conversationId = Number(id);
  const [natalChart, setNatalChart] = useState<any>({});
  const [houseCusps, setHouseCusps] = useState<any>({});
  const [element, setElement] = useState<any>();
  const [chatInput, setChatInput] = useState<string>(
    localStorage.getItem(localStorageEnums.CHAT_INPUT) || ""
  );
  const [isChatFocused, setIsChatFocused] = useState(false);
  const [chatLog, setChatLog] = useState<any>([]);
  const [isResponseLoading, setIsResponseLoading] = useState(false);
  const { t } = useTranslation();
  const rmd = readingModelDictionary(t);

  const scrollbarRef = useRef<any>(null) as any;

  const handleKeyPress = (event: any) => {
    if (event.key === "Enter" && !event.shiftKey) {
      event.preventDefault(); // Prevent the default Enter behavior
      if (event.target.value === "") {
        return;
      }
      sendMessage({
        message: event.target.value,
      }); // Call your submit function
    }
  };

  const scrollToEndOfChat = () => {
    if (!scrollbarRef.current) return;
    setTimeout(() => {
      const scrollerElement = scrollbarRef.current;
      if (scrollerElement) {
        scrollerElement.scrollTo({
          behavior: "smooth",
          top: scrollerElement.scrollHeight,
        });
      }
    }, 300);
  };

  const onChatInputChange = (event: any) => {
    setChatInput(event.target.value);
    debouncedSetLocalStorage(event.target.value);
  };

  const debouncedSetLocalStorage = useMemo(
    () =>
      debounce((value) => {
        localStorage.setItem(localStorageEnums.CHAT_INPUT, value);
      }, 500),
    []
  );

  const sendMessage = async ({
    message,
    role = ChatRole.USER,
    renderMessage = true,
  }: {
    message: any;
    role?: ChatRole.USER | ChatRole.SYSTEM;
    renderMessage?: boolean;
  }) => {
    const totalUserMessages = chatLog.filter(
      (chat: any) => chat.role === ChatRole.USER
    ).length;

    if (totalUserMessages === 2) {
      return setShowPaywall(true);
    }

    setChatInput("");
    localStorage.removeItem(localStorageEnums.CHAT_INPUT);
    const newUserMessage = { role, content: message };

    const newChatLog = [...chatLog, newUserMessage];

    if (renderMessage) {
      setChatLog(newChatLog);
    }
    scrollToEndOfChat();

    await delay(1500);

    setIsResponseLoading(true);

    try {
      const { data } = await http.post(
        "v1/web_conversations/process_conversation",
        {
          log: newChatLog,
          reading_model_key: rmd[element].key,
          user: {
            lang: query.get("lang") || "en",
            gender: "he/him",
            natalChart,
            houseCusps,
          },
          mostImportantInAPartner: query.get("mostImportantInPartner"),
        }
      );

      setIsResponseLoading(false);

      localStorage.setItem(
        localStorageEnums.CONVERSATION_LOG,
        JSON.stringify(data.log)
      );
      setChatLog(data.log);
      scrollToEndOfChat();
    } catch (err) {
      console.log(err);
    } finally {
      setIsResponseLoading(false);
    }
  };

  const initializeConversation = async (
    natalChartFromLocalStorage: any,
    houseCuspsFromLocalStorage: any,
    elementFromLocalStorage: any
  ) => {
    try {
      const { data } = await http.post("v1/web_conversations", {
        log: [],
        readingModelKey: rmd[elementFromLocalStorage].key,
        user: {
          lang: query.get("lang") || "en",
          gender: "he/him",
          natalChart: natalChartFromLocalStorage,
          houseCusps: houseCuspsFromLocalStorage,
        },
        mostImportantInAPartner: query.get("mostImportantInPartner"),
      });
      setChatLog(data.log);
      localStorage.setItem(
        localStorageEnums.CONVERSATION_LOG,
        JSON.stringify(data.log)
      );
    } catch (err) {
      console.log(err);
    } finally {
      setIsResponseLoading(false);
    }
  };

  useEffect(() => {
    const natalChartFromLocalStorage = localStorage.getItem(
      localStorageEnums.NATAL_CHART
    )
      ? JSON.parse(localStorage.getItem(localStorageEnums.NATAL_CHART)!)
      : {};
    const elementFromLocalStorage = localStorage.getItem(
      localStorageEnums.ELEMENT
    );
    const houseCuspsFromLocalStorage = localStorage.getItem(
      localStorageEnums.HOUSE_CUSPS
    )
      ? JSON.parse(localStorage.getItem(localStorageEnums.HOUSE_CUSPS)!)
      : {};
    if (
      Object.keys(natalChartFromLocalStorage).length === 0 ||
      !elementFromLocalStorage
    ) {
      console.log("no natal chart need to redirect");
      console.log("natalChartFromLocalStorage", natalChartFromLocalStorage);
      console.log("elementFromLocalStorage", elementFromLocalStorage);
    } else {
      setElement(elementFromLocalStorage);
      setNatalChart(natalChartFromLocalStorage);
      setHouseCusps(houseCuspsFromLocalStorage);
    }

    (async () => {
      const savedConversationLog = localStorage.getItem(
        localStorageEnums.CONVERSATION_LOG
      )
        ? JSON.parse(localStorage.getItem(localStorageEnums.CONVERSATION_LOG)!)
        : [];
      setIsResponseLoading(true);

      if (savedConversationLog.length > 0) {
        setChatLog(savedConversationLog);
      } else {
        await initializeConversation(
          natalChartFromLocalStorage,
          houseCuspsFromLocalStorage,
          elementFromLocalStorage
        );
      }

      setIsResponseLoading(false);
    })();
  }, []);

  useEffect(() => {
    setTimeout(() => {
      scrollToEndOfChat();
    }, 300);
  }, []);

  return {
    sendMessage,
    handleKeyPress,
    chatInput,
    chatLog,
    isChatFocused,
    setIsChatFocused,
    isResponseLoading,
    onChatInputChange,
    scrollbarRef,
  };
};
