export const delay = (ms: number, signal?: AbortSignal) =>
  new Promise((resolve, reject) => {
    if (signal && signal.aborted) {
      return reject(new Error('Operation was aborted'));
    }

    const timerId = setTimeout(resolve, ms);

    if (signal) {
      signal.addEventListener('abort', () => {
        clearTimeout(timerId);
        reject(new Error('Operation was aborted'));
      });
    }
  });
