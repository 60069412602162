import { horoscopeSignDictionary } from "app/dictionaries/horoscopeSignDictionary";
import { getSign } from "horoscope";
import { get } from "lodash";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

export const useBirthDateScreen = ({
  relationshipProfile,
  setRelationshipProfile,
}: {
  relationshipProfile: any;
  setRelationshipProfile: (profile: any) => void;
}) => {
  const [birthDate, setBirthDate] = useState<Date>(new Date());
  const [horoscope, setHoroscope] = useState<any>();
  const { t } = useTranslation();

  const onBirthDateChange = (date: Date) => {
    setBirthDate(date);
    setRelationshipProfile({
      ...relationshipProfile,
      birthDate: date.toISOString(),
    });
  };

  const formatDateForUniversally = (year: any, month: any, day: any) => {
    const pad = (num: number) => (num < 10 ? `0${num}` : num);
    return `${year}-${pad(month)}-${pad(day)}`;
  };

  useEffect(() => {
    (async () => {
      try {
        const horoscopeSign = await getSign({
          month: birthDate.getMonth() + 1,
          day: birthDate.getDate() === 31 ? 31 : birthDate.getDate() + 1,
        } as any);
        const newHoroscope = get(
          horoscopeSignDictionary(t),
          horoscopeSign.toLowerCase()
        );
        setHoroscope(newHoroscope);
      } catch (error) {
        console.error(error);
      }
    })();
  }, [birthDate]);

  useEffect(() => {
    if (!relationshipProfile.birthDate) {
      setRelationshipProfile({
        ...relationshipProfile,
        birthDate: new Date(),
      });
    }
  }, []);

  return {
    horoscope,
    onBirthDateChange,
    birthDate,
    formatDateForUniversally,
  };
};
