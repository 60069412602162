import { Divider } from "@nextui-org/react";
import { twClassNames } from "@subframe/core";
import { PrimaryButton } from "app/components/buttons/button";
import { FadeInSlide } from "app/motions/fadeInSlide";
import * as React from "react";
import { useTranslation } from "react-i18next";

export interface ConversionProps {
  trialPlan: any;
  setShowPaymentModal: (show: boolean) => void;
  fromCanceled?: boolean;
  subPrice: string;
}

export const Conversion: React.FC<ConversionProps> = ({
  trialPlan,
  setShowPaymentModal,
  fromCanceled = false,
  subPrice,
}) => {
  const { t } = useTranslation("common", { keyPrefix: "quiz" });
  return (
    <FadeInSlide direction="top" key="discount">
      <div className="flex max-w-[467px] flex-col justify-center mobile:max-w-[313px] mx-auto h-[100dvh]">
        <header className="flex flex-col mx-auto items-center gap-2 mb-10">
          <h1 className="text-[40px]">🥳</h1>
          <p className="text-[40px] text-center mobile:text-lg">
            {t("secret_discount")}
          </p>
          <p className="text-[#DBD2BEB2] text-center text-2xl mobile:text-xl">
            {t("no_pressure")}
          </p>
        </header>
        <div className={twClassNames("flex flex-col items-center max-w-192")}>
          <div className="flex h-10 w-full justify-between items-center px-4 gap-4 bg-[#48443bff] rounded-t-lg">
            <span className="text-[20px] font-[500] leading-[28px] text-pe-50 mobile:text-sm">
              {t("discount_applied")}
            </span>
            <div className="flex items-center gap-1">
              <span className="text-[20px] font-[500] leading-[28px] text-pe-50 line-through mobile:text-sm">
                -30%
              </span>
              <span className="text-[20px] font-[500] leading-[28px] text-pe-50 mobile:text-sm">
                -70%
              </span>
            </div>
          </div>
          <div className="flex w-full flex-col items-center justify-center gap-6 bg-[#171107ff] px-6 py-6 rounded-b-lg">
            <div className="flex w-full items-center justify-between gap-1">
              <span className="text-[20px] font-[500] leading-[32px] w-3/5 text-pe-50 mobile:text-sm">
                {fromCanceled
                  ? t("2months_cost")
                  : t("2months_cost_after_trial")}
              </span>
              <div className="flex gap-1">
                <span className="text-[20px] font-[500] leading-[32px] text-pe-50 line-through mobile:text-sm">
                  $55
                </span>
                <span className="text-[20px] font-[500] leading-[32px] text-pe-50 mobile:text-sm">
                  $19.99
                </span>
              </div>
            </div>
            <div className="flex w-full items-center justify-center gap-1">
              <span className="grow shrink-0 basis-0 text-[20px] font-[500] leading-[28px] text-pe-50 mobile:text-sm">
                {t("you_save")} $36
              </span>
            </div>
            <Divider className="bg-[#DBD2BE]" />
            <div className="flex w-full items-center justify-center gap-1">
              <span className="grow shrink-0 basis-0 text-[20px] font-[500] leading-[28px] text-pe-50 mobile:text-sm">
                {t("total_today")}
              </span>

              <div className="flex items-center justify-center gap-4">
                <span className="text-[20px] font-[500] leading-[32px] text-pe-50 mobile:text-sm">
                  {fromCanceled ? subPrice : trialPlan?.title}
                </span>
              </div>
            </div>
          </div>
        </div>
        <PrimaryButton
          className="w-full !p-6 my-10"
          onClick={() => setShowPaymentModal(true)}
        >
          <p className="font-public text-black text-2xl font-semibold">
            {fromCanceled ? t("claim_discount") : t("start_7_day_trial")}
          </p>
        </PrimaryButton>
        <p className="text-center text-sm mobile:text-xs">
          {fromCanceled
            ? t("subscription_notice_without_trial", { subPrice })
            : t("subscription_notice", {
                selectedPlan: trialPlan.value,
                subPrice,
              })}
          <a href="https://morereadings.com">morereadings.com</a>.
          {t("learn_more_cancellation2")}
        </p>
      </div>
    </FadeInSlide>
  );
};
