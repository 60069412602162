import { genderDictionary } from "app/dictionaries/genderDictionary";
import classNames from "classnames";
import * as React from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import ReactPlayer from "react-player";

export interface GenderScreenProps {
  relationshipProfile: any;
  setRelationshipProfile: (profile: any) => void;
}

export const GenderScreen: React.FC<GenderScreenProps> = ({
  relationshipProfile,
  setRelationshipProfile,
}) => {
  const { t } = useTranslation();
  const [playingVideos, setPlayingVideos] = useState<{
    [key: string]: boolean;
  }>({});

  const handleVideoPlay = (key: string) => {
    setPlayingVideos((prev) => ({ ...prev, [key]: true }));
    setRelationshipProfile({ ...relationshipProfile, gender: key });
  };

  const handleVideoEnd = (key: string) => {
    setPlayingVideos((prev) => ({ ...prev, [key]: false }));
  };

  return (
    <div className="flex flex-col justify-center items-center pt-20">
      <p className="font-aquino text-[40px] text-[#DBD2BE]">
        {t("gender_label")}
      </p>
      <div className="flex gap-5 justify-center items-center h-[50vh]">
        {Object.entries(genderDictionary(t)).map(
          ([key, { title, video, image }]) => {
            const isGenderSelected = relationshipProfile.gender === key;
            return (
              <div className="flex flex-col gap-10" key={key}>
                <div
                  className={classNames(
                    "w-24 h-24 border rounded cursor-pointer",
                    isGenderSelected
                      ? "border-[1px] border-solid border-[#DBD2BE] bg-[COLOR.HME9] overflow-hidden"
                      : "border-transparent bg-transparent"
                  )}
                  onClick={() => handleVideoPlay(key)}
                >
                  <ReactPlayer
                    url={video}
                    playing={playingVideos[key]}
                    muted
                    width="100%"
                    height="100%"
                    onPlay={() => handleVideoPlay(key)}
                    onEnded={() => handleVideoEnd(key)}
                    config={{
                      file: {
                        attributes: {
                          playsInline: true,
                        },
                      },
                    }}
                  />
                </div>
                <button
                  onClick={() => handleVideoPlay(key)}
                  className="font-aquino text-[18px] text-[#DBD2BE]"
                >
                  {title}
                </button>
              </div>
            );
          }
        )}
      </div>
    </div>
  );
};
