import { useQuery } from "app/utils/useQuery";
import * as React from "react";
import { Navigate, useParams } from "react-router";
import { PaymentUpsell as PaymentUpsellQuiz2 } from "app/quiz/types/quiz2/v2/screens/paymentUpsell/paymentUpsell";
import { QuizTypes } from "../useQuiz";

export interface PaymentUpsellProps {}

export const PaymentUpsell: React.FC<PaymentUpsellProps> = (props) => {
  const { type }: { type?: QuizTypes } = useParams();

  const getUpsellFlow = () => {
    switch (type) {
      case QuizTypes.QUIZ2:
        return <PaymentUpsellQuiz2 />;
      default:
        return <Navigate to="/" />;
    }
  };

  return getUpsellFlow();
};
