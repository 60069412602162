import * as React from "react";
import { Button, ButtonProps as NextUiButtonProps } from "@nextui-org/react";
import styles from "./button.module.css";
import classnames from "classnames";

export interface ButtonProps extends NextUiButtonProps {
  variant?: NextUiButtonProps["variant"];
  className?: NextUiButtonProps["className"];
  children?: React.ReactNode;
  icon?: string;
  imageAlt?: string;
  active?: boolean;
}

export const PrimaryButton: React.FC<ButtonProps> = ({
  children,
  variant = "light",
  className = "",
  ...buttonProps
}) => (
  <Button
    variant={variant}
    {...buttonProps}
    className={classnames(styles.button, styles.primary, className)}
  >
    {children}
  </Button>
);

export const SecondaryButton1: React.FC<ButtonProps> = ({
  children,
  variant,
  className = "",
  ...buttonProps
}) => (
  <Button
    variant={variant}
    {...buttonProps}
    className={classnames(styles.button, styles.secondary1, className)}
  >
    {children}
  </Button>
);

export const SecondaryButton2: React.FC<ButtonProps> = ({
  children,
  variant,
  className = "",
  ...buttonProps
}) => (
  <Button
    variant={variant}
    {...buttonProps}
    className={classnames(styles.button, styles.secondary2, className)}
  >
    {children}
  </Button>
);

export const SecondaryButton3: React.FC<ButtonProps> = ({
  children,
  variant,
  className = "",
  ...buttonProps
}) => (
  <Button
    variant={variant}
    {...buttonProps}
    className={classnames(styles.button, styles.secondary3, className)}
  >
    {children}
  </Button>
);

export const TertiaryButton: React.FC<ButtonProps> = ({
  children,
  variant,
  className = "",
  ...buttonProps
}) => (
  <Button
    variant={variant}
    {...buttonProps}
    className={classnames(styles.button, styles.tertiary, className)}
  >
    {children}
  </Button>
);

// export const GoogleButton: React.FC<ButtonProps> = ({
//   variant,
//   className = "",
//   ...buttonProps
// }) => (
//   <Button
//     variant={variant}
//     {...buttonProps}
//     isIconOnly
//     className={classnames(styles.button, styles.auth, className)}
//   >
//     <img className="w-6" src={GoogleIconSvg} alt="google icon" />{" "}
//     <span>Continue with Google</span>
//   </Button>
// );

// export const DiscordButton: React.FC<ButtonProps> = ({
//   variant,
//   className = "",
//   ...buttonProps
// }) => (
//   <Button
//     variant={variant}
//     {...buttonProps}
//     className={classnames(styles.button, styles.auth, className)}
//   >
//     <img className="w-6" src={DiscordIconSvg} alt="discord icon" />{" "}
//     <span>Continue with Discord</span>
//   </Button>
// );

export const IconButton: React.FC<ButtonProps> = ({
  variant,
  className = "",
  icon,
  imageAlt,
  active = false,
  ...buttonProps
}) => (
  <Button
    isIconOnly
    variant={variant}
    className={classnames(styles.button, styles.icon, className, {
      [styles.iconActive]: active,
    })}
    {...buttonProps}
  >
    <img src={icon} className="w-4 h-4" alt={imageAlt} />
  </Button>
);
