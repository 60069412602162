import * as React from "react";
import { useProfileCreationInvite } from "app/relationshipProfiles/profileCreationInvite/useProfileCreationInvite";
import { Helmet } from "react-helmet-async";
import styles from "./profileCreationInvite.module.css";
import { Fireflies } from "app/components/fireflies/fireflies";
import classNames from "classnames";
import { Button } from "@nextui-org/react";
import { RelationshipScreen } from "./screens/relationshipScreen/relationshipScreen";
import { StepIndicator } from "app/components/stepIndicator/stepIndicator";
import FancyBlackChevronSvg from "app/assets/icons/fancyBlackChevron.svg";
import MoreReadingsLogoSvg from "app/assets/images/moreReadingsLogo.svg";
import { BirthDateScreen } from "./screens/birthDateScreen/birthDateScreen";
import { GenderScreen } from "./screens/genderScreen/genderScreen";
import { BirthPlaceScreen } from "./screens/birthPlaceScreen/birthPlaceScreen";
import { BirthTimeScreen } from "./screens/birthTimeScreen/birthTimeScreen";
import { COLORS } from "app/dictionaries/colors";
import { Alignment, Fit, Layout, useRive } from "@rive-app/react-canvas";
import LoadingRive from "app/assets/rive/loadingEcruEye.riv";
import { Link } from "react-router-dom";
import { Path } from "app/path";
import { HoroscopeReport } from "app/horoscopeReport/horoscopeReport";
import { useTranslation } from "react-i18next";

export interface ProfileCreationInviteProps {}

export const ProfileCreationInvite: React.FC<
  ProfileCreationInviteProps
> = ({}) => {
  const {
    isTokenValid,
    handleProfileCreation,
    inviterName,
    screenIndex,
    setScreenIndex,
    relationshipProfile,
    setRelationshipProfile,
    isLoading,
    horoscopeReport,
  } = useProfileCreationInvite();
  const { t } = useTranslation();

  const { rive, RiveComponent } = useRive({
    // Load a local riv `clean_the_car.riv` or upload your own!
    src: LoadingRive,
    // Be sure to specify the correct state machine (or animation) name
    stateMachines: "play",
    // This is optional.Provides additional layout control.
    layout: new Layout({
      fit: Fit.Cover, // Change to: rive.Fit.Contain, or Cover
      alignment: Alignment.Center,
    }),
    autoplay: true,
  });

  if (isLoading || !isTokenValid) {
    return (
      <div
        className={classNames(
          "h-[100dvh] !bg-transparent justify-center items-center p-[20px]",
          styles.base
        )}
      >
        <Helmet>
          <title>More Readings - {t("profile_creation")}</title>
          <meta property="og:title" content={t("meta.join_more_readings")} />
          <meta
            property="og:description"
            content={t("meta.create_relationship")}
          />
          <meta
            property="og:image"
            content="https://morereadings.com/logo512.png"
          />
          <meta
            property="og:url"
            content="https://morereadings.com/profile-creation-invite?token=cfec8ea36f17234c181c"
          />
          <meta property="og:type" content="website" />
        </Helmet>
        <Link to={Path.HOME} className="cursor-pointer">
          <img src={MoreReadingsLogoSvg} alt="more readings logo" />
        </Link>
        {isLoading ? (
          <RiveComponent
            className={
              "absolute top-1/2 left-1/2 -translate-x-[50%] -translate-y-[50%] w-[80px] h-[80px]"
            }
          />
        ) : (
          <div className="flex flex-col justify-center items-center h-2/3 gap-8">
            <p className={`font-aquino text-[${COLORS.PE}] text-2xl`}>
              {t("link_expired")}
            </p>
            <p className={`text-center text-[${COLORS.PE}]`}>
              {t("ask")} <br /> {t("send_another")}
            </p>
          </div>
        )}
        <Fireflies />
      </div>
    );
  }

  const screens = [
    {
      continueDisabled:
        relationshipProfile.name === "" ||
        relationshipProfile.relationship === "",
      component: (
        <RelationshipScreen
          relationshipProfile={relationshipProfile}
          setRelationshipProfile={setRelationshipProfile}
        />
      ),
    },
    {
      continueDisabled: !relationshipProfile.birthDate,
      component: (
        <BirthDateScreen
          relationshipProfile={relationshipProfile}
          setRelationshipProfile={setRelationshipProfile}
        />
      ),
    },
    {
      continueDisabled: !relationshipProfile.gender,
      component: (
        <GenderScreen
          relationshipProfile={relationshipProfile}
          setRelationshipProfile={setRelationshipProfile}
        />
      ),
    },
    {
      continueDisabled:
        !relationshipProfile.name ||
        !relationshipProfile.birthplaceName ||
        !relationshipProfile.birthplaceLatitude ||
        !relationshipProfile.birthplaceLongitude,
      component: (
        <BirthPlaceScreen
          relationshipProfile={relationshipProfile}
          setRelationshipProfile={setRelationshipProfile}
        />
      ),
    },
    {
      continueDisabled: false,
      component: (
        <BirthTimeScreen
          relationshipProfile={relationshipProfile}
          setRelationshipProfile={setRelationshipProfile}
        />
      ),
    },
  ];
  const currentScreen = screens[screenIndex];
  const isLastScreen = screenIndex === screens.length - 1;
  return (
    <div
      className={classNames(
        "flex flex-col relative min-h-[100dvh] h-full !bg-transparent z-0 m-auto p-[20px] max-w-[414px]",
        styles.base
      )}
    >
      <Helmet>
        <title>More Readings - {t("profile_creation")}</title>
        <meta property="og:title" content={t("meta.join_more_readings")} />
        <meta
          property="og:description"
          content={t("meta.create_relationship")}
        />
        <meta
          property="og:image"
          content="https://morereadings.com/logo512.png"
        />
        <meta
          property="og:url"
          content="https://morereadings.com/profile-creation-invite?token=cfec8ea36f17234c181c"
        />
        <meta property="og:type" content="website" />
      </Helmet>
      <Link to={Path.HOME}>
        <img src={MoreReadingsLogoSvg} alt="more readings logo" />
      </Link>
      {horoscopeReport ? (
        <HoroscopeReport
          horoscopeReport={horoscopeReport}
          inviterName={inviterName}
        />
      ) : (
        <>
          {currentScreen?.component}
          <div className="absolute bottom-1 left-0 px-5 w-full flex flex-col items-center ">
            <StepIndicator step={screenIndex + 1} steps={screens.length} />
            <Button
              onClick={() =>
                isLastScreen
                  ? handleProfileCreation()
                  : setScreenIndex(screenIndex + 1)
              }
              isDisabled={currentScreen?.continueDisabled}
              className={classNames(
                `rounded-[2px] bg-[#DBD2BE] p-5 w-full font-aquino text-2xl mt-6 max-w-[414px]`,
                {
                  "bg-[#2C2E33]": currentScreen?.continueDisabled,
                }
              )}
            >
              {isLastScreen ? t("finalize_btn") : t("continue")}
              <img src={FancyBlackChevronSvg} alt="fancy black chevron" />
            </Button>
            <Button
              onClick={() => setScreenIndex(screenIndex - 1)}
              isDisabled={screenIndex === 0}
              className={classNames(
                `rounded-[2px] text-[#DBD2BE] bg-transparent p-5 w-full font-aquino text-2xl max-w-[414px]`,
                {
                  "opacity-0": screenIndex === 0,
                }
              )}
            >
              {t("back")}
            </Button>
            <Fireflies />
          </div>
        </>
      )}
    </div>
  );
};
