import { LuckMeter } from "app/components/luckMeter/luckMeter";
import camelcaseKeys from "camelcase-keys";
import { capitalize } from "lodash";
import NameBadgeEcruSvg from "app/assets/images/nameBadgeEcru.svg";
import * as React from "react";
import { getLuckyStone } from "app/dictionaries/luckyStonesDictionary";
import EcruDiamondBulletPointSvg from "app/assets/icons/ecruDiamondPoint.svg";
import { getFormatDate3 } from "app/utils/date";
import { Divider, Link, Tooltip } from "@nextui-org/react";
import classNames from "classnames";
import { AppleBtn } from "app/components/buttons/apple/appleBtn";
import { useTranslation } from "react-i18next";

export interface HoroscopeReportProps {
  inviterName: string;
  horoscopeReport: any;
}

export const HoroscopeReport: React.FC<HoroscopeReportProps> = ({
  horoscopeReport,
  inviterName,
}) => {
  const { t, i18n } = useTranslation();
  return (
    <div>
      <div className="rounded border-1 border-[#DBD2BE] bg-[#1A1814] p-[10px] mt-5 mb-6">
        <p className="max-w-[300px] !leading-6">
          {t("thank_you")}{" "}
          <span className=" font-bold">
            {horoscopeReport.relationshipProfile.name}!{" "}
          </span>
          {t("we_will_let")} <span className=" font-bold">{inviterName}</span>{" "}
          {t("know_you_finished")}
        </p>
      </div>
      <p className="font-aquino text-2xl mb-11">
        {t("horoscopeReport", {
          name: horoscopeReport.relationshipProfile.name,
        })}
      </p>
      <div className="flex flex-col text-xl">
        <p className="font-aquino mb-[10px] self-end">
          {capitalize(getFormatDate3(horoscopeReport.createdAt, i18n.language))}
        </p>
        <LuckMeter
          helpText={
            "For each category below, your Vibe Meter forecasts your day based on your horoscope, planetary influences, moon phase, and weekday luckiness."
          }
          stats={horoscopeReport.luckMeter}
          animationDelayCount={1000}
          horoscopeSign={horoscopeReport.relationshipProfile.horoscopeSign}
        />
      </div>
      <section
        className={`mt-7 relative pt-9 p-2 flex justify-between items-center bg-[#0B0B0B] border border-[#1A1B1E] rounded`}
      >
        <div className="absolute -top-6 left-1/2 -translate-x-1/2 flex justify-center items-center">
          <img className="w-[148px]" src={NameBadgeEcruSvg} alt="Name Badge" />
          <p className="absolute font-aquino inset-0 flex items-center justify-center text-[#DBD2BE]">
            {t("lucky_pairing")}
          </p>
        </div>
        <div className="flex justify-between items-center w-full">
          <div className="flex flex-col items-center w-20 h-16 justify-between">
            <p className="text-[#DBD2BE] font-aquino text-[32px] leading-9 h-8">
              {horoscopeReport.horoscopeFortune.luckyNumber}
            </p>
            <p className="text-white text-xs">{t("number")}</p>
          </div>
          <Tooltip
            className="!bg-black text-[#DBD2BE] rounded-sm"
            content={capitalize(
              t(
                `luckyColors.${horoscopeReport.horoscopeFortune.luckyColor.name}` as any
              )
            )}
          >
            <div className="flex flex-col items-center w-20 h-16 justify-between">
              <div
                className="w-8 h-8 rounded-md"
                style={{
                  backgroundColor:
                    horoscopeReport.horoscopeFortune.luckyColor.hex,
                }}
              />
              <p className="text-white text-xs overflow-hidden overflow-ellipsis whitespace-nowrap w-full text-center">
                {capitalize(
                  t(
                    `luckyColors.${horoscopeReport.horoscopeFortune.luckyColor.name}` as any
                  )
                )}
              </p>
            </div>
          </Tooltip>
          <Tooltip
            className="!bg-black text-[#DBD2BE] rounded-sm"
            content={capitalize(
              t(
                `luckyStones.${horoscopeReport?.horoscopeFortune.luckyStone}` as any
              )
            )}
          >
            <div className="relative flex flex-col items-center h-16 justify-between w-20">
              <img
                src={getLuckyStone(horoscopeReport.horoscopeFortune.luckyStone)}
                alt={horoscopeReport.horoscopeFortune.luckyStone}
                className="object-cover w-8 h-8"
              />

              <p className="text-white text-xs overflow-hidden overflow-ellipsis whitespace-nowrap w-full text-center">
                {capitalize(
                  t(
                    `luckyStones.${horoscopeReport?.horoscopeFortune.luckyStone}` as any
                  )
                )}
              </p>
            </div>
          </Tooltip>
        </div>
      </section>
      <section className="mt-5 flex flex-col gap-5">
        <div className="flex items-center gap-[10px] overflow-hidden">
          <p className="font-aquino text-xl whitespace-nowrap">
            {t("do_donts")}
          </p>
          <Divider className="bg-[#A89F8B] text-[#A89F8B] w-full" />
          {/* <div className="h-[1px] border-t-1 border-[#A89F8B]" /> */}
        </div>
        <div className="p-[10px] border-1 border-[#1A1B1E] rounded-sm bg-[#0B0B0B66]">
          <p className="text-[#A0ffBA] mb-[10px] font-semibold">{t("dos")}</p>
          <ul className="flex flex-col text-[#DBD2BE] list-inside gap-[10px]">
            {horoscopeReport.dos.map((item: string, index: number) => (
              <li key={index} className="flex items-start">
                <img
                  src={EcruDiamondBulletPointSvg}
                  alt="bullet point"
                  className="w-1 mt-2 mx-2"
                />
                {item}
              </li>
            ))}
          </ul>
        </div>
        <div className="p-[10px] border-1 border-[#1A1B1E] rounded-sm bg-[#0B0B0B66]">
          <p className="text-[#F59E9E] mb-[10px] font-semibold">{t("donts")}</p>
          <ul className="flex flex-col text-[#DBD2BE] list-inside gap-[10px]">
            {horoscopeReport.donts.map((item: string, index: number) => (
              <li key={index} className="flex items-start">
                <img
                  src={EcruDiamondBulletPointSvg}
                  alt="bullet point"
                  className="w-1 mt-2 mx-2"
                />
                {item}
              </li>
            ))}
          </ul>
        </div>
      </section>
      <section className="mt-5 flex flex-col gap-5">
        <div className="flex items-center gap-[10px] overflow-hidden">
          <p className="font-aquino text-xl whitespace-nowrap">
            {t("high_lows")}
          </p>
          <Divider className="bg-[#A89F8B] text-[#A89F8B] w-full" />
          {/* <div className="h-[1px] border-t-1 border-[#A89F8B]" /> */}
        </div>
        <div className="p-[10px] border-1 border-[#1A1B1E] rounded-sm bg-[#0B0B0B66]">
          <p className="mb-[10px]">{t("highlights")}</p>
          <ul className="flex flex-col text-[#DBD2BE] list-inside gap-[10px]">
            {horoscopeReport.highlights.map((item: string, index: number) => (
              <li key={index} className="flex items-start">
                <img
                  src={EcruDiamondBulletPointSvg}
                  alt="bullet point"
                  className="w-1 mt-2 mx-2"
                />
                {item}
              </li>
            ))}
          </ul>
        </div>
        <div className="p-[10px] border-1 border-[#1A1B1E] rounded-sm bg-[#0B0B0B66]">
          <p className="mb-[10px]">{t("lowlights")}</p>
          <ul className="flex flex-col text-[#DBD2BE] list-inside gap-[10px]">
            {horoscopeReport.lowlights.map((item: string, index: number) => (
              <li key={index} className="flex items-start">
                <img
                  src={EcruDiamondBulletPointSvg}
                  alt="bullet point"
                  className="w-1 mt-2 mx-2"
                />
                {item}
              </li>
            ))}
          </ul>
        </div>
      </section>
      <section className="mt-5 flex flex-col gap-5">
        <div className="flex items-center gap-[10px] overflow-hidden">
          <p className="font-aquino text-xl whitespace-nowrap">
            {t("whats_going_on")}
          </p>
          <Divider className="bg-[#A89F8B] text-[#A89F8B] w-full" />
        </div>
        <p className="text-xl font-extralight !leading-8 text-[#909296]">
          {horoscopeReport.reading}
        </p>
      </section>
      <section className="my-12 items-center py-10 px-2 flex flex-col gap-[30px] bg-[#1A1814] border-1 border-[#F5E7C8] rounded relative">
        <div className="absolute bg-gradient-to-r from-[#0B0B0B] to-[#1F1F1E] -top-4 p-[10px] rounded">
          <p className="!leading-[14px]">Try More Readings for free</p>
        </div>
        <p className="text-center">{t("track_horoscope", { inviterName })} </p>
        <AppleBtn />
      </section>
    </div>
  );
};
