export const trialPlanDeals = [
  // Works better as a list cause we map over it
  {
    title: "$1",
    value: 1,
    priceId: process.env.REACT_APP_STRIPE_TRIAL_1_PRICE_ID,
  },
  // {
  //   title: "$2",
  //   value: 2,
  //   priceId: process.env.REACT_APP_STRIPE_TRIAL_2_PRICE_ID,
  // },
  {
    title: "$3",
    value: 3,
    priceId: process.env.REACT_APP_STRIPE_TRIAL_3_PRICE_ID,
  },
  {
    title: "$5",
    value: 5,
    priceId: process.env.REACT_APP_STRIPE_TRIAL_5_PRICE_ID,
  },
  {
    title: "$7.86",
    value: 7.86,
    priceId: process.env.REACT_APP_STRIPE_TRIAL_786_PRICE_ID,
  },
];

export enum MonthylPlanDealsEnum {
  SUB1999 = "sub1999",
  SUB2999 = "sub2999",
}

export const monthlyPlanDeals = {
  // all plans are 2 month cycles as of Oct 15 2021
  // Works better as an object cause we access it by key
  [MonthylPlanDealsEnum.SUB1999]: {
    title: "$19.99",
    value: 19.99,
    priceId: process.env.REACT_APP_STRIPE_MONTHLY_1999_PRICE_ID,
  },
  [MonthylPlanDealsEnum.SUB2999]: {
    title: "$29.99",
    value: 29.99,
    priceId: process.env.REACT_APP_STRIPE_MONTHLY_2999_PRICE_ID,
  },
};
