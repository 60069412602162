import * as React from "react";
import classNames from "classnames";
import { Fireflies } from "app/components/fireflies/fireflies";
import HeroTitleSvg from "app/assets/images/heroTitle.svg";
import LucyReviewPng from "app/assets/images/lucyReview.png";
import { useBreakpoint } from "app/utils/useBreakpoint";
import { Path } from "app/path";
import { Link } from "react-router-dom";
import styles from "app/home/home.module.css";
import { PlaystoreBtn } from "app/components/buttons/android/playstoreBtn";
import { AppleBtn } from "app/components/buttons/apple/appleBtn";
import { useTranslation } from "react-i18next";

export interface TiktokProps {}

export const Tiktok: React.FC<TiktokProps> = (props) => {
  const { isBelowSm, isAboveSm } = useBreakpoint("sm");
  const { isBelowXs, isAboveXs } = useBreakpoint("xs");
  const { isBelowLg } = useBreakpoint("lg");
  const { t } = useTranslation();

  React.useEffect(() => {
    window.location.href =
      "https://apps.apple.com/app/id6479682201?platform=iphone";
  }, []);

  return (
    <div className={styles.base}>
      {isBelowLg && (
        <div
          className={classNames(
            styles.bottomFade,
            "absolute top-0 w-full h-[80dvh]"
          )}
        />
      )}
      <div
        className={classNames(
          styles.content,
          "h-[100dvh] !bg-transparent justify-center items-center"
        )}
      >
        <div
          className={classNames("flex flex-col h-full justify-center m-auto", {
            "items-center": isAboveSm,
            "max-w-[300px] items-start": isBelowSm,
            "gap-10": isAboveXs,
            "gap-5": isBelowXs,
          })}
        >
          <img src={HeroTitleSvg} alt="Hero Title" />
          <img
            src={LucyReviewPng}
            alt="Review"
            className={classNames("max-w-[197px]", {
              "-ml-[10px]": isBelowSm,
            })}
          />
          <div
            className={classNames("flex flex-col", {
              "gap-10 mt-10": isAboveXs,
              "gap-5 mt-5": isBelowXs,
            })}
          >
            <AppleBtn />
            <PlaystoreBtn />
          </div>
        </div>
        <div
          className={classNames("flex gap-4 text-xs m-auto mb-4", {
            "flex-col max-w-[300px]": isBelowSm,
            "items-center justify-center": isAboveSm,
          })}
        >
          <p className="text-white font-thin">{t("rights")}</p>
          <div className="flex gap-[10px] text-white">
            <Link
              to={Path.TERMS}
              className="font-thin border-b border-transparent duration-300 hover:border-white"
            >
              {t("terms")}
            </Link>
            <Link
              to={Path.POLICIES}
              className="font-thin border-b border-transparent duration-300 hover:border-white"
            >
              {t("policies")}
            </Link>
            <Link
              id="contact"
              to={"mailto:hello@support.morereadings.me"}
              className="font-thin border-b border-transparent duration-300 hover:border-white"
            >
              {t("contact")}
            </Link>
          </div>
        </div>
      </div>
      <Fireflies />
    </div>
  );
};
