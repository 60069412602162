import * as React from "react";
import styles from "app/legal/legal.module.css";

export interface PolicyProps {}

export const Policy: React.FC<PolicyProps> = ({}) => {
  return (
    <div className={styles.container}>
      <h1 className={styles.h1}>Política de Privacidad</h1>
      <p className={styles.p}>ÚLTIMA ACTUALIZACIÓN: 4 DE ABRIL DE 2024</p>
      <p className={styles.p}>
        Tacit Social LLC se compromete a proteger la información personal que
        compartes con nosotros. Esta Política de Privacidad explica cómo
        recopilamos, almacenamos, usamos y divulgamos la información. Esta
        Política de Privacidad se aplica a nuestra aplicación móvil (“App”),
        nuestro sitio web [insertar URL del sitio web de Tacit Social LLC]
        (“Sitio Web”) y nuestras actividades corporativas, referidas
        colectivamente como nuestros servicios (“Servicios”). Al utilizar
        nuestros Servicios o proporcionarnos información, consientes que
        recopilemos, almacenemos, usemos y divulguemos la información según lo
        descrito en esta Política de Privacidad.
      </p>
      <h2 className={styles.h2}>Contáctanos</h2>
      <p className={styles.p}>
        Si tienes alguna pregunta respecto a esta Política de Privacidad, por
        favor contáctanos en hello@support.morereadings.me.
      </p>
      <h2 className={styles.h2}>
        Tu aceptación de esta política de privacidad
      </h2>
      <p className={styles.p}>
        Las disposiciones contenidas en esta Política de Privacidad reemplazan
        todos los avisos y declaraciones previos respecto a nuestras prácticas
        de privacidad con respecto a los Servicios. Si no estás de acuerdo con
        todas las disposiciones de esta Política de Privacidad, no podrás
        acceder ni utilizar los Servicios. Podemos actualizar o modificar esta
        Política de Privacidad de vez en cuando. Si hacemos cambios materiales a
        esta Política de Privacidad o a la manera en que usamos, compartimos o
        recopilamos Información Personal, te notificaremos revisando la “Fecha
        de Vigencia” en la parte superior de esta Política de Privacidad,
        publicando de manera prominente un anuncio de los cambios en nuestros
        Servicios, o enviándote una comunicación por escrito utilizando la
        información de contacto que nos proporcionaste antes de que la nueva
        política entre en vigor. Los usuarios están sujetos a cualquier cambio
        en la Política de Privacidad si utilizan nuestros Servicios después de
        que se haya comunicado el aviso de dichos cambios. Te recomendamos
        revisar esta Política de Privacidad regularmente para asegurarte de
        estar familiarizado con las prácticas actuales de Tacit Social LLC.
      </p>

      <p className={styles.p}>
        Además, reconoces que esta Política de Privacidad forma parte de
        nuestros Términos de Servicio en{" "}
        <a href="morereadings.com/terms">morereadings.com/terms</a> y está
        incorporada en ellos por referencia. Al acceder o usar nuestros
        Servicios, aceptas estar sujeto a los Términos de Servicio. Si no estás
        de acuerdo con nuestros Términos de Servicio, no accedas ni uses nuestro
        Servicio.
      </p>

      <h2 className={styles.h2}>Información que obtenemos de ti</h2>
      <p className={styles.p}>
        Cuando utilizas nuestros Servicios, recopilamos información que nos
        proporcionas. Además, se recopila automáticamente cierta información de
        ti. Parte de esta información puede identificarte (“Información
        Personal”), y parte de esta información puede no identificarte
        (“Información Anónima”). A veces, la Información Anónima puede estar
        asociada directa o indirectamente con Información Personal. En estos
        casos, dicha Información Anónima se considerará Información Personal
        para los propósitos de esta Política de Privacidad. Además, en la medida
        en que las direcciones de Protocolo de Internet (“IP”) u otros
        identificadores o información similar se consideren información de
        identificación personal según la ley aplicable, trataremos esos
        elementos como Información Personal según esta Política de Privacidad.
      </p>

      <h2 className={styles.h2}>INFORMACIÓN QUE NOS PROPORCIONAS</h2>

      <p className={styles.p}>
        La información que puedes proporcionar incluye tu nombre, correo
        electrónico, número de teléfono móvil, nombre de usuario de Facebook o
        Apple, fecha de nacimiento, lugar de nacimiento, hora de nacimiento y
        nombre de inicio de sesión o cuenta. Si solicitas un puesto con
        nosotros, puedes proporcionarnos información sobre tu historial laboral,
        estado de autorización laboral, educación, habilidades, licencias
        profesionales, permisos o certificaciones, y referencias, así como
        información en cualquier documento que elijas proporcionar, como un CV,
        currículum o carta de presentación, y cualquier otra información que
        decidas proporcionarnos. Si compras nuestros Servicios o productos,
        puedes proporcionar tu información de tarjeta de crédito o débito. Tacit
        Social LLC. utiliza terceros para procesar pagos – no recopilamos ni
        almacenamos información de tarjetas de crédito. Si proporcionas tu
        número de teléfono, enviamos algunos tipos de mensajes de texto
        transaccionales directamente relacionados con el uso de tus Servicios,
        como confirmar tu cuenta y restablecer tu contraseña. Al proporcionar tu
        número de teléfono móvil, consientes expresamente recibir este tipo de
        mensajes de texto transaccionales de nuestra parte en ese número. Pueden
        aplicarse tarifas de datos y mensajes de terceros.
      </p>

      <h2 className={styles.h2}>
        INFORMACIÓN RECOPILADA AUTOMÁTICAMENTE DE DISPOSITIVOS
      </h2>

      <p className={styles.p}>
        Cuando accedes a nuestros Servicios, podemos recopilar automáticamente
        información que está vinculada a tu computadora, teléfono u otro
        dispositivo, incluyendo información relacionada con tu comportamiento y
        actividad mientras interactúas con nosotros, como tu dirección IP,
        sistema operativo y versión, zona horaria local, fecha, hora y actividad
        de tu solicitud, tipo de dispositivo (por ejemplo, escritorio, portátil,
        tableta, teléfono, etc.), fabricante del dispositivo, tamaño de pantalla
        y preferencia de idioma.
      </p>

      <p className={styles.p}>
        De manera consistente con la mayoría de los sitios web, usamos cookies y
        etiquetas de píxel. Las cookies son pequeños archivos de texto
        utilizados para recopilar información y los píxeles son imágenes
        transparentes que nos permiten entender cómo los usuarios interactúan
        con nuestros Sitios Web. Los Sitios Web utilizan cookies necesarias y
        cookies analíticas.
      </p>

      <h2 className={styles.h2}>NO SE RASTREA</h2>

      <p className={styles.p}>
        No Se Rastrear (“DNT”) es una configuración opcional del navegador que
        te permite expresar tus preferencias respecto al rastreo por parte de
        anunciantes y otros terceros. Empleamos tecnología para reconocer y
        respetar las preferencias de DNT que estableces en tu navegador web.
      </p>

      <h2 className={styles.h2}>Cómo usamos la información</h2>

      <p className={styles.p}>
        En general, usamos la información que recopilamos para brindarte los
        mejores Servicios posibles y mejorarlos continuamente con el tiempo.
      </p>

      <p className={styles.p}>
        Específicamente, podemos usar Información Anónima así como Información
        Personal como se describe en otra parte de esta Política de Privacidad
        para mejorar nuestros Servicios, para asegurar que nuestros Servicios
        funcionen correctamente y para otros fines de investigación y
        comerciales. Estos propósitos incluyen, entre otros, lo siguiente:
      </p>

      <ul className={styles.ul}>
        <li className={styles.li}>
          Proveer los Servicios y avisos relacionados;
        </li>
        <li className={styles.li}>
          Crear cuentas de usuario y/o perfiles a través del registro e importar
          contactos de usuario;
        </li>
        <li className={styles.li}>
          Responder a tus consultas y/o solicitudes sobre nuestros Servicios y
          productos;
        </li>
        <li className={styles.li}>
          Administrar, mantener, analizar, mejorar y personalizar los Servicios
          y crear una mejor experiencia para los usuarios en nuestros Servicios;
        </li>
        <li className={styles.li}>
          Proveer o enviar información sobre nuestros Servicios;
        </li>
        <li className={styles.li}>
          Enviar mensajes de texto transaccionales directamente relacionados con
          tu uso de tus Servicios (por ejemplo, confirmar tu cuenta y
          restablecer tu contraseña);
        </li>
        <li className={styles.li}>
          Recibir comentarios y proporcionar soporte al cliente y técnico para
          los Servicios;
        </li>
        <li className={styles.li}>
          Realizar encuestas, investigaciones y auditorías;
        </li>
        <li className={styles.li}>
          Cumplir con cualquier orden realizada a través de los Sitios Web
          (incluyendo el procesamiento de tu información de pago, la
          organización del envío y proporcionarte facturas y/o confirmaciones de
          pedido);
        </li>
        <li className={styles.li}>
          Procesar tu solicitud de empleo, incluyendo evaluar tus calificaciones
          para el trabajo, realizar verificaciones de referencias y realizar
          verificaciones de antecedentes si te ofrecemos un puesto, según lo
          permita la ley aplicable.
        </li>
        <li className={styles.li}>
          Proveer información a nuestros representantes y/o asesores, como
          nuestros abogados, contadores y otros para ayudarnos a cumplir con
          requisitos legales, contables o de seguridad;
        </li>
        <li className={styles.li}>
          Verificar y responder a tus solicitudes respecto a tu información
          personal o preguntas que puedas tener sobre esta Política de
          Privacidad.
        </li>
        <li className={styles.li}>
          Detectar, prevenir e investigar fraudes, hackeos, infracciones u otras
          conductas indebidas reales o sospechadas que involucren los Servicios;
          y para otros propósitos divulgados en el momento de la recopilación.
        </li>
      </ul>

      <p className={styles.p}>
        Además, podemos usar tu información personal si creemos que es necesario
        o estamos permitidos para propósitos legales, para proteger nuestro
        negocio, nuestros derechos o nuestra propiedad, o para abordar
        actividades que puedan ser ilegales, poco éticas o legalmente
        procesables.
      </p>

      <h2 className={styles.h2}>Cómo compartimos la información</h2>

      <p className={styles.p}>
        Tacit Social LLC. no vende tu información personal a terceros, ni
        divulgamos información personal a terceros para propósitos de marketing
        directo.
      </p>

      <p className={styles.p}>
        Podemos compartir Información Anónima con terceros como se describe en
        otra parte de esta Política de Privacidad y para mejorar nuestros
        Servicios y para otros propósitos comerciales.
      </p>

      <p className={styles.p}>
        Tomamos tu privacidad en serio, y podemos divulgar Información Personal
        solo de la siguiente manera:
      </p>

      <ul className={styles.ul}>
        <li className={styles.li}>
          <span className="font-bold">A Socios Comerciales Autorizados.</span>{" "}
          Los Socios Comerciales Autorizados son terceros cuyos servicios Tacit
          Social LLC. utiliza para operar y mejorar nuestros propios Servicios.
          Estos terceros incluyen agentes, contratistas, proveedores,
          integradores de sistemas, etc. Tacit Social LLC., nuestras afiliadas y
          nuestros socios comerciales pueden compartir esta Información Personal
          entre sí y usarla de acuerdo con esta Política de Privacidad. También
          pueden combinarla con otra información para proveer y mejorar nuestros
          Servicios.
        </li>
        <li className={styles.li}>
          <span className="font-bold">A empleados de soporte al cliente.</span>{" "}
          Empleados autorizados de soporte al cliente pueden acceder a tu
          Información Personal según sea necesario para resolver problemas que
          encuentres en nuestros Servicios y ayudarte a usar nuestros Servicios.
        </li>
        <li className={styles.li}>
          Podemos compartir información sobre ti por razones legales, de
          seguridad y protección. Podemos compartir información sobre ti si
          razonablemente creemos que divulgar la información es necesario para:
          <ul className={styles.ul}>
            <li className={styles.li}>
              Cumplir con cualquier proceso legal válido, solicitud
              gubernamental o ley, regla o regulación aplicable.
            </li>
            <li className={styles.li}>
              Investigar, remediar o hacer cumplir posibles violaciones de los
              Términos de Servicio.
            </li>
            <li className={styles.li}>
              Proteger los derechos, la propiedad o la seguridad de nosotros,
              nuestros usuarios u otros.
            </li>
            <li className={styles.li}>
              Detectar y resolver cualquier fraude o problema de seguridad.
            </li>
          </ul>
        </li>
        <li className={styles.li}>
          Podemos compartir información sobre ti como parte de una fusión o
          adquisición. Si Tacit Social LLC. se involucra en una fusión, venta de
          activos, financiamiento, liquidación o bancarrota, o adquisición de
          toda o parte de nuestro negocio por otra compañía, podemos compartir
          tu información con esa compañía antes y después del cierre de la
          transacción.
        </li>
      </ul>

      <h2 className={styles.h2}>NO VENDEMOS INFORMACIÓN PERSONAL</h2>

      <p className={styles.p}>
        Nunca hemos vendido Información Personal, y no venderemos tu Información
        Personal a terceros para su uso sin tu consentimiento.
      </p>

      <h2 className={styles.h2}>Protegiendo la Privacidad de los Niños</h2>

      <p className={styles.p}>
        PROTECCIÓN DE NIÑOS EN LÍNEA
        <br />
        Nuestros Servicios no están dirigidos a niños menores de trece (13)
        años, y no recopilamos conscientemente información personal de niños
        menores de trece (13) años. Si no tienes trece (13) años o más, no uses
        nuestro Sitio Web, Apps o Servicios. Si crees que hemos recibido la
        información de un niño, por favor contáctanos en
        hello@support.morereadings.me
      </p>

      <p className={styles.p}>
        LEY DE BORRADO
        <br />
        De acuerdo con la ley de California, los menores de dieciocho (18) años
        que residen en California pueden eliminar, o solicitar y obtener la
        eliminación de, contenido e información que comparten en los Sitios Web
        o la App. Para eliminar o solicitar y obtener la eliminación de dicho
        contenido e información, el usuario debe enviar un correo electrónico a
        hello@support.morereadings.me. Los usuarios deben ser conscientes de que
        eliminar contenido e información publicada por un menor no garantiza la
        eliminación completa o integral de contenido o información publicada en
        nuestros Servicios.
      </p>

      <p className={styles.p}>
        En ciertas circunstancias, Tacit Social LLC. puede no tener que cumplir
        con los requisitos de eliminación mencionados anteriormente. Tacit
        Social LLC. también se reserva el derecho, en la medida permitida por la
        ley, de anonimizar el contenido o información publicada o hacer que
        dicho contenido o información sea invisible para otros usuarios y el
        público, en lugar de eliminar o borrar el contenido o la información.
      </p>

      <h2 className={styles.h2}>Enlaces a sitios web de terceros</h2>

      <p className={styles.p}>
        Cuando usas nuestros Servicios, es posible que te dirijamos a otros
        sitios web y aplicaciones que están fuera de nuestro control. También
        podemos permitir que sitios web o aplicaciones de terceros enlacen a los
        Servicios. No somos responsables de las prácticas de privacidad de
        terceros ni del contenido de sitios web y aplicaciones enlazados, pero
        te animamos a leer las políticas de privacidad aplicables y los términos
        y condiciones de dichas partes, sitios web y aplicaciones. Esta Política
        de Privacidad solo se aplica a los Servicios.
      </p>

      <h2 className={styles.h2}>Características de Redes Sociales</h2>

      <p className={styles.p}>
        INFORMACIÓN QUE COMPARTES SOCIALMENTE
        <br />
        Ten en cuenta que los usuarios que ven cualquier contenido que compartas
        (por ejemplo, tu perfil o tus mensajes directos) siempre pueden guardar
        ese contenido o copiarlo fuera de la aplicación. Por lo tanto, el mismo
        sentido común que aplica a internet en general también aplica a Tacit
        Social LLC.: No compartas ni hagas público ningún contenido que no
        quieras que otra persona guarde o comparta.
      </p>

      <h2 className={styles.h2}>
        Cómo aseguramos, almacenamos y retenemos la información
      </h2>

      <p className={styles.p}>
        SEGURIDAD
        <br />
        Hemos implementado precauciones técnicas y organizativas razonables para
        proteger la confidencialidad, seguridad e integridad de tu información
        personal contra destrucción accidental o ilegal, pérdida, alteración,
        divulgación o acceso no autorizado. No podemos garantizar la seguridad
        de la información transmitida a nosotros a través de internet.
      </p>

      <p className={styles.p}>
        ALMACENAMIENTO
        <br />
        La Información Personal que nos proporcionas generalmente se almacena en
        servidores mantenidos por Heroku. Estos servidores están ubicados en los
        Estados Unidos. Si te encuentras en otra jurisdicción, debes ser
        consciente de que una vez que tu Información Personal se envía a través
        de nuestros Servicios, será transferida a nuestros servidores en los
        Estados Unidos y, por lo tanto, estará regida por las leyes de
        protección de datos locales, que pueden variar de estado a estado.
      </p>

      <p className={styles.p}>
        RETENCIÓN
        <br />
        Retendremos tu información personal durante el período necesario para
        cumplir con los propósitos descritos en esta Política de Privacidad, a
        menos que un período de retención más largo sea requerido o permitido
        por ley.
      </p>

      <h2 className={styles.h2}>Usuarios internacionales</h2>

      <p className={styles.p}>
        Tacit Social LLC tiene su sede en Canadá, y almacenamos información
        personal en servidores ubicados en los Estados Unidos, que pueden no
        tener las mismas leyes de protección de datos que el país en el que
        resides. Si estás usando nuestros Servicios desde fuera de los Estados
        Unidos o Canadá, tu información puede ser transferida, almacenada y
        procesada en los Estados Unidos y Canadá. Al usar nuestros Servicios,
        consientes dichas transferencias de esta información.
      </p>

      <p className={styles.p}>
        Podemos actuar como un “controlador” según lo definido bajo la ley de
        protección de datos aplicable cuando procesamos tu información desde el
        Sitio Web, Apps y Servicios. En general, nos basamos en nuestros
        intereses legítimos al procesar esta información. Estos intereses
        legítimos incluyen proveer nuestros Servicios, operar nuestro Sitio Web
        y Apps, y nuestros otros propósitos comerciales. También podemos
        procesar tu información personal para cumplir con contratos que podamos
        tener contigo. Si el consentimiento es la base legal, obtendremos tu
        consentimiento y te informaremos cómo puedes retirarlo.
      </p>

      <h2 className={styles.h2}>Tus opciones y derechos</h2>

      <p className={styles.p}>
        Si deseas actualizar tu información de contacto o preferencias, eliminar
        tu información de nuestros registros, o si tienes comentarios o
        preguntas sobre esta Política de Privacidad, por favor contáctanos en
        hello@support.morereadings.me.
      </p>

      <p className={styles.p}>
        No estás obligado a proporcionarnos tu información. Si decides no
        hacerlo, podemos no ser capaces de responder a tus solicitudes o
        preguntas, y sin cierta información como tu fecha de nacimiento, lugar
        de nacimiento y hora de nacimiento, algunas o todas las características
        de los Servicios pueden no estar disponibles o funcionar en absoluto.
      </p>

      <p className={styles.p}>
        Puedes optar por no recibir comunicaciones por correo electrónico en
        cualquier momento haciendo clic en el enlace de cancelación de
        suscripción dentro de cualquier mensaje que recibas. Puedes controlar
        cómo responde tu navegador a las cookies ajustando la configuración de
        privacidad y seguridad de tu navegador web. Puedes configurar tu
        navegador para no aceptar cookies, pero esto puede limitar ciertas
        funcionalidades en los Sitios Web. Cómo optar por no participar depende
        del tipo de navegador y/o dispositivo que estés usando. Si deseas optar
        por no participar en cookies analíticas, puedes optar por no participar
        en Google Analytics. Si resides o te encuentras en ciertas
        jurisdicciones, puedes tener derechos y protecciones en relación con tu
        información bajo las leyes aplicables. Estos derechos pueden incluir el
        derecho a acceder, eliminar, corregir, mover (o portar), oponerte al
        procesamiento y limitar el procesamiento de tu información así como el
        derecho a presentar una queja ante un regulador. Estos derechos no son
        absolutos, están sujetos a excepciones, y podemos estar obligados o
        permitidos por ley a rechazar tu solicitud. En la medida en que lo
        requieran y permitan las leyes aplicables, procesaremos tu solicitud, y
        si es aplicable, podemos pedirte información adicional para verificar tu
        identidad. Si deseas ejercer tus derechos o tienes preguntas sobre la
        manera en que procesamos tu información personal, por favor contáctanos
        en hello@support.morereadings.me
      </p>

      <h2 className={styles.h2}>Cambios a esta política de privacidad</h2>

      <p className={styles.p}>
        Podemos actualizar o modificar esta Política de Privacidad de vez en
        cuando y te notificaremos que se han hecho cambios indicando en la
        Política de Privacidad la fecha en que se actualizó por última vez en la
        parte superior de esta Política de Privacidad, publicando de manera
        prominente un anuncio de los cambios en nuestros Servicios, o enviándote
        una comunicación por escrito utilizando la información de contacto que
        nos proporcionaste antes de que la nueva política entre en vigor. Los
        usuarios están sujetos a cualquier cambio en la Política de Privacidad
        si utilizan nuestros Sitios Web, Apps y Servicios después de que se haya
        comunicado el aviso de dichos cambios. Te recomendamos revisar esta
        Política de Privacidad regularmente para asegurarte de estar
        familiarizado con las prácticas actuales de Tacit Social LLC.
      </p>
    </div>
  );
};
