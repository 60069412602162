import * as React from "react";
import { usePaymentUpsell } from "./usePaymentUpsell";
import { Initial } from "./screens/initial";
import { Conversion } from "./screens/conversion";
import { CheckoutModalForm } from "app/quiz/types/quiz2/v2/screens/conversionScreen/components/checkoutModalForm/checkoutModalForm";
import { PaymentStatusModal } from "../conversionScreen/components/paymentStatusModal/paymentStatusModal";
import { Loading } from "app/components/loading/loading";
import { useTranslation } from "react-i18next";

export interface PaymentUpsellProps {}

export const PaymentUpsell: React.FC<PaymentUpsellProps> = ({}) => {
  const {
    email,
    step,
    trialPlan,
    onClaimDiscount,
    clientSecret,
    showPaymentModal,
    setShowPaymentModal,
    showPaymentStatusModal,
    setShowPaymentStatusModal,
    paymentStatus,
    createSetupIntent,
    loadingPaymentStatus,
    fromCanceled,
  } = usePaymentUpsell();
  const { t } = useTranslation();

  if (loadingPaymentStatus) {
    return (
      <div className="absolute left-1/2 top-1/3 -translate-x-1/2">
        <Loading />
        <p>{t("quiz.finalizing_payment")}</p>
      </div>
    );
  }

  const getPage = () => {
    switch (step) {
      case 0:
        return <Initial onClaimDiscount={onClaimDiscount} />;
      case 1:
        return (
          <Conversion
            trialPlan={trialPlan}
            setShowPaymentModal={setShowPaymentModal}
            fromCanceled={fromCanceled}
            subPrice="$19.99"
          />
        );
      default:
        return (
          <Conversion
            trialPlan={trialPlan}
            setShowPaymentModal={setShowPaymentModal}
            fromCanceled={fromCanceled}
            subPrice="$19.99"
          />
        );
    }
  };

  const page = getPage();

  return (
    <>
      <PaymentStatusModal
        open={showPaymentStatusModal}
        onOpenChange={setShowPaymentStatusModal}
        type={paymentStatus!}
        planDeal={trialPlan!.value}
        email={email!}
        setShowPaymentModal={setShowPaymentModal}
        createSetupIntent={createSetupIntent}
      />
      {showPaymentModal && clientSecret && (
        <CheckoutModalForm
          clientSecret={clientSecret}
          showPaymentModal={showPaymentModal}
          setShowPaymentModal={setShowPaymentModal}
        />
      )}
      {page}
    </>
  );
};
