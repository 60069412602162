import * as React from "react";
import { DatePicker } from "@nextui-org/date-picker";
import { useBirthDateScreen } from "./useBirthDateScreen";
import ReactPlayer from "react-player";
import { titleize } from "app/utils/strings";
import {
  getLocalTimeZone,
  today,
  parseDate,
  toCalendarDateTime,
} from "@internationalized/date";
import { DateInput } from "@nextui-org/react";
import { useTranslation } from "react-i18next";

export interface BirthDateScreenProps {
  relationshipProfile: any;
  setRelationshipProfile: (profile: any) => void;
}

export const BirthDateScreen: React.FC<BirthDateScreenProps> = ({
  relationshipProfile,
  setRelationshipProfile,
}) => {
  const { horoscope, onBirthDateChange, formatDateForUniversally } =
    useBirthDateScreen({
      relationshipProfile,
      setRelationshipProfile,
    });
  const { t } = useTranslation();
  return (
    <div className="flex justify-center items-center h-[50vh] flex-col gap-[12%]">
      <div className="flex flex-col gap-[12px] justify-center items-center">
        <ReactPlayer
          className="react-player"
          url={horoscope?.video}
          playing
          muted
          width={135}
          height={135}
          config={{
            file: {
              attributes: {
                playsInline: true,
              },
            },
          }}
        />
        <p className="font-aquino text-2xl">{titleize(horoscope?.name)}</p>
      </div>
      <DateInput
        classNames={{
          segment: "!text-[#DBD2BE]",
          label: "!text-[#DBD2BE]",
          inputWrapper:
            "!bg-[#1A1814] rounded-sm h-16 hover:!bg-[#1A1814] focus:!bg-[#1A1814] selected:!bg-[#1A1814]",
        }}
        radius="sm"
        minValue={parseDate("1000-01-01")}
        maxValue={today(getLocalTimeZone())}
        defaultValue={today(getLocalTimeZone())}
        color="default"
        label={t("birth_date")}
        onChange={(date) => {
          if (date && date.year && date.month && date.day && date.year > 1000) {
            const formattedDate = formatDateForUniversally(
              date.year,
              date.month,
              date.day
            );
            onBirthDateChange(new Date(formattedDate));
          }
        }}
      />
    </div>
  );
};
