import React from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements, PaymentElement } from "@stripe/react-stripe-js";
import { Dialog } from "app/subframe/components/Dialog";
import { PrimaryButton } from "app/components/buttons/button";
import { useCheckoutModalForm } from "./useCheckoutModalForm";
import MoreReadingsLogoSvg from "app/assets/images/moreReadingsLogo.svg";
import { Spinner } from "@nextui-org/react";
import { useTranslation } from "react-i18next";

// Load Stripe with the publishable key
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY!); // Replace with your actual Stripe publishable key

interface CheckoutModalFormProps {
  clientSecret: string;
  showPaymentModal: boolean;
  setShowPaymentModal: (show: boolean) => void;
}

export const CheckoutModalForm: React.FC<CheckoutModalFormProps> = ({
  showPaymentModal,
  setShowPaymentModal,
  clientSecret,
}) => {
  const { i18n } = useTranslation();

  return (
    <Dialog open={showPaymentModal} onOpenChange={setShowPaymentModal}>
      <Dialog.Content className="w-112 bg-black border-[#DBD2BE] p-5 mobile:w-11/12">
        {/* Ensure that Elements wraps everything that needs stripe or elements context */}
        <div className="flex justify-between mb-5">
          <img
            src={MoreReadingsLogoSvg}
            alt="More Readings Logo"
            className="mx-auto"
          />
        </div>
        <Elements
          stripe={stripePromise}
          options={{
            clientSecret,
            locale: i18n.language || ("en" as any),
            appearance: { theme: "night", labels: "floating" },
          }}
        >
          <CheckoutForm />
        </Elements>
      </Dialog.Content>
    </Dialog>
  );
};

// Extract the actual checkout form into a separate component
const CheckoutForm: React.FC = () => {
  const { onSubscribe, loading, errorMessage } = useCheckoutModalForm();
  const { t } = useTranslation();

  return (
    <form onSubmit={onSubscribe} className="w-full">
      <PaymentElement className="w-full" />
      <PrimaryButton
        className="w-full mt-5 rounded"
        type="submit"
        disabled={loading}
      >
        <p className="text-black font-semibold">
          {loading
            ? t("checkout_form.processing")
            : t("checkout_form.complete")}
        </p>
        {loading && (
          <div className="">
            <Spinner size="sm" color="warning" />
          </div>
        )}
      </PrimaryButton>
      {errorMessage && <div className="text-red-500 mt-2">{errorMessage}</div>}
    </form>
  );
};
