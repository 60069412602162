import { useQuery } from "app/utils/useQuery";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { Terms as TermsEn } from "./en/terms";
import { Terms as TermsEs } from "./es/terms";

export interface TermsProps {}

export const Terms: React.FC<TermsProps> = (props) => {
  const { i18n } = useTranslation();

  const getTermsInLanguage = () => {
    switch (i18n.language) {
      case "es":
        return <TermsEs />;
      default:
        return <TermsEn />;
    }
  };

  return getTermsInLanguage();
};
