export enum localStorageEnums {
  CHAT_INPUT = "chatInput",
  PICKED_TAROT_CARD = "pickedTarotCard",
  TAROT_CARD_REDRAW = "tarotCardRedraw",
  TAROT_INTENTION = "tarotIntention",
  LANDING_TAROT_CATEGORY = "landingTarotCategory",
  CONVERSATION_LOG = "conversationLog",
  NATAL_CHART = "natalChart",
  ELEMENT = "element",
  HOUSE_CUSPS = "houseCusps",
  SEVENTH_HOUSE_REPORT = "seventhHouseReport",
}
