import * as React from "react";
import CresentMoonSvg from "app/assets/icons/cresentMoon.svg";
import HalfMoonSvg from "app/assets/icons/halfMoon.svg";
import FullMoonSvg from "app/assets/icons/fullMoon.svg";
import classnames from "classnames";

export interface MoonStepsProps {
  currentScreen: number;
}

export const MoonSteps: React.FC<MoonStepsProps> = ({ currentScreen }) => {
  return (
    <>
      <img
        src={CresentMoonSvg}
        alt="cresent moon"
        className={classnames({
          "opacity-30": currentScreen !== 1,
          "opacity-100": currentScreen === 1,
        })}
      />
      <img
        src={HalfMoonSvg}
        alt="half moon"
        className={classnames({
          "opacity-30": currentScreen !== 2,
          "opacity-100": currentScreen === 2,
        })}
      />
      <img
        src={FullMoonSvg}
        alt="full moon"
        className={classnames({
          "opacity-30": currentScreen !== 3,
          "opacity-100": currentScreen === 3,
        })}
      />
    </>
  );
};
