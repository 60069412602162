import { useQuery } from "app/utils/useQuery";
import { useLayoutEffect } from "react";
import { useTranslation } from "react-i18next";

export const useUpdateLanguage = () => {
  const query = useQuery();
  const { i18n } = useTranslation();
  const lang = query.get("lang");

  useLayoutEffect(() => {
    if (lang && i18n.language !== lang) {
      i18n.changeLanguage(lang).catch(console.error);
    }
  }, [lang, i18n]);
};
