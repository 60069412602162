import { useQuery } from "app/utils/useQuery";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { Policy as PolicyEn } from "./en/policy";
import { Policy as PolicyEs } from "./es/policy";

export interface PolicyProps {}

export const Policy: React.FC<PolicyProps> = (props) => {
  const { i18n } = useTranslation();

  const getPolicyInLanguage = () => {
    switch (i18n.language) {
      case "es":
        return <PolicyEs />;
      default:
        return <PolicyEn />;
    }
  };

  return getPolicyInLanguage();
};
