import { Path } from "app/path";
import { useQuery } from "app/utils/useQuery";
import * as React from "react";
import { useEffect, useRef } from "react";
import { generatePath, useNavigate, useParams } from "react-router";
import CryptoJS from "crypto-js";
import { usePostHog } from "posthog-js/react";
import { localStorageEnums } from "app/dictionaries/localStorageDictionary";

export const useQuiz2 = () => {
  const posthog = usePostHog();
  const navigate = useNavigate();
  const query = useQuery();
  const hideDeviceScreen = query.get("hds") === "true";
  const [currentScreenIndex, setCurrentScreenIndex] = React.useState(0);
  const previousScreenIndex = useRef(0);
  const baseRoot = generatePath(Path.QUIZ, { type: "quiz2" });

  const appendAnswerToParams = ({ key, value, nextPageIndex }: any) => {
    if (key && value !== undefined && value !== null) {
      query.set(key, value);
    }

    if (nextPageIndex) {
      query.set("page", nextPageIndex + 1);
      navigate(`${baseRoot}?${query.toString()}`);
      setCurrentScreenIndex(nextPageIndex);
    }
    window.scroll(0, 0);
  };

  const goBack = () => {
    const screensThatRequire2PagesBack = ["15", "17", "23"];
    const numberOfPagesBack = query.get("page")
      ? screensThatRequire2PagesBack.includes(query.get("page")!)
        ? 2
        : 1
      : 1;

    const newScreenIndex = currentScreenIndex - numberOfPagesBack;
    query.set("page", (newScreenIndex + 1).toString());
    navigate(`${baseRoot}?${query.toString()}`);
    setCurrentScreenIndex(newScreenIndex);
    window.scroll(0, 0);
  };

  useEffect(() => {
    if (query.has("page")) {
      setCurrentScreenIndex(Number(query.get("page")) - 1);
    }
  }, []);

  useEffect(() => {
    if (process.env.NODE_ENV !== "production") {
      return;
    }
    const email = query.get("email");

    // Only send event if the user is moving forward
    if (currentScreenIndex > previousScreenIndex.current) {
      posthog?.capture("quiz_2_progress", {
        page: currentScreenIndex + 1, // Send the page number (1-based index)
        email: email || null,
      });
    }

    // Update previousScreenIndex for next render
    previousScreenIndex.current = currentScreenIndex;
  }, [currentScreenIndex]);

  useEffect(() => {
    if (process.env.NODE_ENV !== "production") {
      return;
    }

    const handleBeforeUnload = () => {
      const email = query.get("email");
      posthog?.capture("quiz_2_page_leave", {
        page: currentScreenIndex + 1,
        email: email || null,
      });
    };

    // Add event listener
    window.addEventListener("beforeunload", handleBeforeUnload);

    // Clean up the event listener on unmount
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [currentScreenIndex, query]);

  useEffect(() => {
    const page = query.get("page") || "1";
    if (page === "1") {
      localStorage.removeItem(localStorageEnums.CONVERSATION_LOG);
      localStorage.removeItem(localStorageEnums.CHAT_INPUT);
    }
    if (page === "1" && process.env.NODE_ENV === "production") {
      try {
        window.ttq.track("ViewContent", {
          contents: [
            {
              content_id: "quiz-2", // string. ID of the product. Example: "1077218".
              content_type: "product_group", // string. Either product or product_group.
              content_name: "Quiz 2", // string. The name of the page or product. Example: "shirt".
            },
          ],
          email: "",
          value: 0, // number. Value of the order or items sold. Example: 100.
          currency: "USD", // string. The 4217 currency code. Example: "USD".
        });
      } catch (err) {
        console.error("Error with TikTok Pixel track:", err);
      }

      try {
        window.fbq("track", "ViewContent", {
          content_ids: ["quiz-2"], // Array of content IDs. Example: ["quiz-2"].
          content_type: "product_group", // Either 'product' or 'product_group'.
          content_name: "Quiz 2", // The name of the content.
          value: 0, // The value of the content/item.
          currency: "USD", // The currency code (ISO 4217 format).
        });
      } catch (err) {
        console.error("Error with Facebook Pixel track:", err);
      }
    }
  }, []);

  useEffect(() => {
    try {
      const email = query.get("email");
      const hashedEmail = CryptoJS.SHA256(email!).toString();

      if (process.env.NODE_ENV === "production") {
        // TikTok Pixel: Identify
        try {
          window.ttq.identify({
            email: hashedEmail, // Hashed email using SHA-256
          });
        } catch (err) {
          console.error("Error identifying user with TikTok Pixel:", err);
        }

        // Meta Pixel: Identify
        try {
          window.fbq("init", "1076681027353651", {
            em: hashedEmail,
          });
        } catch (err) {
          console.error("Error identifying user with Meta Pixel:", err);
        }
      }
    } catch (err) {
      console.error("Error processing email for tracking pixels:", err);
    }
  }, [query.get("email")]);

  return {
    currentScreenIndex,
    setCurrentScreenIndex,
    appendAnswerToParams,
    goBack,
    hideDeviceScreen,
  };
};
