export const COLORS = {
  primary: "black",
  tertiary: "#1C0000",
  gray: "gray",
  DD: "#C1C2C5",
  DD2: "#909296",
  DD5: "#2C2E33",
  DD7: "#1A1B1E",
  DD8: "#141517",
  DD9: "#101113",
  HME: "#F5E7C8",
  PE: "#DBD2BE",
  PE1: "#C2B9A4",
  PE2: "#A89F8B",
  PE3: "#8F8671",
  PE4: "#756C58",
  PE5: "#5C533E",
  PE6: "#423925",
  PE7: "#29200B",
  PE8: "#0F0600",
  HMD: "#E7E7E7",
  HMD1: "#CECECE",
  HMD2: "#B6B6B6",
  HMD8: "#232323",
  HMD9: "#0B0B0B",
  HME2: "#C2B495",
  HME6: "#5C4E2F",
  HME9: "#100200",
  HMDS5: "#6D6D6D",
  HMDS3: "#A3A3A1",
  HMDS7: "#3C3C3C",
  HMDS9: "#1A1814",
  REDS3: "#F59E9E",
  REDS5: "#F06D6E",
  REDS8: "#E92425",
  REDS9: "#E60C0D",
};
