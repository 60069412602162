export const useChatMessage = () => {
  const sectionResponse = ({
    message,
    isUser,
  }: {
    message: string;
    isUser: boolean;
  }) => {
    if (isUser) {
      return [message];
    }
    const sentences = message.split(". ");
    // group sentences into paragraphs where each paragraph has max 300 characters
    const paragraphs: string[] = [];
    let paragraph = "";

    sentences.forEach((sentence, index) => {
      const addPeriod = index < sentences.length - 1 && !sentence.endsWith("?");
      const extendedParagraph = paragraph + sentence + (addPeriod ? ". " : " ");

      if (extendedParagraph.length > 300) {
        if (paragraph !== "") {
          paragraphs.push(paragraph.trim());
          paragraph = "";
        }
        paragraph = sentence + (addPeriod ? ". " : " ");
      } else {
        paragraph = extendedParagraph;
      }
    });

    if (paragraph !== "") {
      paragraphs.push(paragraph);
    }

    return paragraphs;
  };

  return { sectionResponse };
};
