import * as React from "react";
import MoonPng from "app/assets/images/moon.png";
import { usePaymentSuccessScreen } from "./usePaymentSuccessScreen";
import { PrimaryButton } from "app/components/buttons/button";
import classNames from "classnames";
import { Link } from "react-router-dom";
import MoreReadingsLogoSvg from "app/assets/images/moreReadingsLogo.svg";
import { FadeInSlide } from "app/motions/fadeInSlide";
import { useTranslation } from "react-i18next";

export interface PaymentSuccessScreenProps {}

export const PaymentSuccessScreen: React.FC<
  PaymentSuccessScreenProps
> = ({}) => {
  const { trialPlan, email, fromCanceled } = usePaymentSuccessScreen();
  const { t } = useTranslation();
  return (
    <FadeInSlide direction="top" key="successPaymentScreen">
      <div
        className={classNames(
          "flex flex-col max-w-[512px] mx-auto mobile:max-w-[313px]"
        )}
      >
        <div className="flex flex-col items-center mb-12">
          <img src={MoonPng} alt="sign" className="h-[70%] w-[70%]" />

          <div className="flex flex-col gap-10 mobile:gap-5">
            <p className="text-xl leading-8 text-center">
              {fromCanceled
                ? t("thanks_for_giving_us_another_try")
                : t("thanks_for_purchasing", {
                    trialPlan: trialPlan.title,
                  })}{" "}
              {email!.replace(/\ /g, "+")}.
            </p>

            <p className="text-xl leading-8 text-center">
              {t("download_app")}{" "}
              <Link
                to={"mailto:hello@support.morereadings.me"}
                className="border-b"
              >
                hello@support.morereadings.me
              </Link>
            </p>
          </div>
        </div>
        <PrimaryButton
          className="w-full !p-6"
          onClick={() =>
            (window.location.href =
              "https://apps.apple.com/us/app/more-readings-love-companion/id6479682201?platform=iphone")
          }
        >
          <p className="font-public text-black text-2xl font-semibold">
            {t("download_ios")}
          </p>
        </PrimaryButton>
        <div
          className={classNames(
            "mx-auto mobile:scale-75 mobile:bottom-5 mt-20 mb-10",
            {}
          )}
        >
          <img src={MoreReadingsLogoSvg} alt="more readings" />
        </div>
      </div>
    </FadeInSlide>
  );
};
