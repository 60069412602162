import * as React from "react";
import classNames from "classnames";
import { COLORS } from "app/dictionaries/colors";

export interface StepIndicatorProps {
  step: number;
  steps?: number;
}

export const StepIndicator: React.FC<StepIndicatorProps> = ({
  step,
  steps = 2,
}) => {
  return (
    <div className="flex gap-2">
      {Array.from({ length: steps }, (_, index) => index + 1).map((node) => (
        <div
          key={node}
          className={classNames(
            "w-2 h-2 transform rotate-45",
            step === node ? " shadow-lg border border-[#F5E7C8] scale-125" : "",
            node <= step ? `bg-[#C2B9A4]` : `bg-[#423925]`
          )}
        />
      ))}
    </div>
  );
};
