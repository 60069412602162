import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import en from "./locales/en/common.json";
import es from "./locales/es/common.json";

const supportedLanguages = ["en", "es"];

i18n.use(initReactI18next).init({
  fallbackLng: "en",
  defaultNS: "common",
  ns: ["common"],
  supportedLngs: supportedLanguages,
  interpolation: {
    escapeValue: false,
  },
  resources: {
    en: { common: en },
    es: { common: es },
  },
});

declare module "i18next" {
  interface CustomTypeOptions {
    defaultNS: "common";
    resources: {
      common: typeof en;
    };
  }
}

export default i18n;
