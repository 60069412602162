import React from "react";
import { Textarea, Button } from "@nextui-org/react";
import classnames from "classnames";
import styles from "./textInput.module.css";
import chatStyles from "app/components/chat/chat.module.css";
import YellowUpArrowIcon from "app/assets/icons/yellowUpArrow.svg";

interface TextInputProps {
  textInput: any;
  isChatFocused: boolean;
  onSubmit: any;
  setIsChatFocused: (isFocused: boolean) => void;
  onTextInputChange: (e: any) => void;
  handleKeyPress: (e: React.KeyboardEvent) => void;
  setIsDrawTarotModalOpen?: (isOpen: boolean) => void;
  showSubmitButton?: boolean;
  placeholder?: string;
  name: string;
  required?: boolean;
  leftPadding?: boolean;
  show?: boolean;
  isTextInputDisabled?: boolean;
}

export const TextInput: React.FC<TextInputProps> = ({
  textInput,
  isChatFocused,
  onSubmit,
  setIsChatFocused,
  onTextInputChange,
  handleKeyPress,
  showSubmitButton = false,
  placeholder = "",
  leftPadding = false,
  name,
  show = true,
  isTextInputDisabled = false,
}) => {
  return (
    <form
      onSubmit={onSubmit}
      className={classnames("w-full", {
        hidden: !show,
      })}
    >
      <Textarea
        labelPlacement="outside"
        placeholder={placeholder}
        name={name}
        fullWidth
        variant="flat"
        onFocus={() => setIsChatFocused(true)}
        onBlur={() => setIsChatFocused(false)}
        classNames={{
          base: classnames(styles.textAreaBase),
          inputWrapper: classnames(styles.inputWrapper, {
            [styles.focusInputWrapper]: isChatFocused || textInput,
            "!pl-4": leftPadding,
          }),
          innerWrapper: "items-center",
          input: "!placeholder-[#A89F8B] !text-[#F5E7C8] w-[calc(100%-32px)]",
        }}
        isDisabled={isTextInputDisabled}
        type="text"
        endContent={
          showSubmitButton && (
            <Button
              isIconOnly
              className={classnames(
                chatStyles.animatedBtnGradient,
                "bg-transparent rounded w-[32px] !h-[32px] absolute right-1 bottom-1"
              )}
              onPress={() => onSubmit({ message: textInput })}
              variant="faded"
              isDisabled={!textInput}
            >
              <img src={YellowUpArrowIcon} alt="send" />
            </Button>
          )
        }
        value={textInput}
        onChange={onTextInputChange}
        onKeyDown={handleKeyPress}
        minRows={1}
        maxRows={4}
      />
      <button type="submit" className="hidden">
        send
      </button>
    </form>
  );
};
