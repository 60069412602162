import { TFunction } from "i18next";

export enum RelationshipTypes {
  ROMANTIC_PARTNER = "romantic_partner",
  FRIEND = "friend",
  FAMILY = "family",
  ENEMY = "enemy",
  BOSS = "boss",
  COLLEAGUE = "colleague",
  RATHER_NOT_SAY = "rather_not_say",
  MOM = "mom",
  DAD = "dad",
  GRANDMA = "grandma",
  GRANDPA = "grandpa",
  PET = "pet",
  CRUSH = "crush",
}

export const relationshipProfileOrder = [
  RelationshipTypes.ROMANTIC_PARTNER,
  RelationshipTypes.CRUSH,
  RelationshipTypes.PET,
  RelationshipTypes.MOM,
  RelationshipTypes.DAD,
  RelationshipTypes.GRANDMA,
  RelationshipTypes.GRANDPA,
  RelationshipTypes.FAMILY,
  RelationshipTypes.FRIEND,
  RelationshipTypes.ENEMY,
  RelationshipTypes.BOSS,
  RelationshipTypes.COLLEAGUE,
  RelationshipTypes.RATHER_NOT_SAY,
];

export const sortedRelationshipProfiles = (relationshipProfiles: any) =>
  [...relationshipProfiles].sort(
    (a, b) =>
      relationshipProfileOrder.indexOf(a.relationship) -
      relationshipProfileOrder.indexOf(b.relationship)
  );

export const relationshipTypesList = (t: TFunction) => [
  {
    key: RelationshipTypes.RATHER_NOT_SAY,
    title: t("relationship.ratherNotSay"),
  },
  {
    key: RelationshipTypes.ROMANTIC_PARTNER,
    title: t("relationship.romanticPartner"),
  },
  { key: RelationshipTypes.MOM, title: t("relationship.mom") },
  { key: RelationshipTypes.DAD, title: t("relationship.dad") },
  { key: RelationshipTypes.GRANDMA, title: t("relationship.grandma") },
  { key: RelationshipTypes.GRANDPA, title: t("relationship.grandpa") },
  { key: RelationshipTypes.FAMILY, title: t("relationship.family") },
  { key: RelationshipTypes.FRIEND, title: t("relationship.friend") },
  { key: RelationshipTypes.ENEMY, title: t("relationship.enemy") },
  { key: RelationshipTypes.BOSS, title: t("relationship.boss") },
  { key: RelationshipTypes.COLLEAGUE, title: t("relationship.colleague") },
  { key: RelationshipTypes.PET, title: t("relationship.pet") },
  { key: RelationshipTypes.CRUSH, title: t("relationship.crush") },
];
