import { NextUIProvider } from "@nextui-org/react";
import "./App.css";
import { Router } from "./routes/router";
import { useApp } from "./useApp";
import { ToastContainer, Slide } from "react-toastify";
import { useUpdateLanguage } from "./language/useUpdateLanguage";

function App() {
  const { navigate } = useApp();
  useUpdateLanguage();

  return (
    <NextUIProvider navigate={navigate}>
      <Router />
      <ToastContainer
        position="top-center"
        autoClose={7000}
        newestOnTop={false}
        closeOnClick
        transition={Slide}
        // className={"!w-full !max-w-[414px] !p-2"}
        style={{ width: "100%", maxWidth: "550px", padding: "2px" }}
        rtl={false}
        hideProgressBar={true}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
    </NextUIProvider>
  );
}

export default App;
