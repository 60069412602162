import * as React from "react";
import { useAssignReaderScreen } from "./useAssignReaderScreen";
import classNames from "classnames";
import { TitleDescription } from "app/quiz/components/titleDescription";
import { useTranslation } from "react-i18next";
import { BorderedAvatar } from "app/components/avatars/avatars";
import { PrimaryButton } from "app/components/buttons/button";
import { FadeInSlide } from "app/motions/fadeInSlide";

export interface AssignReaderScreenProps {
  currentScreenIndex: number;
  appendAnswerToParams: ({ key, value, nextPageIndex }: any) => void;
}

export const AssignReaderScreen: React.FC<AssignReaderScreenProps> = ({
  currentScreenIndex,
  appendAnswerToParams,
}) => {
  const { t } = useTranslation();
  const { readingModel, element } = useAssignReaderScreen({
    currentScreenIndex,
    appendAnswerToParams,
  });

  return (
    <div
      className={classNames(
        "flex flex-col gap-6 text-center max-w-[414px] justify-center mobile:max-w-[313px]"
      )}
    >
      <TitleDescription
        title=""
        description={t("assignReader.theStarsMatchedYouWith")}
      />
      <FadeInSlide direction="bottom" key={""}>
        <BorderedAvatar src={readingModel?.image} size="lg" />
      </FadeInSlide>
      <p className="text-2xl">{readingModel?.name}</p>
      <p>{t("assignReader.matchedWith", { element: element && element })}</p>
      <p>{readingModel?.trait}</p>
      <p>
        {t("assignReader.coupleMoreQuestions", {
          gender: readingModel?.gender,
        })}
      </p>
      <PrimaryButton
        className="w-full !p-6 mt-4"
        onClick={() =>
          appendAnswerToParams({ nextPageIndex: currentScreenIndex + 1 })
        }
      >
        <p className="font-public text-black text-2xl font-semibold">
          {t("sound_good_next")}
        </p>
      </PrimaryButton>
    </div>
  );
};
