export const useBirthTimeScreen = ({
  relationshipProfile,
  setRelationshipProfile,
}: {
  relationshipProfile: any;
  setRelationshipProfile: (profile: any) => void;
}) => {
  const handleTimeChange = (time: {
    hour: number;
    minute: number;
    second: number;
    millisecond: number;
  }) => {
    const { hour, minute } = time;

    // Create a new Date object based on the existing birthDate
    const birthDate = new Date(relationshipProfile.birthDate);
    // Use UTC methods to avoid timezone issues
    const updatedBirthDate = new Date(
      Date.UTC(
        birthDate.getUTCFullYear(),
        birthDate.getUTCMonth(),
        birthDate.getUTCDate(),
        hour,
        minute,
        time.second,
        time.millisecond
      )
    );

    setRelationshipProfile({
      ...relationshipProfile,
      birthDate: updatedBirthDate,
    });
  };

  return {
    handleTimeChange,
  };
};
