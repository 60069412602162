import * as React from "react";

export const useScreen1 = ({
  setGender,
  setCurrentScreen,
}: {
  setGender: (gender: string) => void;
  setCurrentScreen: (screen: number) => void;
}) => {
  const handleGenderSelect = (key: string) => {
    setGender(key);
    setCurrentScreen(2);
  };

  return {
    handleGenderSelect,
  };
};
