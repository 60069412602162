import React from "react";
import classnames from "classnames";
import DarkMessageTail from "app/assets/icons/darkMessageTail.svg";
import LightMessageTail from "app/assets/icons/lightMessageTail.svg";
import { ChatRole } from "app/components/chat/useChat";
import { useChatMessage } from "./useChatMessage";

interface ChatMessageProps {
  isUser: boolean;
  message: string;
  chat: any; // Define a more specific type if available
}

const ChatMessage: React.FC<ChatMessageProps> = ({ isUser, message, chat }) => {
  const { sectionResponse } = useChatMessage();
  return (
    <div className="flex flex-col gap-1">
      {sectionResponse({ message, isUser }).map(
        (text: string, index: number, self: any) => (
          <div
            className={classnames("flex items-start relative", {
              "justify-flex-end flex-row-reverse": isUser,
            })}
            key={index}
          >
            <p
              className={classnames(
                "flex items-center text-gray-800 text-sm rounded !font-light p-2 mx-[10px] max-w-[80%]",
                {
                  "bg-[#DBD2BE] text-[#0B0B0B]": isUser,
                  "bg-[#1A1814] !text-[#F5E7C8]": !isUser,
                }
              )}
            >
              {text}
              {index === self.length - 1 &&
                chat.role === ChatRole.ASSISTANT && (
                  <img
                    src={DarkMessageTail}
                    alt="dark message tail"
                    className="w-[10px] h-[9px] absolute bottom-0 left-[5px]"
                  />
                )}
              {index === self.length - 1 && chat.role === ChatRole.USER && (
                <img
                  src={LightMessageTail}
                  alt="light message tail"
                  className="w-[10px] h-[9px] absolute bottom-0 right-[5px]"
                />
              )}
            </p>
          </div>
        )
      )}
    </div>
  );
};

export default ChatMessage;
