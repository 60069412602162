import { useLocation, useNavigate } from "react-router-dom";

declare global {
  interface Window {
    ttq: any;
    fbq: any;
    tolt_referral: any;
    tolt: any;
  }
}

export const useApp = () => {
  const navigate = useNavigate();
  const location = useLocation();

  return { navigate, location };
};
