import { localStorageEnums } from "app/dictionaries/localStorageDictionary";
import { readingModelDictionary } from "app/dictionaries/readingModelsDictionary";
import * as React from "react";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";

export const useAssignReaderScreen = ({
  currentScreenIndex,
  appendAnswerToParams,
}: {
  currentScreenIndex: number;
  appendAnswerToParams: ({ key, value, nextPageIndex }: any) => void;
}) => {
  const { t } = useTranslation("common", { keyPrefix: "readingModels" });
  const [readingModel, setReadingModel] = React.useState<any | null>(null);
  const [element, setElement] = React.useState<string | null>(null);
  const translatedReadingModelDictionary = readingModelDictionary(t);

  useEffect(() => {
    const localStorageElement = localStorage.getItem(localStorageEnums.ELEMENT);
    if (localStorageElement) {
      const element = localStorageElement;
      const selectedReadingModel = translatedReadingModelDictionary[element];
      setElement(element);
      setReadingModel(selectedReadingModel);
    } else {
      appendAnswerToParams({ nextPageIndex: currentScreenIndex - 2 });
    }
  }, []);

  return {
    readingModel,
    element,
  };
};
