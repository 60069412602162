import { Input } from "@nextui-org/react";
import { NativeSelect } from "app/components/nativeSelect/nativeSelect";
import { COLORS } from "app/dictionaries/colors";
import {
  relationshipTypesList,
  sortedRelationshipProfiles,
} from "app/dictionaries/relationshipProfileDictionary";
import * as React from "react";
import { useTranslation } from "react-i18next";

export interface RelationshipScreenProps {
  relationshipProfile: any;
  setRelationshipProfile: (profile: any) => void;
}

export const RelationshipScreen: React.FC<RelationshipScreenProps> = ({
  relationshipProfile,
  setRelationshipProfile,
}) => {
  const { t } = useTranslation();
  return (
    <div className="mt-10">
      <div className="gap-[100px] flex flex-col">
        <section className="mt-10">
          <p className={`font-aquino text-[${COLORS.PE}] text-2xl mb-8`}>
            {t("name_label")}
          </p>
          <Input
            style={{
              color: COLORS.PE,
              fontFamily: "Aquino",
              fontSize: "1.5rem",
              marginTop: "10px",
              borderColor: COLORS.PE,
            }}
            value={relationshipProfile.name}
            classNames={{
              inputWrapper: `!border-[#DBD2BE] !border-b-1 !bg-transparent p-0`,
            }}
            radius="none"
            size="lg"
            isClearable
            type="text"
            onValueChange={(value) =>
              setRelationshipProfile({ ...relationshipProfile, name: value })
            }
          />
        </section>

        <section className="flex items-center gap-4">
          <p className={`font-aquino text-[${COLORS.PE}] text-2xl w-1/3`}>
            {t("relation_label")}
          </p>
          <div className="w-2/3">
            <NativeSelect
              onSelectedOptionChange={(value) =>
                setRelationshipProfile({
                  ...relationshipProfile,
                  relationship: value,
                })
              }
              selectedOption={relationshipProfile.relationship}
              options={sortedRelationshipProfiles(relationshipTypesList(t))}
            />
          </div>
        </section>
      </div>
    </div>
  );
};
