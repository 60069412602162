import { useElements, useStripe } from "@stripe/react-stripe-js";
import { useQuery } from "app/utils/useQuery";
import * as React from "react";

export const useCheckoutModalForm = () => {
  const stripe = useStripe();
  const elements = useElements();

  const [loading, setLoading] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState<string | null>(null);

  const onSubscribe = async (event: React.FormEvent) => {
    event.preventDefault();

    if (!stripe || !elements) {
      console.log("Stripe.js or Elements have not loaded yet.");
      return;
    }

    setLoading(true);
    setErrorMessage(null);

    try {
      // Confirm the setup intent using the PaymentElement to save the payment method for future use
      const { error } = await stripe.confirmSetup({
        elements,
        confirmParams: {
          return_url: window.location.href, // Redirect after setup is complete
        },
      });

      if (error) {
        console.error("Error confirming setup intent:", error.message);
        setErrorMessage(error.message || "An unexpected error occurred");
      }
    } catch (err: any) {
      console.error("Unexpected error:", err);
      setErrorMessage(err.message || "An unexpected error occurred");
    } finally {
      setLoading(false);
    }
  };

  return {
    onSubscribe,
    loading,
    errorMessage,
  };
};
