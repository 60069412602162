import AirPng from "app/assets/images/avatars/air.png";
import WaterPng from "app/assets/images/avatars/water.png";
import FirePng from "app/assets/images/avatars/fire.png";
import EarthPng from "app/assets/images/avatars/earth.png";
import { capitalize } from "lodash";
import { TFunction } from "i18next";

export enum ReadingModelElementTypes {
  AIR = "air",
  WATER = "water",
  FIRE = "fire",
  EARTH = "earth",
}

export enum ReadingModelKeys {
  WREN = "wren",
  ANITA = "anita",
  IZZY = "izzy",
  FLINT = "flint",
}

export const readingModelDictionary = (
  t: TFunction<"common", "readingModels">
) => {
  return {
    [ReadingModelElementTypes.AIR]: {
      key: ReadingModelKeys.WREN,
      name: capitalize(ReadingModelKeys.WREN),
      image: AirPng,
      trait: t("airTrait"),
      gender: "her",
    },
    [ReadingModelElementTypes.WATER]: {
      name: capitalize(ReadingModelKeys.ANITA),
      key: ReadingModelKeys.ANITA,
      image: WaterPng,
      trait: t("waterTrait"),
      gender: "her",
    },
    [ReadingModelElementTypes.FIRE]: {
      name: capitalize(ReadingModelKeys.IZZY),
      key: ReadingModelKeys.IZZY,
      image: FirePng,
      trait: t("fireTrait"),
      gender: "her",
    },
    [ReadingModelElementTypes.EARTH]: {
      name: capitalize(ReadingModelKeys.FLINT),
      key: ReadingModelKeys.FLINT,
      image: EarthPng,
      trait: t("earthTrait"),
      gender: "him",
    },
  } as any;
};
