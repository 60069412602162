import { find } from "lodash";
import * as React from "react";
import { Dialog } from "app/subframe/components/Dialog";
import { PrimaryButton } from "app/components/buttons/button";
import { useQuery } from "app/utils/useQuery";
import { useNavigate } from "react-router";
import { Icon } from "@subframe/core";
import { trialPlanDeals } from "app/dictionaries/webFunnelPlanDictionary";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

export interface PaymentStatusModalProps {
  type: "success" | "fail";
  planDeal: number;
  email: string;
  open: boolean;
  onOpenChange: (open: boolean) => void;
  setShowPaymentModal: (show: boolean) => void;
  createSetupIntent: () => void;
}

export const PaymentStatusModal: React.FC<PaymentStatusModalProps> = ({
  type,
  planDeal,
  email,
  open,
  onOpenChange,
  setShowPaymentModal,
  createSetupIntent,
}) => {
  const query = useQuery();
  const navigate = useNavigate();
  const plan = find(trialPlanDeals, { value: planDeal });
  const { t } = useTranslation("common", { keyPrefix: "quiz" });

  const handleTryAgain = async () => {
    query.delete("setup_intent");
    query.delete("setup_intent_client_secret");
    query.delete("redirect_status");
    navigate(window.location.pathname + "?" + query.toString());
    onOpenChange(false);
    await createSetupIntent();
    setShowPaymentModal(true);
  };

  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <Dialog.Content className="w-full max-w-md p-6 bg-white rounded-lg shadow-lg">
        {type === "success" ? (
          <>
            <div className="flex items-center space-x-3">
              <div className="w-10 h-10 flex items-center justify-center rounded-full bg-green-400">
                <Icon
                  name="FeatherCheck"
                  className="text-white stroke-white fill-white"
                />
              </div>
              <p className="text-lg font-semibold text-gray-800">
                {t("access_granted")}
              </p>
            </div>
            {plan && (
              <>
                <p className="mt-4 text-gray-600">{t("already_have_access")}</p>
                <p className="mt-4 text-gray-600">
                  {t("email_with_instructions", { email })}
                </p>
                <p className="mt-4 text-gray-600">
                  {t("check_spam_folder")}
                  <Link
                    to={"mailto:hello@support.morereadings.me"}
                    className="border-b text-black duration-300 border-black"
                  >
                    hello@support.morereadings.me
                  </Link>
                </p>
              </>
            )}
            <PrimaryButton
              className="mt-6 w-full px-4 py-2 text-white !rounded"
              onClick={() => onOpenChange(false)}
            >
              <p className="text-black font-semibold">{t("close")}</p>
            </PrimaryButton>
          </>
        ) : (
          <>
            <div className="flex items-center space-x-3">
              <div className="w-10 h-10 flex items-center justify-center rounded-full bg-red-100">
                <svg
                  className="w-6 h-6 text-red-600"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </div>
              <p className="text-lg font-semibold text-gray-800">
                {t("payment_failed")}
              </p>
            </div>
            <p className="mt-4 text-gray-600">{t("payment_failed_details")}</p>
            <PrimaryButton
              className="mt-6 w-full px-4 py-2 bg-red-600 text-white rounded-lg hover:bg-red-700 transition-colors"
              onClick={() => handleTryAgain()}
            >
              {t("try_again")}
            </PrimaryButton>
          </>
        )}
      </Dialog.Content>
    </Dialog>
  );
};
