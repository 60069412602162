import { Alignment, Fit, Layout, useRive } from "@rive-app/react-canvas";
import React from "react";
import LoadingEcruRive from "app/assets/rive/loadingEcruEye.riv";
import LoadingBlackRive from "app/assets/rive/loadingBlackEye.riv";

export enum LoadingColorType {
  LIGHT = "light",
  DARK = "dark",
}

export interface LoadingProps {
  fullScreen?: boolean;
  width?: number;
  height?: number;
  color?: LoadingColorType;
}

export const Loading: React.FC<LoadingProps> = ({
  fullScreen = false,
  width = 80,
  height = 80,
  color = LoadingColorType.LIGHT,
}) => {
  const { rive, RiveComponent } = useRive({
    // Load a local riv `clean_the_car.riv` or upload your own!
    src: color === LoadingColorType.DARK ? LoadingBlackRive : LoadingEcruRive,
    // Be sure to specify the correct state machine (or animation) name
    stateMachines: "play",
    // This is optional.Provides additional layout control.
    layout: new Layout({
      fit: Fit.Cover, // Change to: rive.Fit.Contain, or Cover
      alignment: Alignment.Center,
    }),
    autoplay: true,
  });

  return (
    <div
      className={`w-full flex flex-col justify-center items-center gap-2.5 ${
        fullScreen ? "fixed inset-0 bg-black" : ""
      }`}
    >
      <div
        className="flex justify-center items-center"
        style={{ width: width, height: height }}
      >
        <RiveComponent className={"w-[80px] h-[80px]"} />
      </div>
    </div>
  );
};
