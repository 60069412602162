const date = new Date();
const options: Intl.DateTimeFormatOptions = { month: "short" }; // Use 'short' for abbreviated month names

const monthNames = Array.from({ length: 12 }, (_, i) =>
  new Date(date.getFullYear(), i, 1).toLocaleString(undefined, options)
);

export const getFormatDate = (dateString: string) => {
  const date = new Date(dateString);
  const userTimezoneOffset = date.getTimezoneOffset() * 60000;
  const correctedDate = new Date(date.getTime() + userTimezoneOffset);

  const year = correctedDate.getFullYear();
  const month = (correctedDate.getMonth() + 1).toString().padStart(2, "0");
  const day = correctedDate.getDate().toString().padStart(2, "0");

  return `${year}-${month}-${day}`;
};

export const getFormatDate2 = (dateString: string) => {
  const date = new Date(dateString);

  const day = date.getDate().toString().padStart(2, "0");
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const year = date.getFullYear();

  return `${month}.${day}.${year}`;
};

export const getFormatDate3 = (dateString: string, locale: string) => {
  const date = new Date(dateString);

  const formattedDate = new Intl.DateTimeFormat(locale, {
    weekday: "short",
    day: "2-digit",
    month: "2-digit",
  }).format(date);

  return formattedDate.replace("/", ". ");
};

export const getFormattedBeginingOfMonthToNow = () => {
  const formatDate = (date: any) => {
    const options = { year: "numeric", month: "long", day: "numeric" };
    return date.toLocaleDateString(undefined, options);
  };

  const getFormattedDateRange = () => {
    const today = new Date();
    const firstDayOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);

    const formattedToday = formatDate(today);
    const formattedFirstDayOfMonth = formatDate(firstDayOfMonth);

    return `${formattedFirstDayOfMonth} to ${formattedToday}`;
  };

  return getFormattedDateRange();
};

export const getDaysBetweenTwoDates = (date1: any, date2: any) => {
  const startDate = new Date(date1) as any;
  const endDate = new Date(date2) as any;
  const timeDifference = endDate - startDate;
  const differenceInDays = Number(
    Math.ceil(timeDifference / -(1000 * 60 * 60 * 24)).toFixed(0)
  );
  return differenceInDays > 0 ? differenceInDays : 1;
};

export const getFormatTime = (dateString: string): string => {
  const date = new Date(dateString);
  let hours = date.getUTCHours();
  const minutes = date.getUTCMinutes();
  const ampm = hours >= 12 ? "pm" : "am";

  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  const minutesStr = minutes < 10 ? "0" + minutes : minutes;

  const formattedTime = `${hours}:${minutesStr} ${ampm}`;
  return formattedTime;
};

export const convertTo12HourFormat = (time: string): string => {
  // Split the time string into hours and minutes
  const [hours24, minutes] = time.split(":");

  // Parse the hours and minutes to numbers
  let hours = parseInt(hours24, 10);
  const suffix = hours >= 12 ? "pm" : "am";

  // Convert 24-hour time to 12-hour time
  hours = hours % 12 || 12; // Converts "0" to "12"

  // Return the formatted time string
  return `${hours}:${minutes} ${suffix}`;
};

export const getWeekDates = (inputDate: any) => {
  const date = new Date(inputDate.getTime()); // Create a new date object from the input to avoid modifying it
  const firstDayOfWeek = date.getDate() - date.getDay() + 1; // get Monday as the first day (assuming Sunday is 0)
  const weekDates = [];

  for (let i = 0; i < 7; i++) {
    let weekDate = new Date(
      date.getFullYear(),
      date.getMonth(),
      firstDayOfWeek + i
    );
    weekDates.push(weekDate.toDateString());
  }

  return weekDates;
};
