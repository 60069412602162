import http from "app/axios/axiosInstance";
import { debounce, set } from "lodash";
import { useCallback, useEffect, useState } from "react";

export const useBirthPlaceScreen = ({
  relationshipProfile,
  setRelationshipProfile,
}: {
  relationshipProfile?: any;
  setRelationshipProfile: (profile: any) => void;
}) => {
  const [birthPlaceInputText, setBirthPlaceInputText] = useState("" as string);
  const [birthPlace, setBirthPlace] = useState({});
  const [locationSuggestions, setLocationSuggestions] = useState([] as any[]);

  const onLocationInputChange = (search: string) => {
    setBirthPlaceInputText(search);
    debouncedSearchLocations(search);
  };

  const debouncedSearchLocations = useCallback(
    debounce((search: string) => {
      searchLocations(search);
    }, 300),
    [birthPlace]
  );

  const searchLocations = async (search: string) => {
    if (!search || search.length < 3) {
      setLocationSuggestions([]);
      setBirthPlace({});
      setRelationshipProfile({
        ...relationshipProfile,
        birthplaceName: null,
        birthplaceLatitude: null,
        birthplaceLongitude: null,
      });
      return;
    }
    try {
      const {
        data: { data },
      } = await http.post(`/v1/mapbox/search_location`, {
        query: search,
      });
      setLocationSuggestions(data);
    } catch (error: any) {
      return console.log(error);
    }
  };

  const onLocationSelect = ({ name, latitude, longitude }: any) => {
    setBirthPlaceInputText(name);

    setBirthPlace({ name, latitude, longitude });
    setRelationshipProfile({
      ...relationshipProfile,
      birthplaceName: name,
      birthplaceLatitude: latitude,
      birthplaceLongitude: longitude,
    });
    setLocationSuggestions([]);
  };

  return {
    locationSuggestions,
    setLocationSuggestions,
    birthPlace,
    onLocationSelect,
    birthPlaceInputText,
    onLocationInputChange,
  };
};
