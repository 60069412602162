import AriesPng from "app/assets/horoscopeSign/lunarAries.png";
import AriesMp4 from "app/assets/horoscopeSign/lunarAries.mp4";
import TaurusPng from "app/assets/horoscopeSign/lunarTaurus.png";
import TaurusMp4 from "app/assets/horoscopeSign/lunarTaurus.mp4";
import GeminiPng from "app/assets/horoscopeSign/lunarGemini.png";
import GeminiMp4 from "app/assets/horoscopeSign/lunarGemini.mp4";
import CancerPng from "app/assets/horoscopeSign/lunarCancer.png";
import CancerMp4 from "app/assets/horoscopeSign/lunarCancer.mp4";
import LeoPng from "app/assets/horoscopeSign/lunarLeo.png";
import LeoMp4 from "app/assets/horoscopeSign/lunarLeo.mp4";
import VirgoPng from "app/assets/horoscopeSign/lunarVirgo.png";
import VirgoMp4 from "app/assets/horoscopeSign/lunarVirgo.mp4";
import LibraPng from "app/assets/horoscopeSign/lunarLibra.png";
import LibraMp4 from "app/assets/horoscopeSign/lunarLibra.mp4";
import ScorpioPng from "app/assets/horoscopeSign/lunarScorpio.png";
import ScorpioMp4 from "app/assets/horoscopeSign/lunarScorpio.mp4";
import SagittariusPng from "app/assets/horoscopeSign/lunarSagittarius.png";
import SagittariusMp4 from "app/assets/horoscopeSign/lunarSagittarius.mp4";
import CapricornPng from "app/assets/horoscopeSign/lunarCapricorn.png";
import CapricornMp4 from "app/assets/horoscopeSign/lunarCapricorn.mp4";
import AquariusPng from "app/assets/horoscopeSign/lunarAquarius.png";
import AquariusMp4 from "app/assets/horoscopeSign/lunarAquarius.mp4";
import PiscesPng from "app/assets/horoscopeSign/lunarPisces.png";
import PiscesMp4 from "app/assets/horoscopeSign/lunarPisces.mp4";
import { TFunction } from "i18next";

export const enum HoroscopeSignDictionaryEnum {
  ARIES = "aries",
  TAURUS = "taurus",
  GEMINI = "gemini",
  CANCER = "cancer",
  LEO = "leo",
  VIRGO = "virgo",
  LIBRA = "libra",
  SCORPIO = "scorpio",
  SAGITTARIUS = "sagittarius",
  CAPRICORN = "capricorn",
  AQUARIUS = "aquarius",
  PISCES = "pisces",
}

export const horoscopeSignDictionary = (t: TFunction) => ({
  [HoroscopeSignDictionaryEnum.ARIES]: {
    name: t("horoscope.aries.name"),
    image: AriesPng,
    video: AriesMp4,
    description: t("horoscope.aries.description"),
    moon: t("moon_rising_venus.moon.aries_moon"),
    ascendant: t("moon_rising_venus.rising.aries_rising"),
    venus: t("moon_rising_venus.venus.aries_venus"),
  },
  [HoroscopeSignDictionaryEnum.TAURUS]: {
    name: t("horoscope.taurus.name"),
    image: TaurusPng,
    video: TaurusMp4,
    description: t("horoscope.taurus.description"),
    moon: t("moon_rising_venus.moon.taurus_moon"),
    ascendant: t("moon_rising_venus.rising.taurus_rising"),
    venus: t("moon_rising_venus.venus.taurus_venus"),
  },
  [HoroscopeSignDictionaryEnum.GEMINI]: {
    name: t("horoscope.gemini.name"),
    image: GeminiPng,
    video: GeminiMp4,
    description: t("horoscope.gemini.description"),
    moon: t("moon_rising_venus.moon.gemini_moon"),
    ascendant: t("moon_rising_venus.rising.gemini_rising"),
    venus: t("moon_rising_venus.venus.gemini_venus"),
  },
  [HoroscopeSignDictionaryEnum.CANCER]: {
    name: t("horoscope.cancer.name"),
    image: CancerPng,
    video: CancerMp4,
    description: t("horoscope.cancer.description"),
    moon: t("moon_rising_venus.moon.cancer_moon"),
    ascendant: t("moon_rising_venus.rising.cancer_rising"),
    venus: t("moon_rising_venus.venus.cancer_venus"),
  },
  [HoroscopeSignDictionaryEnum.LEO]: {
    name: t("horoscope.leo.name"),
    image: LeoPng,
    video: LeoMp4,
    description: t("horoscope.leo.description"),
    moon: t("moon_rising_venus.moon.leo_moon"),
    ascendant: t("moon_rising_venus.rising.leo_rising"),
    venus: t("moon_rising_venus.venus.leo_venus"),
  },
  [HoroscopeSignDictionaryEnum.VIRGO]: {
    name: t("horoscope.virgo.name"),
    image: VirgoPng,
    video: VirgoMp4,
    description: t("horoscope.virgo.description"),
    moon: t("moon_rising_venus.moon.virgo_moon"),
    ascendant: t("moon_rising_venus.rising.virgo_rising"),
    venus: t("moon_rising_venus.venus.virgo_venus"),
  },
  [HoroscopeSignDictionaryEnum.LIBRA]: {
    name: t("horoscope.libra.name"),
    image: LibraPng,
    video: LibraMp4,
    description: t("horoscope.libra.description"),
    moon: t("moon_rising_venus.moon.libra_moon"),
    ascendant: t("moon_rising_venus.rising.libra_rising"),
    venus: t("moon_rising_venus.venus.libra_venus"),
  },
  [HoroscopeSignDictionaryEnum.SCORPIO]: {
    name: t("horoscope.scorpio.name"),
    image: ScorpioPng,
    video: ScorpioMp4,
    description: t("horoscope.scorpio.description"),
    moon: t("moon_rising_venus.moon.scorpio_moon"),
    ascendant: t("moon_rising_venus.rising.scorpio_rising"),
    venus: t("moon_rising_venus.venus.scorpio_venus"),
  },
  [HoroscopeSignDictionaryEnum.SAGITTARIUS]: {
    name: t("horoscope.sagittarius.name"),
    image: SagittariusPng,
    video: SagittariusMp4,
    description: t("horoscope.sagittarius.description"),
    moon: t("moon_rising_venus.moon.sagittarius_moon"),
    ascendant: t("moon_rising_venus.rising.sagittarius_rising"),
    venus: t("moon_rising_venus.venus.sagittarius_venus"),
  },
  [HoroscopeSignDictionaryEnum.CAPRICORN]: {
    name: t("horoscope.capricorn.name"),
    image: CapricornPng,
    video: CapricornMp4,
    description: t("horoscope.capricorn.description"),
    moon: t("moon_rising_venus.moon.capricorn_moon"),
    ascendant: t("moon_rising_venus.rising.capricorn_rising"),
    venus: t("moon_rising_venus.venus.capricorn_venus"),
  },
  [HoroscopeSignDictionaryEnum.AQUARIUS]: {
    name: t("horoscope.aquarius.name"),
    image: AquariusPng,
    video: AquariusMp4,
    description: t("horoscope.aquarius.description"),
    moon: t("moon_rising_venus.moon.aquarius_moon"),
    ascendant: t("moon_rising_venus.rising.aquarius_rising"),
    venus: t("moon_rising_venus.venus.aquarius_venus"),
  },
  [HoroscopeSignDictionaryEnum.PISCES]: {
    name: t("horoscope.pisces.name"),
    image: PiscesPng,
    video: PiscesMp4,
    description: t("horoscope.pisces.description"),
    moon: t("moon_rising_venus.moon.pisces_moon"),
    ascendant: t("moon_rising_venus.rising.pisces_rising"),
    venus: t("moon_rising_venus.venus.pisces_venus"),
  },
});
