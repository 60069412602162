import {
  RelationshipGoalTypes,
  RelationshipStatusTypes,
} from "app/quiz/useQuiz";
import { useQuery } from "app/utils/useQuery";
import * as React from "react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router";

export const useQuizlet1 = () => {
  const query = useQuery();
  const navigate = useNavigate();
  const [currentScreen, setCurrentScreen] = useState(1);
  const [gender, setGender] = useState<string | null>(null);
  const [relationshipStatus, setRelationshipStatus] =
    useState<RelationshipStatusTypes | null>(null);
  const [relationshipGoal, setRelationshipGoal] =
    useState<RelationshipGoalTypes | null>(null);
  const [screenToShow, setScreenToShow] = useState<number>(1);

  useEffect(() => {
    setTimeout(() => {
      setScreenToShow(currentScreen);
    }, 300);
  }, [currentScreen]);

  useEffect(() => {
    if (query.get("complete")) {
      query.delete("complete");
      navigate(`/quiz/quizlet1?${query.toString()}`);
    }
  }, []);

  return {
    currentScreen,
    gender,
    setGender,
    setCurrentScreen,
    relationshipStatus,
    setRelationshipStatus,
    relationshipGoal,
    setRelationshipGoal,
    screenToShow,
    setScreenToShow,
    query,
  };
};
