import React from "react";
import { Card, CardBody, Button, CardHeader } from "@nextui-org/react";
import { ChatRole, useChat } from "./useChat";
import classnames from "classnames";
import { Alignment, Fit, Layout, useRive } from "@rive-app/react-canvas";
import styles from "./chat.module.css";
import { TypingBubble } from "./components/typingBubble/typingBubble";
import ChatMessage from "./components/chatMessage/chatMessage";
import { TextInput } from "app/components/chat/components/textInput/textInput";
import { useBreakpoint } from "app/utils/useBreakpoint";
import StarBgRive from "app/assets/rive/starBg.riv";
import {
  readingModelDictionary,
  ReadingModelElementTypes,
} from "app/dictionaries/readingModelsDictionary";
import { BorderedAvatar } from "../avatars/avatars";
import { useTranslation } from "react-i18next";
import { FadeInSlide } from "app/motions/fadeInSlide";
import { localStorageEnums } from "app/dictionaries/localStorageDictionary";

export interface ChatProps {
  showPaywall?: boolean;
  setShowPaywall?: (show: boolean) => void;
}

export const Chat: React.FC<ChatProps> = ({ showPaywall, setShowPaywall }) => {
  const {
    sendMessage,
    handleKeyPress,
    chatInput,
    chatLog,
    isChatFocused,
    setIsChatFocused,
    isResponseLoading,
    onChatInputChange,
    scrollbarRef,
  } = useChat({ setShowPaywall });
  const { isAboveLg } = useBreakpoint("lg");
  const { t } = useTranslation();

  const { rive, RiveComponent } = useRive({
    // Load a local riv `clean_the_car.riv` or upload your own!
    src: StarBgRive,
    // Be sure to specify the correct state machine (or animation) name
    stateMachines: "play",
    // This is optional.Provides additional layout control.
    layout: new Layout({
      fit: Fit.Cover, // Change to: rive.Fit.Contain, or Cover
      alignment: Alignment.Center,
    }),
    autoplay: true,
  });

  const avatar =
    readingModelDictionary(t)[localStorage.getItem(localStorageEnums.ELEMENT)!];

  return (
    <div className="flex relative w-full flex-col overflow-y-hidden h-[100dvh] md:p-4 md:pb-0 md:h-screen">
      <main
        className={classnames(
          "flex z-10 flex-col gap-5 max-w-5xl justify-center w-full h-full overflow-hidden m-auto lg:flex-row items-center my-0",
          {
            "px-5": isAboveLg,
            "blur-lg": showPaywall,
          }
        )}
      >
        <Card
          shadow="none"
          className="w-full relative rounded-none bg-transparent max-w-2xl h-[100dvh] overflow-hidden"
        >
          <RiveComponent className="absolute h-[100dvh] w-full" />
          <CardHeader className="flex items-center p-4 justify-center">
            <FadeInSlide direction="bottom" key="avatar">
              <BorderedAvatar src={avatar?.image} size="md" />
            </FadeInSlide>
          </CardHeader>
          <div ref={scrollbarRef} className="overflow-y-auto h-full">
            <div className={styles.chatTopFade} />
            <CardBody
              id="chatContainer"
              className="flex flex-col gap-4 border-none pt-2 pb-4 px-[6px]"
            >
              {chatLog
                .filter((chat: any) => chat.role !== ChatRole.SYSTEM)
                .map((chat: any, i: number) => {
                  const name = chat.role;
                  const isUser = name === ChatRole.USER;

                  const message = chat.content;

                  return (
                    // <div key={i}>
                    <FadeInSlide direction="bottom" key={i.toString()}>
                      <ChatMessage
                        isUser={isUser}
                        message={message}
                        chat={chat}
                      />
                    </FadeInSlide>
                  );
                })}
              {isResponseLoading && (
                <div className="ml-[10px]">
                  <FadeInSlide direction="top" key="typingBubble">
                    <TypingBubble />
                  </FadeInSlide>
                </div>
              )}
            </CardBody>
          </div>
          <div
            className={classnames(
              "flex gap-[10px] items-center relative bottom-0 z-10 bg-black pb-4 px-[10px] pt-2 md:pb-6",
              {
                [styles.chatContainerFocus]: isChatFocused,
              }
            )}
          >
            <TextInput
              onTextInputChange={onChatInputChange}
              handleKeyPress={handleKeyPress}
              onSubmit={sendMessage}
              textInput={chatInput}
              isChatFocused={isChatFocused}
              setIsChatFocused={setIsChatFocused}
              showSubmitButton={true}
              placeholder={t("ask_away")}
              name="message"
              leftPadding={true}
              // show={shouldShowTextInput}
            />
          </div>
        </Card>
      </main>
    </div>
  );
};
