import classNames from "classnames";
import React, { useEffect, useRef } from "react";

class Firefly {
  x: number;
  y: number;
  s: number;
  ang: number;
  v: number;
  color: string;
  shadowColor: string;

  constructor(
    w: number,
    h: number,
    color: string = "#242424",
    shadowColor: string = "white"
  ) {
    this.x = Math.random() * w;
    this.y = Math.random() * h;
    this.s = Math.random() * 2;
    this.ang = Math.random() * 2 * Math.PI;
    this.v = (this.s * this.s) / 4;
    this.color = color;
    this.shadowColor = shadowColor;
  }

  move() {
    this.x += this.v * Math.cos(this.ang) * 0.5;
    this.y += this.v * Math.sin(this.ang) * 0.5;
    this.ang += (Math.random() * 20 * Math.PI) / 180 - (10 * Math.PI) / 180;
  }

  show(c: CanvasRenderingContext2D) {
    c.beginPath();
    c.arc(this.x, this.y, this.s, 0, 2 * Math.PI);
    c.fillStyle = this.color; // Updated fill color
    c.shadowBlur = 10;
    c.shadowColor = this.shadowColor; //
    c.fill();
  }
}

interface FirefliesProps {
  color?: string;
  shadowColor?: string;
  negativeZIndex?: boolean;
}

export const Fireflies: React.FC<FirefliesProps> = ({
  color,
  shadowColor,
  negativeZIndex = true,
}) => {
  const canvasRef = useRef<HTMLCanvasElement>(null);

  useEffect(() => {
    const canvas = canvasRef.current;
    const c = canvas?.getContext("2d");
    const fireflies: Firefly[] = [];
    let animationFrameId: number;

    const resizeCanvas = () => {
      if (canvas) {
        canvas.width = window.innerWidth;
        canvas.height = window.innerHeight * 0.75;
      }
    };

    window.addEventListener("resize", resizeCanvas);
    resizeCanvas();

    const draw = () => {
      if (c && canvas) {
        c.clearRect(0, 0, canvas.width, canvas.height);
        if (fireflies.length < 75) {
          for (let j = 0; j < 10; j++) {
            fireflies.push(new Firefly(canvas.width, canvas.height, color));
          }
        }
        fireflies.forEach((firefly, index) => {
          firefly.move();
          firefly.show(c);
          if (
            firefly.x < 0 ||
            firefly.x > canvas.width ||
            firefly.y < 0 ||
            firefly.y > canvas.height
          ) {
            fireflies.splice(index, 1);
          }
        });
      }
    };

    const animate = () => {
      draw();
      animationFrameId = window.requestAnimationFrame(animate);
    };

    animate();

    return () => {
      window.cancelAnimationFrame(animationFrameId);
      window.removeEventListener("resize", resizeCanvas);
    };
  }, []);

  return (
    <canvas
      ref={canvasRef}
      id="canvas"
      className={classNames("absolute bottom-0 left-0 w-full", {
        "z-[-10]": negativeZIndex,
      })}
    />
  );
};
