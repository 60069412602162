import * as React from "react";
import HandPng from "app/assets/images/hand.png";
import { FadeInSlide } from "app/motions/fadeInSlide";
import { PrimaryButton } from "app/components/buttons/button";
import { useTranslation } from "react-i18next";

export interface InitialProps {
  onClaimDiscount: () => void;
}

export const Initial: React.FC<InitialProps> = ({ onClaimDiscount }) => {
  const { t } = useTranslation();
  return (
    <FadeInSlide direction="top" key="discount">
      <div className="flex max-w-[380px] flex-col justify-center mobile:max-w-[313px] mx-auto h-[100dvh]">
        <div className="flex flex-col gap-10 mx-auto mb-14">
          <h1 className="text-[#DBD2BE] text-[40px] mx-auto">
            {t("quiz.save_70_off") + "!"}
          </h1>
          <img src={HandPng} alt="hand" className="mx-auto w-[70%] h-[70%]" />
          <p className="text-2xl mobile:text-lg">
            {t("quiz.personalized_plan")}
          </p>
          <p className="text-2xl mobile:text-lg">🎁 {t("quiz.7_day_trial")}</p>
        </div>
        <PrimaryButton
          className="w-full !p-6"
          onClick={() => onClaimDiscount()}
        >
          <p className="font-public text-black text-2xl font-semibold">
            {" "}
            {t("quiz.get_discount")}
          </p>
        </PrimaryButton>
      </div>
    </FadeInSlide>
  );
};
