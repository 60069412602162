export const getParamsAsJson = (query: any) => {
  const paramsObject: { [key: string]: string | number } = {};

  // Iterate over the query parameters and add them to the paramsObject
  query.forEach((value: string, key: string) => {
    // Convert numeric values to numbers; otherwise keep them as strings
    if (
      ![
        "setup_intent",
        "setup_intent_client_secret",
        "redirect_status",
      ].includes(key)
    ) {
      paramsObject[key] = isNaN(Number(value)) ? value : Number(value);
    }
  });

  return paramsObject;
};
