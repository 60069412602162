import * as React from "react";
import sharedStyles from "app/components/buttons/button.module.css";
import { Link } from "react-router-dom";
import classNames from "classnames";
import GooglePlaySvg from "app/assets/images/googlePlay.svg";
import { usePostHog } from "posthog-js/react";

export interface PlaystoreBtnProps {}

export const PlaystoreBtn: React.FC<PlaystoreBtnProps> = (props) => {
  const {} = props;
  const posthog = usePostHog();

  const handleClick = () => {
    posthog.capture("PlayStore Button Clicked", {
      platform: "web",
      destination: "https://apps.apple.com/app/id6479682201?platform=iphone",
    });
  };

  return (
    <Link
      to="https://apps.apple.com/app/id6479682201?platform=iphone"
      className={classNames(
        sharedStyles.sheenFadeShadow,
        "duration-300 hover:scale-105 w-fit"
      )}
      target="_blank"
      onClick={handleClick}
    >
      <img src={GooglePlaySvg} alt="PlayStore" />
    </Link>
  );
};
