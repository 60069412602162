import * as React from "react";
import { useEffect } from "react";
import { useParams } from "react-router";

export enum RelationshipStatusTypes {
  SINGLE = "single",
  IN_A_RELATIONSHIP = "in_a_relationship",
  MARRIED = "married",
  COMPLICATED = "complicated",
  UNSURE_OTHER = "unsure/other",
}

export enum RelationshipGoalTypes {
  IMPROVE_RELATIONSHIP = "improve_relationship",
  FIX_RELATIONSHIP = "fix_relationships",
  BUILD_A_STRONG_MARRIAGE = "build_a_strong_marriage",
  CHECK_COMPATIBILITY = "check_compatibility",
  ALL_OF_THE_ABOVE = "all_of_the_above",
}

export enum QuizTypes {
  QUIZLET_1 = "quizlet-1",
  QUIZ2 = "quiz2",
  QUIZ2V1 = "quiz2v1",
  QUIZ2V2 = "quiz2v2",
}

export const useQuiz = () => {
  const { type = QuizTypes.QUIZ2 }: { type?: QuizTypes } = useParams();

  return {
    type,
  };
};
