import classNames from "classnames";
import * as React from "react";
import { TitleDescription } from "app/quiz/components/titleDescription";
import { SecondaryButton3 } from "app/components/buttons/button";
import { useBreakpoint } from "app/utils/useBreakpoint";
import { RelationshipStatusTypes } from "app/quiz/useQuiz";

export interface Screen2Props {
  relationshipStatus: RelationshipStatusTypes | null;
  setRelationshipStatus: (relationshipStatus: RelationshipStatusTypes) => void;
  setCurrentScreen: (screen: number) => void;
}

const relationshipStatusOptions = [
  {
    title: (
      <p>
        <span role="img" aria-label="heart" className="mr-2">
          ❤️
        </span>{" "}
        Single
      </p>
    ),
    value: RelationshipStatusTypes.SINGLE,
  },
  {
    title: (
      <p>
        <span role="img" aria-label="heart" className="mr-2">
          💕
        </span>{" "}
        In a relationship
      </p>
    ),
    value: RelationshipStatusTypes.IN_A_RELATIONSHIP,
  },
  {
    title: (
      <p>
        <span role="img" aria-label="ring" className="mr-2">
          💍
        </span>{" "}
        Married
      </p>
    ),
    value: RelationshipStatusTypes.MARRIED,
  },
  {
    title: (
      <p>
        <span role="img" aria-label="broken heart" className="mr-2">
          💔
        </span>{" "}
        Complicated
      </p>
    ),
    value: RelationshipStatusTypes.COMPLICATED,
  },
  {
    title: (
      <p>
        <span role="img" aria-label="thinking face" className="mr-2">
          🤔
        </span>{" "}
        Unsure/Other
      </p>
    ),
    value: RelationshipStatusTypes.UNSURE_OTHER,
  },
];

export const Screen2: React.FC<Screen2Props> = ({
  relationshipStatus,
  setRelationshipStatus,
  setCurrentScreen,
}) => {
  const onOptionClick = (value: RelationshipStatusTypes) => {
    setRelationshipStatus(value);
    setCurrentScreen(3);
  };

  const { isBelowMd, isAboveMd } = useBreakpoint("md");

  return (
    <div className="flex flex-col items-center">
      <div
        className={classNames({
          "mb-[24%]": isAboveMd,
        })}
      >
        <TitleDescription
          title="Personal assessment quizlet"
          description="What is your relationship status?"
        />
      </div>
      <div className="flex flex-col gap-10 w-full items-center max-w-[313px]">
        {relationshipStatusOptions.map(({ title, value }) => (
          <SecondaryButton3
            key={value}
            className={classNames(
              "w-full !px-10 !py-[13px] flex justify-start"
            )}
            onClick={() => onOptionClick(value)}
          >
            {title}
          </SecondaryButton3>
        ))}
      </div>
    </div>
  );
};
