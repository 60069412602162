import * as React from "react";
import styles from "app/legal/legal.module.css";

export interface PolicyProps {}

export const Policy: React.FC<PolicyProps> = ({}) => {
  return (
    <div className={styles.container}>
      <h1 className={styles.h1}>Privacy Policy</h1>
      <p className={styles.p}>LAST UPDATED: APR 4TH, 2024</p>
      <p className={styles.p}>
        Tacit Social LLC is committed to protecting the personal information you
        share with us. This Privacy Policy explains how we collect, store, use,
        and disclose information. This Privacy Policy applies to our mobile
        application (“App”), our website [insert Tacit Social LLC's website URL]
        (“Website”), and our corporate activities, collectively referred to as
        our services (“Services”). By using our Services or providing
        information to us, you consent to us collecting, storing, using, and
        disclosing information as described in this Privacy Policy.
      </p>
      <h2 className={styles.h2}>Contact Us</h2>
      <p className={styles.p}>
        If you have any questions regarding this Privacy Policy, please contact
        us at hello@support.morereadings.me.
      </p>
      <h2 className={styles.h2}>Your acceptance of this privacy policy</h2>
      <p className={styles.p}>
        The provisions contained in this Privacy Policy supersede all previous
        notices and statements regarding our privacy practices with respect to
        the Services. If you do not agree to all provisions of this Privacy
        Policy, you may not access or use the Services. We may update or modify
        this Privacy Policy from time to time. If we make any material changes
        to this Privacy Policy or the way we use, share or collect Personal
        Information, we will notify you by revising the “Effective Date” at the
        top of this Privacy Policy, prominently posting an announcement of the
        changes on our Services, or by sending you a written communication using
        the contact information you provided us prior to the new policy taking
        effect. Users are bound by any changes to the Privacy Policy if they use
        our Services after notice of such changes has been communicated. We
        encourage you to review this Privacy Policy regularly to ensure that you
        are familiar with Tacit Social LLC’s current practices.
      </p>

      <p className={styles.p}>
        Further, you acknowledge that this Privacy Policy is part of our Terms
        of Service at{" "}
        <a href="morereadings.com/terms">morereadings.com/terms</a> and is
        incorporated therein by reference. By accessing or using our Services,
        you agree to be bound by the Terms of Service. If you do not agree to
        our Terms of Service, do not access or use our Service.
      </p>

      <h2 className={styles.h2}>Information we get from you</h2>
      <p className={styles.p}>
        When you use our Services, we collect information that you provide to
        us. Additionally, certain information is automatically collected from
        you. Some of this information may identify you (“Personal Information”),
        and some of this information may not identify you (“Anonymous
        Information”). Sometimes, Anonymous Information may directly or
        indirectly be associated or combined with Personal information. In these
        cases, such Anonymous Information will be considered Personal
        Information for purposes of this Privacy Policy. In addition, to the
        extent that Internet Protocol (“IP”) addresses or similar identifiers or
        information are considered personally identifiable information by
        applicable law, we will treat those items as Personal Information under
        this Privacy Policy.
      </p>

      <h2 className={styles.h2}>INFORMATION YOU PROVIDE TO US</h2>

      <p className={styles.p}>
        Information you may provide includes your name, email, mobile number,
        Facebook or Apple username, birth date, place of birth, and time of
        birth, and login or account name. If you apply for a position with us,
        you may provide us information about your work history, work
        authorization status, education, skills, professional licenses, permits
        or certifications, and references, as well as information in any
        documents you may choose to provide, such as a CV, resume or cover
        letter, and any other information you choose to provide to us. If you
        purchase our Services or products, you may provide your credit or debit
        card information. Tacit Social LLC. uses third parties to process
        payments – we do not collect or store credit card information. If you
        provide your phone number, we send a few kinds of transactional text
        messages directly relating to your use of your Services, like confirming
        your account and resetting your password. By providing your mobile phone
        number, you expressly consent to receive this type of transactional text
        messages from us at that number. Third-party data and message fees may
        apply.
      </p>

      <h2 className={styles.h2}>
        INFORMATION COLLECTED AUTOMATICALLY FROM DEVICES
      </h2>

      <p className={styles.p}>
        When you access our Services, we may automatically collect information
        that is linked to your computer, phone, or other device, including
        information related to your behavior and activity while interacting with
        us, such as your IP address, operating system and version, local time
        zone, date, time, and activity of your request, device type (e.g.
        desktop, laptop, tablet, phone, etc.), device manufacturer, screen size,
        and language preference.
      </p>

      <p className={styles.p}>
        Consistent with most websites, we use cookies and pixel tags. Cookies
        are small text files used to collect information and pixels are
        transparent images that allow us to understand how users interact with
        our Websites. The Websites use necessary cookies and analytics cookies.
      </p>

      <h2 className={styles.h2}>DO NOT TRACK</h2>

      <p className={styles.p}>
        Do Not Track (“DNT”) is an optional browser setting that allows you to
        express your preferences regarding tracking by advertisers and other
        third parties. We employ technology to recognize and respect DNT
        preferences that you set in your web browser.
      </p>

      <h2 className={styles.h2}>How we use information</h2>

      <p className={styles.p}>
        In general, we use the information we collect to provide you with the
        best possible Services, and continually improve them over time.
      </p>

      <p className={styles.p}>
        Specifically, we may use Anonymous Information as well as Personal
        Information as described elsewhere in this Privacy Policy to improve our
        Services, to ensure our Services work properly, and for other research
        and commercial purposes. These purposes include, among other things, to:
      </p>

      <ul className={styles.ul}>
        <li className={styles.li}>
          To provide the Services and related notices;
        </li>
        <li className={styles.li}>
          To create user accounts and/or profiles through registration and
          import user contacts;
        </li>
        <li className={styles.li}>
          To respond to your inquiries and/or requests about our Services and
          products;
        </li>
        <li className={styles.li}>
          To administer, maintain, analyze, improve, and customize the Services
          and create a better experience for users in our Services;
        </li>
        <li className={styles.li}>
          To provide or send information about our Services;
        </li>
        <li className={styles.li}>
          To send transactional text messages directly relating to your use of
          your Services (e.g., confirming your account and resetting your
          password);
        </li>
        <li className={styles.li}>
          To receive feedback and provide customer and technical support for the
          Services;
        </li>
        <li className={styles.li}>To conduct surveys, research, and audits;</li>
        <li className={styles.li}>
          To fulfill any orders placed through the Websites (including
          processing your payment information, arranging for shipping, and
          providing you with invoices and/or order confirmations);
        </li>
        <li className={styles.li}>
          To process your employment application, including assessing your job
          qualifications, conducting reference checks, and conducting background
          checks if we offer you a position, as permitted by applicable law.
        </li>
        <li className={styles.li}>
          To provide information to our representatives and/or advisors, such as
          our attorneys, accountants, and others to help us comply with legal,
          accounting, or security requirements;
        </li>
        <li className={styles.li}>
          To verify and respond to your requests regarding your personal
          information or questions you may have about this Privacy Policy.
        </li>
        <li className={styles.li}>
          To detect, prevent, and investigate actual or suspected fraud,
          hacking, infringement, or other misconduct involving the Services; and
          for other purposes disclosed at the time of collection.
        </li>
      </ul>

      <p className={styles.p}>
        Additionally, we may use your personal information if we think we need
        to or are permitted to for legal purposes, to protect our business, our
        rights, or our property, or to address activity that may be illegal,
        unethical, or legally actionable.
      </p>

      <h2 className={styles.h2}>How we share information</h2>

      <p className={styles.p}>
        Tacit Social LLC. does not sell your personal information to third
        parties, nor do we disclose personal information to third parties for
        direct marketing purposes.
      </p>

      <p className={styles.p}>
        We may share Anonymous Information with third parties as described
        elsewhere in this Privacy Policy and to improve our Services and for
        other commercial purposes.
      </p>

      <p className={styles.p}>
        We take your privacy seriously, and may disclose Personal Information
        only as follows:
      </p>

      <ul className={styles.ul}>
        <li className={styles.li}>
          <span className="font-bold">To Authorized Business Partners.</span>{" "}
          Authorized Business Partners are third parties whose services Tacit
          Social LLC. uses to operate and improve our own Services. These third
          parties include agents, contractors, vendors, system integrators, etc.
          Tacit Social LLC., our affiliates, and our business partners may share
          this Personal Information with each other and use it consistent with
          this Privacy Policy. They may also combine it with other information
          to provide and improve our Services.
        </li>
        <li className={styles.li}>
          <span className="font-bold">To customer support employees.</span>{" "}
          Authorized customer support employees may access to your Personal
          Information as needed to resolve issues you encounter on our Services
          and help you use our Services.
        </li>
        <li className={styles.li}>
          We may share information about you for legal, safety, and security
          reasons. We may share information about you if we reasonably believe
          that disclosing the information is needed to:
          <li className={styles.li}>
            Comply with any valid legal process, governmental request, or
            applicable law, rule, or regulation.
          </li>
          <li className={styles.li}>
            Investigate, remedy, or enforce potential Terms of Service
            violations.
          </li>
          <li className={styles.li}>
            Protect the rights, property, or safety of us, our users, or others.
          </li>
          <li className={styles.li}>
            Detect and resolve any fraud or security concerns.
          </li>
        </li>
        <li className={styles.li}>
          We may share information about you as part of a merger or acquisition.
          If Tacit Social LLC. gets involved in a merger, asset sale, financing,
          liquidation or bankruptcy, or acquisition of all or some portion of
          our business to another company, we may share your information with
          that company before and after the transaction closes.
        </li>
      </ul>

      <h2 className={styles.h2}>WE DO NOT SELL PERSONAL INFORMATION</h2>

      <p className={styles.p}>
        We have never sold Personal Information, and we will not sell your
        Personal Information to third parties for their use without your
        consent.
      </p>

      <h2 className={styles.h2}>Protecting Children's Privacy</h2>

      <p className={styles.p}>
        CHILDREN ONLINE PROTECTION
        <br />
        Our Services are not directed to children under the age of thirteen
        (13), and we do not knowingly collect personal information from children
        under the age of thirteen (13). If you are not thirteen (13) years or
        older, do not use our Website, Apps, or Services. If you believe that we
        have received the information of a child, please contact us at
        hello@support.morereadings.me
      </p>

      <p className={styles.p}>
        ERASURE LAW
        <br />
        In accordance with California law, minors under the age of eighteen (18)
        residing in California may remove, or request and obtain the removal of,
        content and information that they share on the Websites or App. To
        remove or to request and obtain the removal of such content and
        information, the user must email hello@support.morereadings.me. Users
        should be aware that removing content and information posted by a minor
        does not ensure complete or comprehensive erasure of content or
        information posted on our Services.
      </p>

      <p className={styles.p}>
        Under certain circumstances, Tacit Social LLC. may not have to comply
        with the above removal requirements. Tacit Social LLC. also reserves the
        right, to the extent permitted by law, to anonymize the posted content
        or information or to make such content or information invisible to other
        users and the public, instead of removing or deleting the content or
        information.
      </p>

      <h2 className={styles.h2}>Links to third-party websites</h2>

      <p className={styles.p}>
        When you use our Services, you may be directed to other websites and
        applications that are beyond our control. We may also allow third-party
        websites or applications to link to the Services. We are not responsible
        for the privacy practices of any third parties or the content of linked
        websites and applications, but we do encourage you to read the
        applicable privacy policies and terms and conditions of such parties,
        websites, and applications. This Privacy Policy only applies to the
        Services.
      </p>

      <h2 className={styles.h2}>Social Media Features</h2>

      <p className={styles.p}>
        INFORMATION YOU SHARE SOCIALLY
        <br />
        Keep in mind that the users who view any content you share (e.g. your
        profile or your DMs) can always save that content or copy it outside the
        app. So, the same common sense that applies to the internet at large
        applies to Tacit Social LLC. as well: Don’t share or make public any
        content that you wouldn’t want someone else to save or share.
      </p>

      <h2 className={styles.h2}>
        How we secure, store, and retain information
      </h2>

      <p className={styles.p}>
        SECURITY
        <br />
        We have implemented reasonable technical and organizational precautions
        to protect the confidentiality, security and integrity of your personal
        information from accidental or unlawful destruction, loss, alteration,
        disclosure, or unauthorized access. We cannot guarantee the security of
        information transmitted to us over the internet.
      </p>

      <p className={styles.p}>
        STORAGE
        <br />
        The Personal Information that you provide to us is generally stored on
        servers maintained by Heroku. These servers are located in the United
        States. If you are located in another jurisdiction, you should be aware
        that once your Personal Information is submitted through our Services,
        it will be transferred to our servers in the United States and therefore
        governed by local data protection laws, which can vary state by state.
      </p>

      <p className={styles.p}>
        RETENTION
        <br />
        We will retain your personal information for the period necessary to
        fulfill the purposes outlined in this Privacy Policy unless a longer
        retention period is required or permitted by law.
      </p>

      <h2 className={styles.h2}>International users</h2>

      <p className={styles.p}>
        Tacit Social LLC is headquartered in the Canada, and we store personal
        information on servers located in the United States, which may not have
        the same data protection laws as the country in which you reside. If you
        are using our Services from outside the United States or Canada, your
        information may be transferred to, stored, and processed in the United
        States and Canada. By using our Services, you consent to such transfers
        of this information.
      </p>

      <p className={styles.p}>
        We may act as a “controller” as defined under applicable data protection
        law when we process your information from the Website, Apps, and
        Services. Generally, we rely on our legitimate interests when processing
        this information. These legitimate interests include providing our
        Services, operating our Website and Apps, and our other business
        purposes. We also may process your personal information in order to
        fulfill contracts we might have with you. If consent is the legal basis,
        we will obtain your consent and let you know how you can withdraw it.
      </p>

      <h2 className={styles.h2}>Your choices and rights</h2>

      <p className={styles.p}>
        If you would like to update your contact information or preferences,
        have your information removed from our records, or if you have comments
        or questions about this Privacy Policy, please contact us at
        hello@support.morereadings.me.
      </p>

      <p className={styles.p}>
        You are not obligated to provide us your information. If you decline to
        do so, we may be unable to respond to your requests or questions, and
        without certain information like your birth date, place of birth, and
        time of birth, some or all features of the Services may not be available
        or work at all.
      </p>

      <p className={styles.p}>
        You may opt out of email communications at any time by clicking on the
        unsubscribe link within any message that you receive. You can control
        how your browser responds to cookies by adjusting the privacy and
        security settings of your web browser. You may set your browser to not
        accept cookies, but this may limit certain functionalities on the
        Websites. How to opt out depends on the type of browser and/or device
        you are using. If you would like to opt out of analytics cookies, you
        may opt out of Google Analytics. If you reside or are in certain
        jurisdictions, you may have rights and protections in relation to your
        information under applicable laws. These rights may include the right to
        access, delete, correct, move (or port), object to the processing and
        limit the processing of your information as well as the right to lodge a
        complaint with a regulator. These rights are not absolute, are subject
        to exceptions, and we may be required or permitted by law to decline
        your request. To the extent required and permitted by applicable laws,
        we will process your request, and if applicable, we may ask you for
        additional information to verify your identity. If you would like to
        exercise your rights or have questions about the way we process your
        personal information, please contact us at hello@support.morereadings.me
      </p>

      <h2 className={styles.h2}>Changes to this privacy policy</h2>

      <p className={styles.p}>
        We may update or modify this Privacy Policy from time to time and will
        alert you that changes have been made by indicating on the Privacy
        Policy the date it was last updated at the top of this Privacy Policy,
        prominently posting an announcement of the changes on our Services, or
        by sending you a written communication using the contact information you
        provided us prior to the new policy taking effect. Users are bound by
        any changes to the Privacy Policy if they use our Websites, Apps, and
        Services after notice of such changes has been communicated. We
        encourage you to review this Privacy Policy regularly to ensure that you
        are familiar with Tacit Social LLC's current practices.
      </p>
    </div>
  );
};
