import { omit } from "lodash";
import { useEffect, useRef, useState } from "react";
import { delay } from "app/utils/delay";

export const useLuckMeter = ({
  stats,
  animationDelayCount,
}: {
  stats: any;
  animationDelayCount: number;
}) => {
  const meterStats = omit(stats, ["average"]);
  const averageScore = stats.average;
  const [isTooltipVisible, setIsTooltipVisible] = useState(false);

  const onLuckAveragePress = async () => {
    if (isTooltipVisible) {
      setIsTooltipVisible(false);
    }
  };

  return {
    meterStats,
    averageScore,
    setIsTooltipVisible,
    isTooltipVisible,
    onLuckAveragePress,
  };
};
