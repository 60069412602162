import { useEffect, useState } from "react";
import { random } from "lodash";
import { tarotCardDictionary } from "app/dictionaries/tarotCardDictionary";
import { useQuery } from "app/utils/useQuery";
import { useNavigate } from "react-router";
import { RelationshipGoalTypes } from "app/quiz/useQuiz";
import { useTranslation } from "react-i18next";

export const useScreen3 = ({
  setRelationshipGoal,
}: {
  setRelationshipGoal: (relationshipGoal: RelationshipGoalTypes) => void;
}) => {
  const query = useQuery();
  const navigate = useNavigate();
  const { t } = useTranslation("common", { keyPrefix: "tarotcardDictionary" });
  const [selectedCard, setSelectedCard] = useState<any>(
    tarotCardDictionary(t)[random(0, tarotCardDictionary(t).length - 1)]
  );
  const [hasCardBeenFlipped, setHasCardBeenFlipped] = useState(false);

  const onOptionClick = (value: RelationshipGoalTypes) => {
    setRelationshipGoal(value);
  };

  useEffect(() => {
    if (hasCardBeenFlipped) {
      query.set("complete", "true");
      navigate(`/quiz/quizlet1?${query.toString()}`);
      setTimeout(() => {
        window.location.href =
          "https://apps.apple.com/app/id6479682201?platform=iphone";
      }, 5000);
    }
  }, [hasCardBeenFlipped]);

  return {
    onOptionClick,
    selectedCard,
    setSelectedCard,
    hasCardBeenFlipped,
    setHasCardBeenFlipped,
  };
};
