export enum Path {
  HOME = "/",
  TERMS = "/terms",
  POLICIES = "/policies",
  TIKTOK = "/tiktok",
  PROFILE_CREATION_INVITE = "/profile-creation-invite",
  QUIZ = "/quiz/:type",
  QUIZ_PAYMENT_SUCCESS = "/quiz/:type/success",
  QUIZ_PAYMENT_UPSELL = "/quiz/:type/discount",
  QUIZ_REDIRECT = "/quiz",
  PLAYGROUND = "/playground",
}
