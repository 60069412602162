import { MoonSteps } from "app/components/moonsteps/moonSteps";
import * as React from "react";
import { useQuizlet1 } from "./useQuizlet1";
import classNames from "classnames";
import { Link } from "react-router-dom";
import { Path } from "app/path";
import { useBreakpoint } from "app/utils/useBreakpoint";
import { Screen1 } from "./screens/screen1/screen1";
import YellowUpArrowIcon from "app/assets/icons/yellowUpArrow.svg";
import { Button } from "@nextui-org/react";
import quizStyles from "app/quiz/quiz.module.css";
import { Screen2 } from "./screens/screen2/screen2";
import { Screen3 } from "./screens/screen3/screen3";

export interface Quizlet1Props {}

export const Quizlet1: React.FC<Quizlet1Props> = () => {
  const {
    currentScreen,
    gender,
    setGender,
    setCurrentScreen,
    relationshipStatus,
    setRelationshipStatus,
    relationshipGoal,
    setRelationshipGoal,
    screenToShow,
    setScreenToShow,
    query,
  } = useQuizlet1();

  const { isBelowMd, isAboveMd } = useBreakpoint("md");

  return (
    <div className="flex flex-col items-center relative">
      {currentScreen > 1 && !query.get("complete") && (
        <Button
          isIconOnly
          className={classNames(
            "bg-transparent rounded !p-2 border-0 absolute self-center top-0",
            quizStyles.animatedBtnGradient
          )}
          onClick={() => {
            if (currentScreen === 3 && relationshipGoal) {
              setRelationshipGoal(null);
            } else {
              setCurrentScreen(currentScreen - 1);
              setScreenToShow(currentScreen - 1);
            }
          }}
          variant="faded"
        >
          <img src={YellowUpArrowIcon} alt="send" />
        </Button>
      )}
      <div
        className={classNames("duration-300 ease-in-out", {
          "opacity-0": currentScreen !== 1,
          hidden: screenToShow !== 1,
          "opacity-100": currentScreen === 1,
        })}
      >
        <Screen1
          gender={gender}
          setGender={setGender}
          setCurrentScreen={setCurrentScreen}
        />
      </div>
      <div
        className={classNames("duration-300 ease-in-out mt-14", {
          "opacity-0": currentScreen !== 2,
          hidden: screenToShow !== 2,
          "opacity-100": currentScreen === 2,
        })}
      >
        <Screen2
          relationshipStatus={relationshipStatus}
          setRelationshipStatus={setRelationshipStatus}
          setCurrentScreen={setCurrentScreen}
        />
      </div>
      <div
        className={classNames("duration-300 ease-in-out", {
          "opacity-0": currentScreen !== 3,
          hidden: screenToShow !== 3,
          "opacity-100": currentScreen === 3,
        })}
      >
        <Screen3
          relationshipGoal={relationshipGoal}
          setRelationshipGoal={setRelationshipGoal}
          setCurrentScreen={setCurrentScreen}
        />
      </div>

      <footer
        className={classNames(
          "flex flex-col absolute items-center left-1/2 -translate-x-1/2 max-w-[329px] w-full",
          {
            // "bottom-28": isBelowMd && isAboveXxs,
            "gap-10 bottom-10": isAboveMd,
            "gap-5 bottom-5": isBelowMd,
          }
        )}
      >
        {currentScreen === 1 && (
          <div className="flex flex-col items-center">
            <p
              className={classNames("text-[#8f8671]", {
                "text-sm": isBelowMd,
              })}
            >
              By continuing, I agree to More Readings's
            </p>
            <div className="flex gap-1">
              <Link
                className={classNames("text-[#8f8671] underline", {
                  "text-sm": isBelowMd,
                })}
                to={Path.POLICIES}
              >
                Privacy policy
              </Link>
              <p
                className={classNames("text-[#8f8671]", {
                  "text-sm": isBelowMd,
                })}
              >
                and
              </p>
              <Link
                className={classNames("text-[#8f8671] underline", {
                  "text-sm": isBelowMd,
                })}
                to={Path.TERMS}
              >
                Terms of Use.
              </Link>
            </div>
          </div>
        )}
        <div className="flex gap-[80px] p-1">
          <MoonSteps currentScreen={currentScreen} />
        </div>
      </footer>
    </div>
  );
};
