import * as React from "react";
import { useQuiz2 } from "./useQuiz2";
import { RelationshipStatusTypes } from "app/quiz/useQuiz";
import classNames from "classnames";
import quizStyles from "app/quiz/quiz.module.css";
import { Button } from "@nextui-org/react";
// Lazy loading assets and components with named exports
import YellowLeftArrow from "app/assets/icons/yellowLeftArrow.svg";
import MoreReadingsLogoSvg from "app/assets/images/moreReadingsLogo.svg";
import StarPng from "app/assets/images/star.png";
import { compact } from "lodash";
import { useBreakpoint } from "app/utils/useBreakpoint";
import { startTransition } from "react";
import { Loading } from "app/components/loading/loading";
import { useTranslation } from "react-i18next";
import TrainRideInTheAlpsJpg from "app/assets/images/quiz/quiz2/trainRideInTheAlps.jpg";
import TropicalBeachBarJpg from "app/assets/images/quiz/quiz2/tropicalBeachBar.jpg";
import ParisianBistroJpg from "app/assets/images/quiz/quiz2/parisianBistro.jpg";
import TuscanyVineyardJpg from "app/assets/images/quiz/quiz2/tuscanyVineyard.jpg";
import EveningVibesJpg from "app/assets/images/quiz/quiz2/eveningVibes.jpg";
import ZenAfternoonJpg from "app/assets/images/quiz/quiz2/zenAfternoon.jpg";
import AegeanMorningJpg from "app/assets/images/quiz/quiz2/aegeanMorning.jpg";
import CozyHolidayJpg from "app/assets/images/quiz/quiz2/cozyHoliday.jpg";
import { AssignReaderScreen } from "./screens/assignReaderScreen/assignReaderScreen";
import { BorderedAvatar } from "app/components/avatars/avatars";
import { localStorageEnums } from "app/dictionaries/localStorageDictionary";
import { readingModelDictionary } from "app/dictionaries/readingModelsDictionary";
import { FadeInSlide } from "app/motions/fadeInSlide";

// Lazy loading screens with named exports handling
const GenderScreen = React.lazy(() =>
  import("./screens/genderScreen/genderScreen").then((module) => ({
    default: module.GenderScreen,
  }))
);
const ReaderSneakPeak = React.lazy(() =>
  import("./screens/readerSneakPeak/readerSnearPeak").then((module) => ({
    default: module.ReaderSneakPeak,
  }))
);
const DateOfBirthScreen = React.lazy(() =>
  import("./screens/dateOfBirthScreen/dateOfBirthScreen").then((module) => ({
    default: module.DateOfBirthScreen,
  }))
);
const SunSignScreen = React.lazy(() =>
  import("./screens/sunSignScreen/sunSignScreen").then((module) => ({
    default: module.SunSignScreen,
  }))
);
const EducationScreen = React.lazy(() =>
  import("app/quiz/components/educationScreen").then((module) => ({
    default: module.EducationScreen,
  }))
);
const RandomIconsScreen = React.lazy(() =>
  import("app/quiz/components/randonIconsScreen").then((module) => ({
    default: module.RandomIconsScreen,
  }))
);
const TarotCardScreen = React.lazy(() =>
  import("./screens/tarotCardScreen/tarotCardScreen").then((module) => ({
    default: module.TarotCardScreen,
  }))
);
const EmailScreen = React.lazy(() =>
  import("./screens/emailScreen/emailScreen").then((module) => ({
    default: module.EmailScreen,
  }))
);
const SocialProofScreen = React.lazy(() =>
  import("./screens/socialProofScreen/socialProofScreen").then((module) => ({
    default: module.SocialProofScreen,
  }))
);
const ConversionScreen = React.lazy(() =>
  import("./screens/conversionScreen/conversionScreen").then((module) => ({
    default: module.ConversionScreen,
  }))
);
const ListScreen = React.lazy(() =>
  import("app/quiz/components/listScreen").then((module) => ({
    default: module.ListScreen,
  }))
);
const ImageListScreen = React.lazy(() =>
  import("app/quiz/components/imageListScreen").then((module) => ({
    default: module.ImageListScreen,
  }))
);
const DeviceScreen = React.lazy(() =>
  import("./screens/deviceScreen/deviceScreen").then((module) => ({
    default: module.DeviceScreen,
  }))
);
const MoonProgress = React.lazy(() =>
  import("./component/moonProgress").then((module) => ({
    default: module.MoonProgress,
  }))
);
const BirthPlaceScreen = React.lazy(() =>
  import("./screens/birthPlaceScreen/birthPlaceScreen").then((module) => ({
    default: module.BirthPlaceScreen,
  }))
);
const BirthTimeScreen = React.lazy(() =>
  import("./screens/birthTimeScreen/birthTimeScreen").then((module) => ({
    default: module.BirthTimeScreen,
  }))
);
const NatalLoadingScreen = React.lazy(() =>
  import("./screens/natalLoadingScreen/natalLoadingScreen").then((module) => ({
    default: module.NatalLoadingScreen,
  }))
);
const AssignReaderLoadingScreen = React.lazy(() =>
  import("./screens/assignReaderLoadingScreen/assignReaderLoadingScreen").then(
    (module) => ({
      default: module.AssignReaderLoadingScreen,
    })
  )
);
const LoveSignsScreens = React.lazy(() =>
  import("./screens/loveSignsScreen/loveSignsScreen").then((module) => ({
    default: module.LoveSignsScreens,
  }))
);
const SeventhHouseReportScreen = React.lazy(() =>
  import("./screens/seventhHouseReportScreen/seventhHouseReportScreen").then(
    (module) => ({
      default: module.SeventhHouseReportScreen,
    })
  )
);
const SeventhHouseLoadingScreen = React.lazy(() =>
  import("./screens/seventhHouseLoadingScreen/seventhHouseLoadingScreen").then(
    (module) => ({
      default: module.SeventhHouseLoadingScreen,
    })
  )
);
const ChartChatScreen = React.lazy(() =>
  import("./screens/chartChartScreen/chartChatScreen").then((module) => ({
    default: module.ChartChatScreen,
  }))
);

export interface Quiz2Props {}

const getScreen = ({
  currentScreenIndex,
  appendAnswerToParams,
  setCurrentScreenIndex,
  hideDeviceScreen,
  t,
}: any) => {
  const screens = [
    <TarotCardScreen
      currentScreenIndex={currentScreenIndex}
      appendAnswerToParams={appendAnswerToParams}
    />,
    <ReaderSneakPeak
      currentScreenIndex={currentScreenIndex}
      appendAnswerToParams={appendAnswerToParams}
    />,
    <GenderScreen
      currentScreenIndex={currentScreenIndex}
      appendAnswerToParams={appendAnswerToParams}
    />,
    <ListScreen
      title={""}
      description={t("relationShipStatus.label")}
      questions={[
        {
          leadingEmoji: "❤️",
          title: t("relationShipStatus.single"),
          value: 0,
          onQuestionClick: (value: RelationshipStatusTypes) =>
            appendAnswerToParams({
              key: "relationshipStatus",
              value,
              nextPageIndex: currentScreenIndex + 1,
            }),
        },
        {
          leadingEmoji: "💕",
          title: t("relationShipStatus.in_relationship"),
          value: 1,
          onQuestionClick: (value: RelationshipStatusTypes) =>
            appendAnswerToParams({
              key: "relationshipStatus",
              value,
              nextPageIndex: currentScreenIndex + 1,
            }),
        },
        {
          leadingEmoji: "💍",
          title: t("relationShipStatus.married"),
          value: 2,
          onQuestionClick: (value: RelationshipStatusTypes) =>
            appendAnswerToParams({
              key: "relationshipStatus",
              value,
              nextPageIndex: currentScreenIndex + 1,
            }),
        },
        {
          leadingEmoji: "💔",
          title: t("relationShipStatus.complicated"),
          value: 3,
          onQuestionClick: (value: RelationshipStatusTypes) =>
            appendAnswerToParams({
              key: "relationshipStatus",
              value,
              nextPageIndex: currentScreenIndex + 1,
            }),
        },
        {
          leadingEmoji: "🤔",
          title: t("relationShipStatus.unsure"),
          value: 4,
          onQuestionClick: (value: RelationshipStatusTypes) =>
            appendAnswerToParams({
              key: "relationshipStatus",
              value,
              nextPageIndex: currentScreenIndex + 1,
            }),
        },
      ]}
    />,
    <ListScreen
      title={""}
      description={t("perfectLoveStory.label")}
      questions={[
        {
          title: t("perfectLoveStory.passion"),
          value: 0,
          onQuestionClick: (value: number) =>
            appendAnswerToParams({
              key: "perfectLoveStory",
              value,
              nextPageIndex: currentScreenIndex + 1,
            }),
        },
        {
          title: t("perfectLoveStory.understanding"),
          value: 1,
          onQuestionClick: (value: number) =>
            appendAnswerToParams({
              key: "perfectLoveStory",
              value,
              nextPageIndex: currentScreenIndex + 1,
            }),
        },
        {
          title: t("perfectLoveStory.excitement"),
          value: 2,
          onQuestionClick: (value: number) =>
            appendAnswerToParams({
              key: "perfectLoveStory",
              value,
              nextPageIndex: currentScreenIndex + 1,
            }),
        },
        {
          title: t("perfectLoveStory.connection"),
          value: 3,
          onQuestionClick: (value: number) =>
            appendAnswerToParams({
              key: "perfectLoveStory",
              value,
              nextPageIndex: currentScreenIndex + 1,
            }),
        },
      ]}
    />,
    <ImageListScreen
      title={""}
      description={t("locationVibeDate.label")}
      imageQuestions={[
        {
          title: t("locationVibeDate.alps"),
          value: 0,
          image: TrainRideInTheAlpsJpg,
          onQuestionClick: (value: number) =>
            appendAnswerToParams({
              key: "locationVibeDate",
              value,
              nextPageIndex: currentScreenIndex + 1,
            }),
        },
        {
          title: t("locationVibeDate.beachBar"),
          value: 1,
          image: TropicalBeachBarJpg,
          onQuestionClick: (value: number) =>
            appendAnswerToParams({
              key: "locationVibeDate",
              value,
              nextPageIndex: currentScreenIndex + 1,
            }),
        },
        {
          title: t("locationVibeDate.bistro"),
          value: 2,
          image: ParisianBistroJpg,
          onQuestionClick: (value: number) =>
            appendAnswerToParams({
              key: "locationVibeDate",
              value,
              nextPageIndex: currentScreenIndex + 1,
            }),
        },
        {
          title: t("locationVibeDate.vineyard"),
          value: 3,
          image: TuscanyVineyardJpg,
          onQuestionClick: (value: number) =>
            appendAnswerToParams({
              key: "locationVibeDate",
              value,
              nextPageIndex: currentScreenIndex + 1,
            }),
        },
      ]}
    />,
    <ListScreen
      title={""}
      description={t("mostImportantInPartner.label")}
      questions={[
        {
          title: t("mostImportantInPartner.connection"),
          value: 0,
          onQuestionClick: (value: number) =>
            appendAnswerToParams({
              key: "mostImportantInPartner",
              value,
              nextPageIndex: currentScreenIndex + 1,
            }),
        },
        {
          title: t("mostImportantInPartner.humor"),
          value: 1,
          onQuestionClick: (value: number) =>
            appendAnswerToParams({
              key: "mostImportantInPartner",
              value,
              nextPageIndex: currentScreenIndex + 1,
            }),
        },
        {
          title: t("mostImportantInPartner.ambitions"),
          value: 2,
          onQuestionClick: (value: number) =>
            appendAnswerToParams({
              key: "mostImportantInPartner",
              value,
              nextPageIndex: currentScreenIndex + 1,
            }),
        },
        {
          title: t("mostImportantInPartner.dependability"),
          value: 3,
          onQuestionClick: (value: number) =>
            appendAnswerToParams({
              key: "mostImportantInPartner",
              value,
              nextPageIndex: currentScreenIndex + 1,
            }),
        },
        {
          title: t("mostImportantInPartner.passion"),
          value: 4,
          onQuestionClick: (value: number) =>
            appendAnswerToParams({
              key: "mostImportantInPartner",
              value,
              nextPageIndex: currentScreenIndex + 1,
            }),
        },
      ]}
    />,
    <EducationScreen
      img={StarPng}
      description={t("quiz.sun_sign")}
      currentScreenIndex={currentScreenIndex}
      appendAnswerToParams={appendAnswerToParams}
    />,
    <DateOfBirthScreen
      currentScreenIndex={currentScreenIndex}
      appendAnswerToParams={appendAnswerToParams}
    />,
    <FadeInSlide direction="bottom" key="sunsign">
      <SunSignScreen
        currentScreenIndex={currentScreenIndex}
        appendAnswerToParams={appendAnswerToParams}
        setCurrentScreenIndex={setCurrentScreenIndex}
      />
    </FadeInSlide>,
    <RandomIconsScreen
      description={t("quiz.moon_rising_venus")}
      currentScreenIndex={currentScreenIndex}
      appendAnswerToParams={appendAnswerToParams}
    />,
    <BirthPlaceScreen
      currentScreenIndex={currentScreenIndex}
      appendAnswerToParams={appendAnswerToParams}
    />,
    <BirthTimeScreen
      currentScreenIndex={currentScreenIndex}
      appendAnswerToParams={appendAnswerToParams}
    />,
    <NatalLoadingScreen
      currentScreenIndex={currentScreenIndex}
      appendAnswerToParams={appendAnswerToParams}
    />,
    <FadeInSlide direction="bottom" key="lovesign">
      <LoveSignsScreens
        currentScreenIndex={currentScreenIndex}
        appendAnswerToParams={appendAnswerToParams}
      />
    </FadeInSlide>,
    <AssignReaderLoadingScreen
      currentScreenIndex={currentScreenIndex}
      appendAnswerToParams={appendAnswerToParams}
    />,
    <AssignReaderScreen
      currentScreenIndex={currentScreenIndex}
      appendAnswerToParams={appendAnswerToParams}
    />,
    <EmailScreen
      currentScreenIndex={currentScreenIndex}
      appendAnswerToParams={appendAnswerToParams}
    />,
    <ListScreen
      title={""}
      description={t("questionLove.label")}
      questions={[
        {
          title: t("questionLove.right_person"),
          value: 0,
          onQuestionClick: (value: number) =>
            appendAnswerToParams({
              key: "loveQuestion",
              value,
              nextPageIndex: currentScreenIndex + 1,
            }),
        },
        {
          title: t("questionLove.relationship_last"),
          value: 1,
          onQuestionClick: (value: number) =>
            appendAnswerToParams({
              key: "loveQuestion",
              value,
              nextPageIndex: currentScreenIndex + 1,
            }),
        },
        {
          title: t("questionLove.keep_waiting"),
          value: 2,
          onQuestionClick: (value: number) =>
            appendAnswerToParams({
              key: "loveQuestion",
              value,
              nextPageIndex: currentScreenIndex + 1,
            }),
        },
        {
          title: t("questionLove.astrology_help"),
          value: 3,
          onQuestionClick: (value: number) =>
            appendAnswerToParams({
              key: "loveQuestion",
              value,
              nextPageIndex: currentScreenIndex + 1,
            }),
        },
        {
          title: t("questionLove.not_sure"),
          value: 4,
          onQuestionClick: (value: number) =>
            appendAnswerToParams({
              key: "loveQuestion",
              value,
              nextPageIndex: currentScreenIndex + 1,
            }),
        },
      ]}
    />,
    <ListScreen
      title={""}
      description={t("relationshipFear.label")}
      questions={[
        {
          title: t("relationshipFear.losingSpark"),
          value: 0,
          onQuestionClick: (value: number) =>
            appendAnswerToParams({
              key: "relationshipFear",
              value,
              nextPageIndex: currentScreenIndex + 1,
            }),
        },
        {
          title: t("relationshipFear.betrayal"),
          value: 1,
          onQuestionClick: (value: number) =>
            appendAnswerToParams({
              key: "relationshipFear",
              value,
              nextPageIndex: currentScreenIndex + 1,
            }),
        },
        {
          title: t("relationshipFear.getsMe"),
          value: 2,
          onQuestionClick: (value: number) =>
            appendAnswerToParams({
              key: "relationshipFear",
              value,
              nextPageIndex: currentScreenIndex + 1,
            }),
        },
        {
          title: t("relationshipFear.unfulfillingRelationship"),
          value: 3,
          onQuestionClick: (value: number) =>
            appendAnswerToParams({
              key: "relationshipFear",
              value,
              nextPageIndex: currentScreenIndex + 1,
            }),
        },
        {
          title: t("relationshipFear.missingOutOnTrueLove"),
          value: 4,
          onQuestionClick: (value: number) =>
            appendAnswerToParams({
              key: "relationshipFear",
              value,
              nextPageIndex: currentScreenIndex + 1,
            }),
        },
      ]}
    />,
    <ListScreen
      title={""}
      description={t("affectingRelationship.label")}
      questions={[
        {
          title: t("affectingRelationship.infidelity"),
          value: 0,
          onQuestionClick: (value: number) =>
            appendAnswerToParams({
              key: "relationshipIssues",
              value,
              nextPageIndex: currentScreenIndex + 1,
            }),
        },
        {
          title: t("affectingRelationship.depression"),
          value: 1,
          onQuestionClick: (value: number) =>
            appendAnswerToParams({
              key: "relationshipIssues",
              value,
              nextPageIndex: currentScreenIndex + 1,
            }),
        },
        {
          title: t("affectingRelationship.low_sexual_desire"),
          value: 2,
          onQuestionClick: (value: number) =>
            appendAnswerToParams({
              key: "relationshipIssues",
              value,
              nextPageIndex: currentScreenIndex + 1,
            }),
        },
        {
          title: t("affectingRelationship.postpartum_depression"),
          value: 3,
          onQuestionClick: (value: number) =>
            appendAnswerToParams({
              key: "relationshipIssues",
              value,
              nextPageIndex: currentScreenIndex + 1,
            }),
        },
        {
          title: t("affectingRelationship.anxiety"),
          value: 4,
          onQuestionClick: (value: number) =>
            appendAnswerToParams({
              key: "relationshipIssues",
              value,
              nextPageIndex: currentScreenIndex + 1,
            }),
        },
      ]}
    />,
    <SeventhHouseLoadingScreen
      currentScreenIndex={currentScreenIndex}
      appendAnswerToParams={appendAnswerToParams}
    />,
    <FadeInSlide direction="bottom" key="seventhhouse">
      <SeventhHouseReportScreen
        currentScreenIndex={currentScreenIndex}
        appendAnswerToParams={appendAnswerToParams}
      />
    </FadeInSlide>,
    <ListScreen
      title={""}
      description={t("worries.label")}
      questions={[
        {
          title: t("worries.true_love"),
          value: 0,
          onQuestionClick: (value: number) =>
            appendAnswerToParams({
              key: "loveWorries",
              value,
              nextPageIndex: currentScreenIndex + 1,
            }),
        },
        {
          title: t("worries.wrong_person"),
          value: 1,
          onQuestionClick: (value: number) =>
            appendAnswerToParams({
              key: "loveWorries",
              value,
              nextPageIndex: currentScreenIndex + 1,
            }),
        },
        {
          title: t("worries.average_match"),
          value: 2,
          onQuestionClick: (value: number) =>
            appendAnswerToParams({
              key: "loveWorries",
              value,
              nextPageIndex: currentScreenIndex + 1,
            }),
        },
        {
          title: t("worries.not_sure"),
          value: 3,
          onQuestionClick: (value: number) =>
            appendAnswerToParams({
              key: "loveWorries",
              value,
              nextPageIndex: currentScreenIndex + 1,
            }),
        },
      ]}
    />,
    <ListScreen
      title={""}
      description={t("perfectPartnerFeeling.label")}
      questions={[
        {
          title: t("perfectPartnerFeeling.cherished"),
          value: 0,
          onQuestionClick: (value: number) =>
            appendAnswerToParams({
              key: "perfectPartnerFeeling",
              value,
              nextPageIndex: currentScreenIndex + 1,
            }),
        },
        {
          title: t("perfectPartnerFeeling.understood"),
          value: 1,
          onQuestionClick: (value: number) =>
            appendAnswerToParams({
              key: "perfectPartnerFeeling",
              value,
              nextPageIndex: currentScreenIndex + 1,
            }),
        },
        {
          title: t("perfectPartnerFeeling.inspired"),
          value: 2,
          onQuestionClick: (value: number) =>
            appendAnswerToParams({
              key: "perfectPartnerFeeling",
              value,
              nextPageIndex: currentScreenIndex + 1,
            }),
        },
        {
          title: t("perfectPartnerFeeling.safe"),
          value: 3,
          onQuestionClick: (value: number) =>
            appendAnswerToParams({
              key: "perfectPartnerFeeling",
              value,
              nextPageIndex: currentScreenIndex + 1,
            }),
        },
      ]}
    />,
    <ListScreen
      title={""}
      description={t("nextDate.label")}
      questions={[
        {
          title: t("nextDate.2_3_days"),
          value: 0,
          onQuestionClick: (value: number) =>
            appendAnswerToParams({
              key: "nextDate",
              value,
              nextPageIndex: currentScreenIndex + 1,
            }),
        },
        {
          title: t("nextDate.week"),
          value: 1,
          onQuestionClick: (value: number) =>
            appendAnswerToParams({
              key: "nextDate",
              value,
              nextPageIndex: currentScreenIndex + 1,
            }),
        },
        {
          title: t("nextDate.weeks"),
          value: 2,
          onQuestionClick: (value: number) =>
            appendAnswerToParams({
              key: "nextDate",
              value,
              nextPageIndex: currentScreenIndex + 1,
            }),
        },
        {
          title: t("nextDate.month"),
          value: 3,
          onQuestionClick: (value: number) =>
            appendAnswerToParams({
              key: "nextDate",
              value,
              nextPageIndex: currentScreenIndex + 1,
            }),
        },
        {
          title: t("nextDate.months"),
          value: 4,
          onQuestionClick: (value: number) =>
            appendAnswerToParams({
              key: "nextDate",
              value,
              nextPageIndex: currentScreenIndex + 1,
            }),
        },
        {
          title: t("nextDate.none"),
          value: 5,
          onQuestionClick: (value: number) =>
            appendAnswerToParams({
              key: "nextDate",
              value,
              nextPageIndex: currentScreenIndex + 1,
            }),
        },
      ]}
    />,
    <ImageListScreen
      title={""}
      description={t("chooseOneThatSparksJoy.label")}
      imageQuestions={[
        {
          title: t("chooseOneThatSparksJoy.eveningVibes"),
          value: 0,
          image: EveningVibesJpg,
          onQuestionClick: (value: number) =>
            appendAnswerToParams({
              key: "chooseOneThatSparksJoy",
              value,
              nextPageIndex: currentScreenIndex + 1,
            }),
        },
        {
          title: t("chooseOneThatSparksJoy.zenAfternoon"),
          value: 1,
          image: ZenAfternoonJpg,
          onQuestionClick: (value: number) =>
            appendAnswerToParams({
              key: "chooseOneThatSparksJoy",
              value,
              nextPageIndex: currentScreenIndex + 1,
            }),
        },
        {
          title: t("chooseOneThatSparksJoy.aegeanMorning"),
          value: 2,
          image: AegeanMorningJpg,
          onQuestionClick: (value: number) =>
            appendAnswerToParams({
              key: "chooseOneThatSparksJoy",
              value,
              nextPageIndex: currentScreenIndex + 1,
            }),
        },
        {
          title: t("chooseOneThatSparksJoy.cozyHoliday"),
          value: 3,
          image: CozyHolidayJpg,
          onQuestionClick: (value: number) =>
            appendAnswerToParams({
              key: "chooseOneThatSparksJoy",
              value,
              nextPageIndex: currentScreenIndex + 1,
            }),
        },
      ]}
    />,
    <SocialProofScreen
      currentScreenIndex={currentScreenIndex}
      appendAnswerToParams={appendAnswerToParams}
    />,
    <ChartChatScreen
      currentScreenIndex={currentScreenIndex}
      appendAnswerToParams={appendAnswerToParams}
    />,
    !hideDeviceScreen && (
      <DeviceScreen
        appendAnswerToParams={appendAnswerToParams}
        currentScreenIndex={currentScreenIndex}
      />
    ),
    <ConversionScreen />,
  ];

  return compact(screens)[currentScreenIndex];
};

export const Quiz2: React.FC<Quiz2Props> = ({}) => {
  const {
    currentScreenIndex,
    appendAnswerToParams,
    setCurrentScreenIndex,
    goBack,
    hideDeviceScreen,
  } = useQuiz2();

  const { isBelowXxs } = useBreakpoint("xxs");
  const { isBelowMd, isAboveMd } = useBreakpoint("md");
  const { t } = useTranslation();

  return (
    <div className="flex flex-col w-full max-w-192 mobile:max-w-[350px] items-center">
      <React.Suspense fallback={<Loading />}>
        <div
          className={classNames(
            "flex justify-between w-full max-w-112 mobile:max-w-[313px] mb-6 relative mobile:justify-normal",
            {
              hidden: currentScreenIndex > 26 || currentScreenIndex === 0,
            }
          )}
        >
          <Button
            isIconOnly
            className={classNames(
              "bg-transparent rounded !p-2 border-0 -left-20 top-10 absolute mobile:-left-5 mobile:top-2 mobile:relative",
              quizStyles.animatedBtnGradient
            )}
            onClick={() => {
              startTransition(() => {
                goBack();
              });
            }}
            variant="faded"
          >
            <img src={YellowLeftArrow} alt="back" />
          </Button>

          <div className="w-full mobile:w-[77%]">
            <MoonProgress
              currentIndex={currentScreenIndex}
              totalPages={28}
              dashCount={isBelowXxs ? 40 : 45}
              activeIndexCheckpoints={[0, 7, 14, 27]}
            />
          </div>
          {localStorage.getItem(localStorageEnums.ELEMENT) && (
            <div
              className={classNames(
                "absolute self-center top-4 -right-40 mobile:-right-5 mobile:top-2",
                {
                  hidden: currentScreenIndex < 17 || currentScreenIndex > 26,
                }
              )}
            >
              <BorderedAvatar
                src={
                  readingModelDictionary(t)[
                    localStorage.getItem(localStorageEnums.ELEMENT)!
                  ].image
                }
                size={isBelowMd ? "xs" : "md"}
              />
            </div>
          )}
        </div>
        <div className="h-full">
          {getScreen({
            currentScreenIndex,
            appendAnswerToParams,
            setCurrentScreenIndex,
            hideDeviceScreen,
            t,
          })}
        </div>
        {currentScreenIndex !== 0 && currentScreenIndex !== 28 && (
          <div
            className={classNames("mobile:scale-75 mobile:bottom-5", {
              "relative my-10": currentScreenIndex === 14,
              "my-3 bottom-0": currentScreenIndex !== 14,
            })}
          >
            <img src={MoreReadingsLogoSvg} alt="more readings" />
          </div>
        )}
      </React.Suspense>
    </div>
  );
};
