import classnames from "classnames";
import { LazyLoadImage } from "react-lazy-load-image-component";
import AvatarBorderSvg from "app/assets/images/avatarBorder.svg";

export interface AvatarProps {
  src: string;
  size?: "xs" | "sm" | "md" | "lg";
  active?: boolean;
  containerHeightClass?: string;
  isUser?: boolean;
}

export const BorderedAvatar: React.FC<AvatarProps> = ({
  src,
  size = "md",
  isUser = false,
}) => {
  return (
    <div className={classnames("relative flex justify-center self-center")}>
      <img
        src={AvatarBorderSvg}
        alt={`border avatar`}
        className={classnames("absolute top-0 z-10", {
          "scale-[1.04]": true,
          "w-[48px] h-[48px]": size === "xs",
          "w-[64px] h-[64px]": size === "sm",
          "w-[100px] h-[100px]": size === "md",
          "w-[148px] h-[146px]": size === "lg",
        })}
      />
      {/* <img
        src={GlowSvg}
        alt={`background glow`}
        className={classnames(
          styles.backgroundGlow,
          "w-full -translate-y-[2px]",
          {
            hidden: isUser,
          }
        )}
      /> */}
      <div
        className={classnames("relative overflow-hidden !max-h-[148px]", {
          "w-[48px] h-[48px]": size === "xs",
          "w-[64px] h-[64px]": size === "sm",
          "w-[100px] h-[100px]": size === "md",
          "w-[148px] h-[146px]": size === "lg",
        })}
      >
        <LazyLoadImage
          src={src}
          alt={`7th sister`}
          placeholder={<img src={src} alt={`src`} />}
        />
        {/* 
        <div
          id="grained"
          className={classnames("absolute left-0 top-0 w-full !max-h-[148px]", {
            "w-[64px]": size === "sm",
            "w-[100px] h-[100px]": size === "md",
            "w-[148px] h-[146px]": size === "lg",
          })}
        /> */}

        {/* <img
          src={GlowSvg}
          alt={`frontal glow`}
          className={classnames(styles.frontalGlow, "w-full", {
            "translate-y-14": size === "lg" || size === "md",
            "translate-y-8": size === "sm",
            hidden: isUser,
          })}
        /> */}
      </div>
    </div>
  );
};
