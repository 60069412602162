import * as React from "react";
import styles from "app/legal/legal.module.css";

export interface TermsProps {}

export const Terms: React.FC<TermsProps> = (props) => {
  return (
    <div className={styles.container}>
      <h1 className={styles.h1}>Términos de Servicio</h1>
      <p className={styles.p}>ÚLTIMA ACTUALIZACIÓN: 4 DE ABRIL DE 2024</p>
      <p className={styles.p}>
        Estos Términos de Servicio (estos “Términos”) se aplican a tu acceso y
        uso de: (1) nuestros sitios web ubicados en morereadings.com y
        shop.morereadings.com (los “Sitios Web”); (2) nuestras aplicaciones
        móviles (“Apps”); y (3) cualquier servicio, contenido y características
        disponibles por nosotros a través de los Sitios Web o las Apps (junto
        con los Sitios Web y las Apps, nuestros “Servicios”). En estos Términos,
        “Tacit Social LLC.” “More Readings” el “Compañía”, “nosotros”, “nos” y
        “nuestro” se refieren a More Readings y nuestras afiliadas, sucesores y
        cesionarios; y “tú” y “tu” se refieren a cualquier usuario de nuestros
        Servicios.
      </p>

      <h2 className={styles.h2}>Aceptación de estos términos</h2>

      <p className={styles.p}>
        Tu acceso y uso de nuestros Servicios están sujetos en todo momento a
        estos Términos y a nuestra Política de Privacidad ubicada en
        morereadings.com/privacy incorporada aquí por referencia. Por favor, lee
        estos Términos cuidadosamente. Al utilizar los Servicios, declaras que
        estás legalmente capacitado para entrar en este acuerdo. Al acceder o
        usar nuestros Servicios de cualquier manera o al hacer clic para aceptar
        o acordar los Términos cuando esta opción esté disponible para ti,
        aceptas estar sujeto a estos Términos. Si no estás de acuerdo con todos
        los términos y condiciones de estos Términos, no accedas ni uses
        nuestros Servicios.
      </p>

      <p className={styles.p}>
        Estos términos contienen una disposición obligatoria de arbitraje
        individual y renuncia a juicios por jurado/acuerdos colectivos que
        requiere el uso de arbitraje de manera individual para resolver
        disputas, en lugar de juicios por jurado o acciones colectivas, y
        también limita los recursos disponibles para ti en caso de una disputa.
      </p>

      <h2 className={styles.h2}>Uso no autorizado por menores</h2>

      <p className={styles.p}>
        Nuestros Servicios no están destinados a niños menores de 13 años. Si
        tienes menos de 13 años, tienes prohibido crear una cuenta o usar los
        Servicios. Si legalmente se requiere, los usuarios entre 13 y 18 años de
        edad pueden usar los Servicios solo con el permiso y la supervisión de
        un padre o tutor legal. Si no cumples con todos estos requisitos, tienes
        prohibido acceder o usar los Servicios.
      </p>

      <h2 className={styles.h2}>Modificación de los Términos</h2>

      <p className={styles.p}>
        Podemos revisar estos Términos de vez en cuando a nuestra sola
        discreción y sin previo aviso, sujeto a la ley aplicable. La fecha de la
        última actualización se refleja al inicio de estos Términos. Cuando
        revisamos estos Términos, publicaremos la versión revisada en los
        Servicios. Tienes la libertad de decidir si aceptas o no una versión
        revisada de estos Términos, pero aceptar estos Términos, tal como han
        sido revisados, es necesario para que continúes accediendo o usando los
        Servicios. Si no estás de acuerdo con estos Términos o cualquier versión
        revisada de estos Términos, tu único recurso es terminar tu acceso o uso
        de los Servicios. Excepto por lo expresamente indicado por nosotros, tu
        acceso y uso de los Servicios están sujetos a, y constituyen tu
        aceptación de, la versión de estos Términos en vigor en el momento de tu
        acceso o uso.
      </p>

      <h2 className={styles.h2}>Acceso y seguridad de la cuenta</h2>

      <p className={styles.p}>
        Estamos mejorando constantemente nuestros Servicios y creando nuevos
        todo el tiempo. Esto significa que podemos agregar, dejar de actualizar
        o eliminar características, productos, contenido o funcionalidades, y
        también podemos suspender o detener los Servicios por completo. Podemos
        tomar cualquiera de estas acciones en cualquier momento, y cuando lo
        hagamos, intentaremos notificarte con antelación, pero esto no siempre
        será posible.
      </p>

      <p className={styles.p}>
        Para acceder y usar algunos de los Servicios, es posible que se te
        requiera proporcionar cierta información (“Información del Usuario”) y
        registrar una cuenta (“Cuenta”). Nuestra Política de Privacidad gobierna
        nuestra recopilación, uso, almacenamiento y divulgación de cualquier
        información personal contenida dentro de la Información del Usuario.
        Declaras y garantizas que toda la Información del Usuario que nos
        proporcionas ocasionalmente en relación con los Servicios es veraz,
        precisa, actual y completa. Aceptas notificarnos rápidamente sobre
        cambios en tu Información del Usuario actualizando tu Cuenta en el Sitio
        Web o a través de las Apps.
      </p>

      <p className={styles.p}>
        Eres responsable de cualquier actividad que ocurra en tu cuenta de More
        Readings, ya sea autorizada o no por ti. Por lo tanto, es importante que
        tomes medidas apropiadas para mantener segura tu cuenta (por ejemplo,
        usando contraseñas fuertes, manteniendo tu contraseña privada, teniendo
        precaución al usar dispositivos públicos o compartidos). La Compañía no
        será responsable por ninguna pérdida que incurras como resultado de que
        alguien más use tu cuenta o contraseña, ya sea con o sin tu
        conocimiento. Puedes ser responsable por cualquier pérdida incurrida por
        la Compañía, sus afiliadas, oficiales, directores, empleados,
        consultores, agentes y representantes debido al uso de tu cuenta o
        contraseña por parte de otra persona. Excepto si lo establece
        expresamente de otra manera, no somos responsables por ninguna pérdida o
        daño que surja del pérdida o robo de tu nombre de usuario, contraseña u
        otra información de seguridad. Si crees que alguien ha obtenido acceso a
        tu cuenta, por favor comunícate inmediatamente con
        hello@support.morereadings.me
      </p>

      <h2 className={styles.h2}>Derechos de propiedad intelectual</h2>

      <p className={styles.p}>
        Los Servicios y todo el contenido relacionado, características y
        funcionalidades (incluyendo, pero no limitado a, toda la información,
        software, texto, displays, imágenes, videos y audio, y el diseño,
        selección y disposición de los mismos), son propiedad de Tacit Social
        LLC, sus licenciantes u otros proveedores de dicho material y están
        protegidos por las leyes de derechos de autor, marcas comerciales,
        patentes, secretos comerciales y otros derechos de propiedad intelectual
        o propietarios de EE.UU. e internacionales, según corresponda.
      </p>

      <p className={styles.p}>
        Sujeto a estos Términos, se te permite acceder y usar los Servicios para
        tu uso personal y no comercial únicamente. El nombre de More Readings,
        el logo de More Readings y todos los nombres relacionados, logos,
        nombres de productos y servicios, diseños y eslóganes son marcas
        comerciales de Tacit Social LLC. No debes usar dichas marcas sin nuestro
        permiso previo por escrito. No puedes hacer lo siguiente (o permitir que
        alguien más lo haga):
      </p>

      <ul className={styles.ul}>
        <li className={styles.li}>
          Copiar, archivar, descargar, subir, distribuir, sindicar, transmitir,
          mostrar, imprimir, monitorear, poner a disposición, modificar o usar
          de cualquier otra manera cualquier porción de los Servicios o el
          contenido en los Servicios, excepto según lo establecido en estos
          Términos;
        </li>
        <li className={styles.li}>
          Usar los Servicios, cualquier herramienta proporcionada por los
          Servicios, o cualquier contenido en los Servicios para cualquier
          propósito comercial sin obtener nuestro consentimiento previo por
          escrito; o violar o infringir los derechos de autor, marcas
          comerciales u otros derechos de propiedad intelectual de More
          Readings.
        </li>
      </ul>
      <p className={styles.p}>
        Si deseas usar nuestros activos de marca de una manera no cubierta por
        estas directrices, por favor contáctanos en
        hello@support.morereadings.me
      </p>

      <p className={styles.p}>
        Si violas estos Términos, tu derecho a acceder y usar los Servicios
        cesará inmediatamente, y deberás, a nuestra opción, devolver o destruir
        cualquier copia de los materiales que hayas hecho. No se transfiere a ti
        ningún derecho, título o interés en los Servicios, y todos los derechos
        no expresamente concedidos están reservados por la Compañía. Cualquier
        acceso o uso de los Servicios que no esté expresamente permitido por
        estos Términos es una violación de estos Términos y puede violar
        derechos de autor, marcas comerciales y otras leyes.
      </p>

      <h2 className={styles.h2}>Usos prohibidos</h2>
      <p className={styles.p}>
        Solo puedes usar los Servicios para fines legales y de acuerdo con estos
        Términos. Si tu acceso o uso de los Servicios está prohibido por la ley
        aplicable, entonces no estás autorizado para acceder o usar los
        Servicios. No somos responsables si accedes o usas los Servicios de
        cualquier manera que viole la ley aplicable. Aceptas no usar los
        Servicios:
      </p>
      <ul className={styles.ul}>
        <li className={styles.li}>
          De cualquier manera que viole cualquier ley o regulación federal,
          estatal, local o internacional aplicable (incluyendo, sin limitación,
          cualquier ley referente a la exportación de datos o software hacia y
          desde los Estados Unidos u otros países);
        </li>
        <li className={styles.li}>
          Con el propósito de explotar, dañar o intentar explotar o dañar a
          menores de cualquier manera exponiéndolos a contenido inapropiado,
          solicitando información de identificación personal u otro;
        </li>
        <li className={styles.li}>
          De manera que viole los estándares de contenido establecidos en estos
          Términos cuando envías, recibes conscientemente, subes, descargas,
          usas o reutilizas cualquier material que viole estos Términos o la ley
          aplicable;
        </li>
        <li className={styles.li}>
          Transmitir, o procurarse el envío de, cualquier material publicitario
          o promocional sin nuestro consentimiento previo por escrito,
          incluyendo cualquier correo basura, spam u otra solicitud;
        </li>
        <li className={styles.li}>
          Suplantar o intentar suplantar a More Readings, a un empleado de More
          Readings, a otro usuario o a cualquier otra persona o entidad
          (incluyendo, sin limitación, usar direcciones de correo electrónico o
          nombres de usuario asociados con cualquiera de los anteriores); o
        </li>
        <li className={styles.li}>
          Participar en cualquier otra conducta que restrinja o inhiba el uso o
          disfrute de los Servicios por parte de cualquiera, o que, según lo
          determinen nosotros, pueda dañar a More Readings o a los usuarios de
          los Servicios o exponerlos a responsabilidad.
        </li>
      </ul>

      <h2 className={styles.h2}>Además, aceptas no:</h2>

      <ul className={styles.ul}>
        <li className={styles.li}>
          Usar los Servicios de cualquier manera que pudiera deshabilitar,
          sobrecargar, dañar o perjudicar los Servicios o interferir con el uso
          de los Servicios por parte de cualquier otra persona, incluyendo su
          capacidad para participar en actividades en tiempo real a través de
          los Servicios;
        </li>
        <li className={styles.li}>
          Usar cualquier robot, araña u otro dispositivo automático, proceso o
          medio para acceder a los Servicios con cualquier propósito, incluyendo
          monitorear o copiar cualquier material disponible a través de los
          Servicios;
        </li>
        <li className={styles.li}>
          Usar cualquier dispositivo, software o rutina que interfiera con el
          correcto funcionamiento de los Servicios;
        </li>
        <li className={styles.li}>
          Introducir cualquier virus, caballo de Troya, gusano, bomba lógica u
          otro material que sea malicioso o tecnológicamente dañino;
        </li>
        <li className={styles.li}>
          Intentar obtener acceso no autorizado, interferir, dañar o perturbar
          cualquier parte de los Servicios, los servidores en los que se
          almacenan los Servicios, o cualquier servidor, computadora o base de
          datos conectada a los Servicios;
        </li>
        <li className={styles.li}>
          Atacar los Servicios mediante un ataque de denegación de servicio o un
          ataque distribuido de denegación de servicio; o
        </li>
        <li className={styles.li}>
          Intentar de otra manera interferir con el correcto funcionamiento de
          los Servicios.
        </li>
      </ul>

      <h2 className={styles.h2}>Contenido subido</h2>

      <p className={styles.p}>
        Aspectos de nuestros Servicios te permiten crear, subir, publicar,
        enviar, recibir y almacenar contenido (colectivamente, “Contenido
        Subido”). El contenido subido no debe:
      </p>
      <ul className={styles.ul}>
        <li className={styles.li}>
          Violentar o infringir los derechos de publicidad, privacidad, derechos
          de autor, marcas comerciales u otros derechos de propiedad de otra
          persona;
        </li>
        <li className={styles.li}>
          Contener cualquier material que sea falso, difamatorio, calumnioso,
          obsceno, acosador, amenazante, discriminatorio, sectario, odioso,
          violento, vulgar, profano, pornográfico o de otra manera ofensivo,
          inapropiado, dañino, ilegal, disruptivo o perjudicial;
        </li>
        <li className={styles.li}>
          Buscar dañar o explotar a niños exponiéndolos a contenido inapropiado,
          solicitando información de identificación personal u otro;
        </li>
        <li className={styles.li}>
          Acosar, intimidar, difamar, exponer, hacer spam o solicitar a nuestros
          usuarios;
        </li>
        <li className={styles.li}>
          Ser de otra manera objetable u ofensivo según lo determine nosotros a
          nuestra sola discreción.
        </li>
      </ul>

      <p className={styles.p}>
        Cualquier Contenido Subido será considerado información personal y
        tratado como tal según los términos descritos en la Política de
        Privacidad, incluyendo cualquier derecho que More Readings y sus
        afiliadas puedan tener en y sobre la información personal. Declaras y
        garantizas que:
      </p>

      <ul className={styles.ul}>
        <li className={styles.li}>
          Eres dueño o controlas todos los derechos en y sobre el Contenido
          Subido que publicas y tienes el derecho de otorgar a More Readings y
          sus afiliadas los derechos descritos en la Política de Privacidad;
        </li>
        <li className={styles.li}>
          Todo Contenido Subido que publiques no y no infringirá ni violará los
          derechos de ningún tercero; y
        </li>
        <li className={styles.li}>
          Todo Contenido Subido que publiques cumple y cumplirá con estos
          Términos.
        </li>
      </ul>

      <p className={styles.p}>
        Entiendes y reconoces que eres responsable únicamente del Contenido
        Subido que publicas, y tú, no Tacit Social LLC., tienes plena
        responsabilidad por dicho Contenido Subido, incluyendo su legalidad,
        confiabilidad, precisión y adecuación.
      </p>

      <p className={styles.p}>
        No somos responsables, ni estamos obligados ante ningún tercero, por el
        contenido o la precisión de cualquier Contenido Subido que publiques tú
        o cualquier otro usuario de los Servicios.
      </p>

      <p className={styles.p}>
        Aunque no estamos obligados a hacerlo, podemos acceder, revisar,
        monitorear y eliminar tu contenido en cualquier momento y por cualquier
        razón, incluyendo para proveer y desarrollar los Servicios o si creemos
        que tu contenido viola estos Términos. Sin embargo, tú permaneces
        únicamente responsable del contenido que creas, subes, publicas, envías
        o almacenas a través del Servicio.
      </p>

      <h2 className={styles.h2}>
        Prohibición de Materiales Fotográficos Ilegales
      </h2>

      <p className={styles.p}>
        CONTENIDO PROHIBIDO: El uso de More Readings requiere el cumplimiento de
        las leyes locales, nacionales e internacionales. Los usuarios tienen
        estricta prohibición de subir, compartir o diseminar cualquier material
        fotográfico que sea ilegal bajo cualquier ley aplicable. Esto incluye,
        pero no se limita a, imágenes que sean pornográficas, abusivas, que
        violen la privacidad, que promuevan actividades ilegales o que infrinjan
        derechos de propiedad intelectual. 1.2 Responsabilidad del Usuario: Los
        usuarios son únicamente responsables de asegurar que el contenido que
        suben a More Readings cumpla con todas las leyes y regulaciones
        aplicables. Al usar More Readings, los usuarios acuerdan no subir ningún
        contenido que pueda ser considerado ilegal o dañino.
      </p>

      <p className={styles.p}>
        Aplicación y Derecho a Rechazar Servicio: More Readings se reserva el
        derecho de revisar y monitorear el contenido para asegurar el
        cumplimiento de esta política. En casos donde se identifique o reporte
        contenido ilegal o inapropiado, More Readings se reserva el derecho de
        tomar las medidas adecuadas. Esto puede incluir la eliminación del
        contenido, la suspensión o terminación de la cuenta del usuario, y/o
        reportar el asunto a las autoridades pertinentes. More Readings además
        se reserva el derecho de rechazar el servicio a cualquier usuario a su
        sola discreción, sin previo aviso, por cualquier o ninguna razón.
      </p>

      <p className={styles.p}>
        Cumplimiento Regional: Se recuerda a los usuarios que los estándares y
        regulaciones legales respecto a materiales fotográficos pueden variar
        significativamente entre diferentes regiones y jurisdicciones. Es
        responsabilidad de cada usuario estar al tanto y cumplir con las leyes
        que rigen su región o cualquier región en la que accedan o usen More
        Readings.
      </p>

      <p className={styles.p}>
        Reporte de Violaciones: More Readings alienta a la comunidad a reportar
        cualquier instancia de contenido ilegal o inapropiado a
        hello@support.morereadings.me. More Readings tomará las medidas
        adecuadas en respuesta a tales reportes de acuerdo con este término y
        nuestros Términos de Servicio generales.
      </p>

      <h2 className={styles.h2}>
        Descargo de responsabilidad de contenido generado por IA
      </h2>

      <p className={styles.p}>
        Asistencia de IA: More Readings emplea tecnologías avanzadas de
        inteligencia artificial (IA) para generar texto, especulaciones,
        métricas e imágenes para los usuarios. Esta funcionalidad de IA está
        diseñada para mejorar la experiencia del usuario proporcionando
        contenido creativo, atractivo y entretenido.
      </p>

      <p className={styles.p}>
        Propósito del Contenido: El contenido generado por IA a través de More
        Readings está destinado únicamente para fines de entretenimiento. Los
        usuarios no deben confiar en este contenido para obtener precisión,
        integridad o utilidad en la toma de decisiones. More Readings no hace
        representaciones ni garantías de ningún tipo, expresas o implícitas,
        sobre la fiabilidad, precisión, adecuación o disponibilidad de cualquier
        contenido generado por IA.
      </p>

      <p className={styles.p}>
        Sin Garantía de Validez: Aunque More Readings se esfuerza por
        proporcionar una experiencia novedosa y atractiva, reconoce que el
        contenido generado por IA puede ocasionalmente producir resultados
        inesperados, especulativos o inexactos. Los usuarios acuerdan usar More
        Readings entendiendo que todo el contenido generado por IA se
        proporciona en una base de “tal cual” y “según disponibilidad”, sin
        garantía de ningún tipo.
      </p>

      <p className={styles.p}>
        Limitación de Responsabilidad: More Readings y sus desarrolladores
        renuncian a toda responsabilidad por cualquier pérdida, daño, lesión o
        gasto de cualquier tipo que surja del uso o la confianza en, directa o
        indirectamente, el contenido generado por IA proporcionado por la
        aplicación. Los usuarios reconocen que la interpretación y el uso de
        cualquier contenido generado por IA son bajo su propio riesgo.
      </p>

      <p className={styles.p}>
        No Asesoramiento Financiero: More Readings no proporciona asesoramiento
        financiero, y cualquier opinión relacionada con finanzas generada por IA
        no debe tomarse en serio. Los usuarios no deben basar ninguna decisión
        financiera en contenido generado por IA.
      </p>

      <p className={styles.p}>
        Discreción del Usuario Recomendado: Se aconseja a los usuarios ejercer
        discreción y juicio crítico al interactuar con contenido generado por
        IA. More Readings anima a los usuarios a disfrutar de las experiencias
        innovadoras y entretenidas ofrecidas por la IA con la comprensión de sus
        limitaciones y naturaleza especulativa.
      </p>

      <p className={styles.p}>
        Reporte de Inexactitudes: More Readings valora los comentarios de los
        usuarios y se esfuerza por mejorar la precisión y relevancia de la IA.
        Se anima a los usuarios a reportar cualquier preocupación o inexactitud
        en el contenido generado por IA a través de
        hello@support.morereadings.me para que More Readings pueda continuar
        mejorando la experiencia del usuario.
      </p>

      <h2 className={styles.h2}>Compras</h2>

      <p className={styles.p}>
        Ofrecemos productos y servicios para su compra a través de la Apple App
        Store, Google Play, Shopify y otros servicios externos autorizados por
        More Readings (cada uno, un “servicio externo”). Cualquier compra
        realizada en un servicio externo será procesada a través de tu cuenta
        (“Cuenta de Servicio Externo”) en ese servicio externo de acuerdo con
        los términos divulgados en el momento de la compra y los términos
        generales aplicables a tu Cuenta de Servicio Externo. Algunos servicios
        externos pueden cobrarte impuestos sobre las ventas, dependiendo de
        dónde vivas, lo cual puede cambiar en ocasiones.
      </p>

      <p className={styles.p}>
        Cualquier oferta para cualquier producto o servicio realizada en
        nuestros Servicios es nula donde esté prohibida.
      </p>

      <h2 className={styles.h2}>TARIFAS DENTRO DE LA APP</h2>

      <p className={styles.p}>
        La Compañía puede, ahora o en el futuro, cobrar tarifas de suscripción o
        servicio por el uso de los Servicios o ciertas características de los
        Servicios. Aceptas pagar a la Compañía todas las tarifas por productos o
        servicios comprados en o a través de los Servicios bajo tu Cuenta a los
        precios y tarifas vigentes (incluyendo cualquier impuesto aplicable), y
        cumplir con los términos de pago de la Compañía en vigor en el momento
        de dicha compra. La Compañía puede cambiar las tarifas por el uso de los
        Servicios o cualquier característica de los Servicios o añadir nuevas
        tarifas o cargos, en cualquier momento. Para cualquier cambio en las
        tarifas por productos o servicios comprados bajo tu Cuenta, la Compañía
        te enviará una notificación de dicho cambio antes de que dicho cambio
        entre en efecto (mediante un mensaje en o a través de los Servicios).
      </p>

      <ul className={styles.ul}>
        <li className={styles.li}>
          Para solicitar un reembolso de More Readings, por favor contacta a
          hello@support.morereadings.me
        </li>
      </ul>

      <h2 className={styles.h2}>SUSCRIPCIONES</h2>

      <p className={styles.p}>
        Puedes tener acceso a un período de prueba gratuito para una
        suscripción. Si te suscribes a una prueba gratuita y no cancelas antes
        de que finalice, tu prueba puede convertirse en una suscripción pagada y
        el Método de Pago que configuraste en tu Cuenta de Servicio Externo será
        automáticamente cobrado. Continuarás siendo cobrado hasta que canceles
        la suscripción a través de los métodos de Cuenta de Servicio Externo
        compartidos anteriormente en la sección de Cancelaciones y reembolsos.
        Si te has suscrito a una prueba gratuita en la suscripción a través de
        la Apple Store o Google Play Store previamente, no serás elegible para
        otra prueba gratuita; en este caso, te suscribirás automáticamente a una
        suscripción pagada y serás cobrado como se describe en estos términos.
      </p>
      <p className={styles.p}>
        Si compras una suscripción, se renovará automáticamente al precio que
        aceptaste al suscribirte hasta que la canceles, de acuerdo con los
        términos divulgados en el momento de la compra y los términos generales
        aplicables a tu Cuenta de Servicio Externo.
      </p>

      <p className={styles.p}>
        Para cancelar tu suscripción, debes iniciar sesión en tu Cuenta de
        Servicio Externo y seguir las instrucciones relevantes.
      </p>

      <ul className={styles.ul}>
        <li className={styles.li}>
          Para cancelar tu suscripción con More Readings, por favor contacta a
          hello@support.morereadings.me
        </li>
      </ul>

      <p className={styles.p}>
        Si cancelas tu suscripción, continuarás teniendo acceso a los beneficios
        de tu suscripción hasta el final de tu período de suscripción, momento
        en el cual expirará. Debido a que nuestros Servicios pueden ser usados
        sin una suscripción, cancelar tu suscripción no elimina tu perfil de
        nuestros Servicios. Si deseas eliminar completamente tu perfil, debes
        hacerlo como se describe en nuestra política de privacidad.
      </p>

      <h2 className={styles.h2}>TIENDAS EN LÍNEA</h2>

      <p className={styles.p}>
        Como parte de los Servicios, la Compañía puede, ahora o en el futuro,
        hacer disponibles ciertos productos o servicios exclusivamente en línea
        a través de los Sitios Web (las “Tiendas en Línea”). Estos productos o
        servicios pueden tener cantidades limitadas y están sujetos a devolución
        o intercambio solo según nuestras políticas descritas en nuestras
        Preguntas Frecuentes.
      </p>

      <p className={styles.p}>
        Nos esforzamos por asegurar que nuestros precios en línea e información
        de productos sean precisos y completos. Sin embargo, de vez en cuando,
        ocurren errores, y nos reservamos el derecho de corregir cualquier
        error, inexactitud u omisión, y de cambiar o actualizar la información o
        cancelar pedidos si cualquier información en el Servicio es inexacta en
        cualquier momento sin previo aviso (incluyendo después de que hayas
        enviado tu pedido).
      </p>
      <p className={styles.p}>
        Nos reservamos el derecho de limitar las cantidades de, cambiar precios
        o descripciones de, o descontinuar cualquier producto o servicio que
        ofrezcamos en cualquier momento, sin aviso. No tenemos obligación de
        actualizar, enmendar o aclarar la información en el Servicio, incluyendo
        sin limitación, información de precios, excepto cuando lo requiera la
        ley. No garantizamos que la calidad de cualquier producto, servicio,
        información u otro contenido (incluyendo el de terceros) comprado u
        obtenido por ti cumplirá con tus expectativas.
      </p>
      <p className={styles.p}>
        Nos reservamos el derecho de limitar, bloquear o cancelar cualquier
        pedido que realices con nosotros. Podemos ejercer este derecho caso por
        caso y a nuestra sola discreción. Si hacemos un cambio o cancelamos un
        pedido, podemos intentar notificarte contactando el correo electrónico,
        la dirección de facturación y/o el número de teléfono proporcionados en
        el momento en que se realizó el pedido.
      </p>

      <h2 className={styles.h2}>
        Comentarios de usuarios, retroalimentación y otras presentaciones
      </h2>

      <p className={styles.p}>
        Si nos envías ideas, sugerencias, propuestas, retroalimentación, planes
        u otros materiales, ya sea en línea, por correo electrónico, por correo
        postal u otra manera (“Comentarios”), aceptas que podemos, en cualquier
        momento, sin restricción, editar, copiar, publicar, distribuir, traducir
        y usar dichos Comentarios. No estamos y no estaremos obligados a
        mantener ningún comentario en confidencialidad; a pagar compensación por
        cualquier comentario; o a responder a cualquier comentario.
      </p>
      <p className={styles.p}>
        Aceptas que tus Comentarios no violarán ningún derecho de terceros,
        incluyendo derechos de autor, marcas comerciales, privacidad u otro
        derecho personal o de propiedad. Además, aceptas que tus Comentarios no
        contendrán material difamatorio ni de otra manera ilegal, abusivo u
        obsceno, ni contendrán ningún virus informático u otro malware que
        pudiera afectar de alguna manera la operación de los Servicios. No
        puedes usar una dirección de correo electrónico falsa, fingir ser otra
        persona o de otra manera engañarnos a nosotros o a terceros sobre el
        origen de cualquier comentario. Eres el único responsable de cualquier
        Comentario que hagas y de su precisión. No nos hacemos responsables y no
        asumimos ninguna responsabilidad por cualquier Comentario que nos
        envíes.
      </p>

      <h2 className={styles.h2}>Terminación o suspensión de una cuenta</h2>

      <p className={styles.p}>
        Podemos, por cualquier razón y a nuestra sola discreción, suspender,
        limitar o terminar tu Cuenta y tu acceso o uso de los Servicios, sin
        previo aviso ni responsabilidad hacia ti. Puedes terminar tu Cuenta y
        dejar de usar los Servicios en cualquier momento notificándonos en
        hello@support.morereadings.me y proporcionando la información suficiente
        para que verifiquemos tu identidad. Tras la terminación de tu Cuenta,
        debes cesar todo uso de los Servicios. La terminación de tu Cuenta no
        afectará ninguno de nuestros derechos ni tus obligaciones que surjan
        bajo estos Términos antes de dicha terminación. Las disposiciones de
        estos Términos que, por su naturaleza, deberían sobrevivir a la
        terminación de tu Cuenta continuarán en pleno vigor y efecto como si la
        terminación no se hubiera producido.
      </p>

      <h2 className={styles.h2}>
        Procedimiento para alegar infracción de derechos de autor
      </h2>

      <p className={styles.p}>
        Conforme a la Ley de Derechos de Autor del Milenio Digital de 1998 (la
        “DMCA”), los propietarios de derechos de autor que aleguen la infracción
        de sus derechos de autor en el Sitio Web y/o las Apps pueden presentar
        una notificación de infracción con la Compañía contactando al agente
        designado utilizando la información proporcionada a continuación:
      </p>
      <ul className={styles.ul}>
        <li className={styles.li}>
          Correo Electrónico: hello@support.morereadings.me
        </li>
      </ul>

      <p className={styles.p}>
        Presentar una notificación de infracción con el gobierno federal de los
        Estados Unidos requiere cumplir con los requisitos especificados en el
        Título II de la DMCA. El texto de este estatuto se puede encontrar en el
        sitio web de la Oficina de Derechos de Autor de EE.UU., copyright.gov.
      </p>

      <h2 className={styles.h2}>Confianza en la información publicada</h2>

      <p className={styles.p}>
        La información presentada a través de los Servicios está disponible
        únicamente para propósitos de información general. No garantizamos la
        precisión, completitud o utilidad de esta información. Cualquier
        confianza que deposites en dicha información es estrictamente bajo tu
        propio riesgo. Renunciamos a toda responsabilidad y obligación que surja
        de cualquier confianza que tú o cualquier otro usuario de los Servicios
        o cualquier persona que pueda estar informada de la información
        presentada a través de los Servicios pueda tener en dichos materiales.
      </p>

      <p className={styles.p}>
        Los Servicios pueden incluir contenido proporcionado por terceros,
        incluyendo materiales proporcionados por otros usuarios, blogueros y
        licenciantes de terceros, sindicadores, agregadores y/o servicios de
        reportes. Todas las declaraciones y/o opiniones expresadas en estos
        materiales, y todos los artículos y respuestas a preguntas y otro
        contenido, excepto el contenido proporcionado por More Readings, son
        únicamente las opiniones y la responsabilidad de la persona o entidad
        que proporciona dichos materiales. Estos materiales no reflejan
        necesariamente la opinión de More Readings. No somos responsables, ni
        somos responsables ante ti o cualquier tercero, por el contenido o la
        precisión de cualquier material proporcionado por terceros.
      </p>

      <h2 className={styles.h2}>Privacidad</h2>

      <p className={styles.p}>
        Por favor, revisa nuestra Política de Privacidad para detalles sobre
        cómo recopilamos, usamos, almacenamos y divulgamos información en
        conexión con los Servicios. Puedes encontrar nuestra Política de
        Privacidad aquí:{" "}
        <a href="https://morereadings.com/policies">
          morereadings.com/policies
        </a>
        . Al usar los Servicios, consientes todas las acciones que realizamos
        con respecto a tu información en cumplimiento con nuestra Política de
        Privacidad.
      </p>

      <h2 className={styles.h2}>
        Enlaces al sitio web y características de redes sociales
      </h2>

      <p className={styles.p}>
        Solo puedes enlazar a los Servicios de una manera que sea justa y legal
        y que no busque dañar la reputación de More Readings o aprovecharse de
        ella. No debes
      </p>

      <ul className={styles.ul}>
        <li className={styles.li}>
          Establecer un enlace de manera que sugiera cualquier forma de
          asociación, aprobación o respaldo de nuestra parte sin nuestro
          consentimiento previo por escrito.
        </li>
        <li className={styles.li}>
          Hacer que el Sitio Web o partes de él se muestren, o parezcan ser
          mostradas por cualquier otro sitio web excepto los tuyos o según lo
          permitido por escrito por More Readings. Ejemplos de la visualización
          del Sitio Web incluyen encuadrar, enlazado profundo o enlazado en
          línea.
        </li>
        <li className={styles.li}>
          De otra manera, tomar cualquier acción con respecto a los materiales
          en los Servicios que sea inconsistente con cualquier otra disposición
          de estos Términos.
        </li>
      </ul>

      <h2 className={styles.h2}>Enlaces desde los Servicios</h2>

      <p className={styles.p}>
        Si los Servicios contienen enlaces a otros sitios web proporcionados por
        terceros (“Sitios Enlazados”), estos enlaces se proporcionan únicamente
        por tu conveniencia. Nuestra inclusión de tales enlaces no implica
        nuestro respaldo a ningún Sitio Enlazado ni a ninguna asociación con sus
        propietarios u operadores, y expresamente renunciamos a la
        responsabilidad de dichos Sitios Enlazados. No tenemos control sobre el
        contenido de los Sitios Enlazados y no aceptamos ninguna responsabilidad
        por ellos. Si decides acceder a cualquier Sitio Enlazado, lo haces
        completamente bajo tu propio riesgo y sujeto a los términos y
        condiciones de uso de dichos Sitios Enlazados. Cualquier interacción que
        tengas con los Sitios Enlazados es entre tú y dicho sitio, y aceptas que
        no somos responsables por cualquier daño o pérdida que puedas sufrir
        como resultado de cualquier interacción con los Sitios Enlazados o
        cualquier reclamo que puedas tener contra los Sitios Enlazados.
      </p>

      <h2 className={styles.h2}>Limitaciones geográficas</h2>

      <p className={styles.p}>
        No afirmamos que los Servicios sean accesibles o apropiados fuera de los
        Estados Unidos. El acceso a los Servicios puede no ser legal para
        ciertas personas o en ciertos países. Si accedes a los Servicios desde
        fuera de los Estados Unidos, lo haces por tu propio riesgo e iniciativa
        y eres responsable de cumplir con las leyes locales.
      </p>

      <h2 className={styles.h2}>Descargo de garantías</h2>

      <p className={styles.p}>
        NO SEREMOS RESPONSABLES POR NINGUNA PÉRDIDA O DAÑO CAUSADO POR UN ATAQUE
        DE DENEGACIÓN DE SERVICIO DISTRIBUIDO, VIRUS U OTRO MATERIAL
        TECNOLÓGICAMENTE PERJUDICIAL QUE PUEDA INFECTAR TU EQUIPO INFORMÁTICO,
        APPS INFORMÁTICAS, DATOS U OTRO MATERIAL PROPIETARIO DEBIDO A TU USO DE
        LOS SERVICIOS O ELEMENTOS OBTENIDOS A TRAVÉS DE LOS SERVICIOS O A TU
        DESCARGA DE CUALQUIER MATERIAL PUBLICADO EN EL MISMO, O EN CUALQUIER
        SITIO WEB ENLAZADO AL MISMO.
      </p>

      <p className={styles.p}>
        HASTA EL MÁXIMO EXTENSIÓN PERMITIDA POR LA LEY APLICABLE, TÚ ACEPTAS
        EXPRESAMENTE QUE TU USO DE LOS SERVICIOS Y TODA LA INFORMACIÓN,
        PRODUCTOS Y OTRO CONTENIDO (INCLUYENDO EL DE TERCEROS) INCLUIDO EN O
        ACCESIBLE DESDE LOS SERVICIOS ES BAJO TU PROPIO RIESGO. LOS SERVICIOS SE
        PROPORCIONAN EN UNA BASE DE “TAL COMO ESTÁ” Y “SEGÚN DISPONIBILIDAD”,
        SIN NINGUNA GARANTÍA DE NINGÚN TIPO, YA SEA EXPRESA O IMPLÍCITA. NI LA
        COMPAÑÍA NI NINGUNA PERSONA ASOCIADA CON LA COMPAÑÍA HACEN NINGUNA
        GARANTÍA O REPRESENTACIÓN CON RESPECTO A LA COMPLETITUD,
        CONFIDENCIALIDAD, SEGURIDAD E INTEGRIDAD DE TU INFORMACIÓN PERSONAL
        CONTRA DESTRUCCIÓN ACCIDENTAL O ILÍCITA, PÉRDIDA, ALTERACIÓN,
        DIVULGACIÓN O ACCESO NO AUTORIZADO. NO PODEMOS GARANTIZAR LA SEGURIDAD
        DE LA INFORMACIÓN TRANSMITIDA A NOSOTROS A TRAVÉS DE INTERNET.
      </p>

      <p className={styles.p}>
        ALMACENAMIENTO
        <br />
        La Información Personal que nos proporcionas generalmente se almacena en
        servidores mantenidos por Heroku. Estos servidores están ubicados en los
        Estados Unidos. Si te encuentras en otra jurisdicción, debes ser
        consciente de que una vez que tu Información Personal se envía a través
        de nuestros Servicios, será transferida a nuestros servidores en los
        Estados Unidos y, por lo tanto, estará regida por las leyes de
        protección de datos locales, que pueden variar de estado a estado.
      </p>

      <p className={styles.p}>
        RETENCIÓN
        <br />
        Retendremos tu información personal durante el período necesario para
        cumplir con los propósitos descritos en esta Política de Privacidad, a
        menos que un período de retención más largo sea requerido o permitido
        por ley.
      </p>

      <h2 className={styles.h2}>Usuarios internacionales</h2>

      <p className={styles.p}>
        Tacit Social LLC tiene su sede en Canadá, y almacenamos información
        personal en servidores ubicados en los Estados Unidos, que pueden no
        tener las mismas leyes de protección de datos que el país en el que
        resides. Si estás usando nuestros Servicios desde fuera de los Estados
        Unidos o Canadá, tu información puede ser transferida, almacenada y
        procesada en los Estados Unidos y Canadá. Al usar nuestros Servicios,
        consientes dichas transferencias de esta información.
      </p>

      <p className={styles.p}>
        Podemos actuar como un “controlador” según lo definido bajo la ley de
        protección de datos aplicable cuando procesamos tu información desde el
        Sitio Web, Apps y Servicios. En general, nos basamos en nuestros
        intereses legítimos al procesar esta información. Estos intereses
        legítimos incluyen proveer nuestros Servicios, operar nuestro Sitio Web
        y Apps, y nuestros otros propósitos comerciales. También podemos
        procesar tu información personal para cumplir con contratos que podamos
        tener contigo. Si el consentimiento es la base legal, obtendremos tu
        consentimiento y te informaremos cómo puedes retirarlo.
      </p>

      <h2 className={styles.h2}>Tus opciones y derechos</h2>

      <p className={styles.p}>
        Si deseas actualizar tu información de contacto o preferencias, eliminar
        tu información de nuestros registros, o si tienes comentarios o
        preguntas sobre esta Política de Privacidad, por favor contáctanos en
        hello@support.morereadings.me.
      </p>

      <p className={styles.p}>
        No estás obligado a proporcionarnos tu información. Si decides no
        hacerlo, podemos no ser capaces de responder a tus solicitudes o
        preguntas, y sin cierta información como tu fecha de nacimiento, lugar
        de nacimiento y hora de nacimiento, algunas o todas las características
        de los Servicios pueden no estar disponibles o funcionar en absoluto.
      </p>

      <p className={styles.p}>
        Puedes optar por no recibir comunicaciones por correo electrónico en
        cualquier momento haciendo clic en el enlace de cancelación de
        suscripción dentro de cualquier mensaje que recibas. Puedes controlar
        cómo responde tu navegador a las cookies ajustando la configuración de
        privacidad y seguridad de tu navegador web. Puedes configurar tu
        navegador para no aceptar cookies, pero esto puede limitar ciertas
        funcionalidades en los Sitios Web. Cómo optar por no participar depende
        del tipo de navegador y/o dispositivo que estés usando. Si deseas optar
        por no participar en cookies analíticas, puedes optar por no participar
        en Google Analytics. Si resides o te encuentras en ciertas
        jurisdicciones, puedes tener derechos y protecciones en relación con tu
        información bajo las leyes aplicables. Estos derechos pueden incluir el
        derecho a acceder, eliminar, corregir, mover (o portar), oponerte al
        procesamiento y limitar el procesamiento de tu información así como el
        derecho a presentar una queja ante un regulador. Estos derechos no son
        absolutos, están sujetos a excepciones, y podemos estar obligados o
        permitidos por ley a rechazar tu solicitud. En la medida en que lo
        requieran y permitan las leyes aplicables, procesaremos tu solicitud, y
        si es aplicable, podemos pedirte información adicional para verificar tu
        identidad. Si deseas ejercer tus derechos o tienes preguntas sobre la
        manera en que procesamos tu información personal, por favor contáctanos
        en hello@support.morereadings.me
      </p>

      <h2 className={styles.h2}>Cambios a esta política de privacidad</h2>

      <p className={styles.p}>
        Podemos actualizar o modificar esta Política de Privacidad de vez en
        cuando y te notificaremos que se han hecho cambios indicando en la
        Política de Privacidad la fecha en que se actualizó por última vez en la
        parte superior de esta Política de Privacidad, publicando de manera
        prominente un anuncio de los cambios en nuestros Servicios, o enviándote
        una comunicación por escrito utilizando la información de contacto que
        nos proporcionaste antes de que la nueva política entre en vigor. Los
        usuarios están sujetos a cualquier cambio en la Política de Privacidad
        si utilizan nuestros Sitios Web, Apps y Servicios después de que se haya
        comunicado el aviso de dichos cambios. Te recomendamos revisar esta
        Política de Privacidad regularmente para asegurarte de estar
        familiarizado con las prácticas actuales de Tacit Social LLC.
      </p>
    </div>
  );
};
