import http from "app/axios/axiosInstance";
import { useQuery } from "app/utils/useQuery";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

export const useProfileCreationInvite = () => {
  const query = useQuery();
  const token = query.get("token");
  const [isTokenValid, setIsTokenValid] = useState(false);
  const [inviterName, setInviterName] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [screenIndex, setScreenIndex] = useState(0);
  const [relationshipProfile, setRelationshipProfile] = useState({
    name: "",
    relationship: "",
    gender: "",
    birthDate: null,
    birthplaceName: null,
    birthplaceLatitude: null,
    birthplaceLongitude: null,
  } as any);
  const [horoscopeReport, setHoroscopeReport] = useState(null);
  const { t } = useTranslation();

  const checkIfTokenIsValid = async () => {
    if (!token) {
      // show conditional screen
      return console.log("No token found");
    }
    try {
      const { data } = await http.post(
        `/v1/relationship_profile_invites/validate_token`,
        {
          token,
        }
      );
      if (data.status === "complete") {
        const horoscopeReport = localStorage.getItem("horoscopeReport");
        if (horoscopeReport) {
          setHoroscopeReport(JSON.parse(horoscopeReport));
          setIsTokenValid(true);
        } else {
          setIsTokenValid(false);
        }
        setIsLoading(false);
        return;
      }
      setInviterName(data.inviter);
      toast(t("inviter_message", { inviter: data.inviter }));
      setIsTokenValid(true);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  const handleProfileCreation = async () => {
    try {
      setIsLoading(true);
      const { data } = await http.post(
        `/v1/relationship_profile_invites/create_relationship_profile`,
        {
          relationshipProfile,
          token,
        }
      );
      setHoroscopeReport(data.horoscopeReport);
      localStorage.setItem(
        "horoscopeReport",
        JSON.stringify(data.horoscopeReport)
      );
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsTokenValid(false);
      // Redirect to the invalid token page
    }
  };

  useEffect(() => {
    checkIfTokenIsValid();
  }, []);

  return {
    isTokenValid,
    handleProfileCreation,
    inviterName,
    screenIndex,
    setScreenIndex,
    relationshipProfile,
    setRelationshipProfile,
    isLoading,
    horoscopeReport,
  };
};
