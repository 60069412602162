import * as React from "react";
import styles from "./appleBtn.module.css";
import sharedStyles from "app/components/buttons/button.module.css";
import { Link } from "react-router-dom";
import classNames from "classnames";
import AppStoreSvg from "app/assets/images/appStore.svg";
import { usePostHog } from "posthog-js/react";

export interface AppleBtnProps {}

export const AppleBtn: React.FC<AppleBtnProps> = (props) => {
  const {} = props;
  const posthog = usePostHog();

  const handleClick = () => {
    posthog.capture("IOS Appstore Button Clicked", {
      platform: "web",
      destination: "https://apps.apple.com/app/id6479682201?platform=iphone",
    });
  };
  return (
    <Link
      to="https://apps.apple.com/app/id6479682201?platform=iphone"
      className={classNames(
        sharedStyles.sheenFadeShadow,
        "duration-300 hover:scale-105 w-fit"
      )}
      target="_blank"
      onClick={handleClick}
    >
      <img src={AppStoreSvg} alt="App Store" />
    </Link>
  );
};
