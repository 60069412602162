import { useMediaQuery } from "react-responsive";

const breakpoints = {
  xxxs: "320px",
  xxs: "375px",
  xs: "390px",
  sm: "640px",
  md: "768px",
  lg: "1024px",
  xl: "1280px",
  "2xl": "1536px",
};

type BreakpointKey = keyof typeof breakpoints;

export function useBreakpoint<K extends BreakpointKey>(breakpointKey: K) {
  const breakpointValue = breakpoints[breakpointKey];
  const breakpointNum = Number(breakpointValue.replace(/[^0-9]/g, ""));

  const isBelow = useMediaQuery({ query: `(max-width: ${breakpointValue})` });
  const isAbove = useMediaQuery({
    query: `(min-width: ${breakpointNum + 1}px)`,
  });
  const isAt = useMediaQuery({
    query: `(min-width: ${breakpointValue}) and (max-width: ${breakpointValue})`,
  });

  const capitalizedKey =
    breakpointKey[0].toUpperCase() + breakpointKey.substring(1);

  type KeyAbove = `isAbove${Capitalize<K>}`;
  type KeyBelow = `isBelow${Capitalize<K>}`;
  type KeyAt = `isAt${Capitalize<K>}`;

  return {
    [breakpointKey]: Number(String(breakpointValue).replace(/[^0-9]/g, "")),
    [`isAbove${capitalizedKey}`]: isAbove,
    [`isBelow${capitalizedKey}`]: isBelow,
    [`isAt${capitalizedKey}`]: isAt,
  } as Record<typeof breakpointKey, number> &
    Record<KeyAbove | KeyBelow | KeyAt, boolean>;
}
