import * as React from "react";
import { useQuiz2 } from "./useQuiz2";
import { RelationshipStatusTypes } from "app/quiz/useQuiz";
import classNames from "classnames";
import quizStyles from "app/quiz/quiz.module.css";
import { Button } from "@nextui-org/react";
// Lazy loading assets and components with named exports
import YellowLeftArrow from "app/assets/icons/yellowLeftArrow.svg";
import MoreReadingsLogoSvg from "app/assets/images/moreReadingsLogo.svg";
import StarPng from "app/assets/images/star.png";
import MoonPng from "app/assets/images/moon.png";
import RomComPng from "app/assets/images/romCom.png";
import { compact } from "lodash";
import { useBreakpoint } from "app/utils/useBreakpoint";
import { startTransition } from "react";
import { Loading } from "app/components/loading/loading";
import { useTranslation } from "react-i18next";

// Lazy loading screens with named exports handling
const GenderScreen = React.lazy(() =>
  import("./screens/genderScreen/genderScreen").then((module) => ({
    default: module.GenderScreen,
  }))
);
const DateOfBirthScreen = React.lazy(() =>
  import("./screens/dateOfBirthScreen/dateOfBirthScreen").then((module) => ({
    default: module.DateOfBirthScreen,
  }))
);
const SunSignScreen = React.lazy(() =>
  import("./screens/sunSignScreen/sunSignScreen").then((module) => ({
    default: module.SunSignScreen,
  }))
);
const EducationScreen = React.lazy(() =>
  import("app/quiz/components/educationScreen").then((module) => ({
    default: module.EducationScreen,
  }))
);
const RandomIconsScreen = React.lazy(() =>
  import("app/quiz/components/randonIconsScreen").then((module) => ({
    default: module.RandomIconsScreen,
  }))
);
const TarotCardScreen = React.lazy(() =>
  import("./screens/tarotCardScreen/tarotCardScreen").then((module) => ({
    default: module.TarotCardScreen,
  }))
);
const TarotCardScreen2 = React.lazy(() =>
  import("./screens/tarotCardScreen/tarotCardScreen2").then((module) => ({
    default: module.TarotCardScreen2,
  }))
);
const EmailScreen = React.lazy(() =>
  import("./screens/emailScreen/emailScreen").then((module) => ({
    default: module.EmailScreen,
  }))
);
const WeHaveHelped = React.lazy(() =>
  import("./screens/weHaveHelped/weHaveHelped").then((module) => ({
    default: module.WeHaveHelped,
  }))
);
const ChoosePriceScreen = React.lazy(() =>
  import("./screens/choosePriceScreen/choosePriceScreen").then((module) => ({
    default: module.ChoosePriceScreen,
  }))
);
const ConversionScreen = React.lazy(() =>
  import("./screens/conversionScreen/conversionScreen").then((module) => ({
    default: module.ConversionScreen,
  }))
);
const ListScreen = React.lazy(() =>
  import("app/quiz/components/listScreen").then((module) => ({
    default: module.ListScreen,
  }))
);
const DeviceScreen = React.lazy(() =>
  import("./screens/deviceScreen/deviceScreen").then((module) => ({
    default: module.DeviceScreen,
  }))
);
const MoonProgress = React.lazy(() =>
  import("./component/moonProgress").then((module) => ({
    default: module.MoonProgress,
  }))
);
const BirthPlaceScreen = React.lazy(() =>
  import("./screens/birthPlaceScreen/birthPlaceScreen").then((module) => ({
    default: module.BirthPlaceScreen,
  }))
);
const BirthTimeScreen = React.lazy(() =>
  import("./screens/birthTimeScreen/birthTimeScreen").then((module) => ({
    default: module.BirthTimeScreen,
  }))
);
const LoadingScreen = React.lazy(() =>
  import("./screens/loadingScreen/loadingScreen").then((module) => ({
    default: module.LoadingScreen,
  }))
);
const LoveSignsScreens = React.lazy(() =>
  import("./screens/loveSignsScreen/loveSignsScreen").then((module) => ({
    default: module.LoveSignsScreens,
  }))
);

export interface Quiz2Props {}

const getScreen = ({
  currentScreenIndex,
  appendAnswerToParams,
  setCurrentScreenIndex,
  hideDeviceScreen,
  t,
}: any) => {
  const screens = [
    <TarotCardScreen
      currentScreenIndex={currentScreenIndex}
      appendAnswerToParams={appendAnswerToParams}
    />,
    <GenderScreen
      currentScreenIndex={currentScreenIndex}
      appendAnswerToParams={appendAnswerToParams}
    />,
    <ListScreen
      title={""}
      description={t("relationShipStatus.label")}
      questions={[
        {
          leadingEmoji: "❤️",
          title: t("relationShipStatus.single"),
          value: 0,
          onQuestionClick: (value: RelationshipStatusTypes) =>
            appendAnswerToParams({
              key: "relationshipStatus",
              value,
              nextPageIndex: currentScreenIndex + 1,
            }),
        },
        {
          leadingEmoji: "💕",
          title: t("relationShipStatus.in_relationship"),
          value: 1,
          onQuestionClick: (value: RelationshipStatusTypes) =>
            appendAnswerToParams({
              key: "relationshipStatus",
              value,
              nextPageIndex: currentScreenIndex + 1,
            }),
        },
        {
          leadingEmoji: "💍",
          title: t("relationShipStatus.married"),
          value: 2,
          onQuestionClick: (value: RelationshipStatusTypes) =>
            appendAnswerToParams({
              key: "relationshipStatus",
              value,
              nextPageIndex: currentScreenIndex + 1,
            }),
        },
        {
          leadingEmoji: "💔",
          title: t("relationShipStatus.complicated"),
          value: 3,
          onQuestionClick: (value: RelationshipStatusTypes) =>
            appendAnswerToParams({
              key: "relationshipStatus",
              value,
              nextPageIndex: currentScreenIndex + 1,
            }),
        },
        {
          leadingEmoji: "🤔",
          title: t("relationShipStatus.unsure"),
          value: 4,
          onQuestionClick: (value: RelationshipStatusTypes) =>
            appendAnswerToParams({
              key: "relationshipStatus",
              value,
              nextPageIndex: currentScreenIndex + 1,
            }),
        },
      ]}
    />,
    <ListScreen
      title={""}
      description={t("loveLife.label")}
      questions={[
        {
          title: t("loveLife.excited"),
          value: 0,
          onQuestionClick: (value: number) =>
            appendAnswerToParams({
              key: "loveLife",
              value,
              nextPageIndex: currentScreenIndex + 1,
            }),
        },
        {
          title: t("loveLife.more_love"),
          value: 1,
          onQuestionClick: (value: number) =>
            appendAnswerToParams({
              key: "loveLife",
              value,
              nextPageIndex: currentScreenIndex + 1,
            }),
        },
        {
          title: t("loveLife.meet_someone"),
          value: 2,
          onQuestionClick: (value: number) =>
            appendAnswerToParams({
              key: "loveLife",
              value,
              nextPageIndex: currentScreenIndex + 1,
            }),
        },
        {
          title: t("loveLife.single"),
          value: 3,
          onQuestionClick: (value: number) =>
            appendAnswerToParams({
              key: "loveLife",
              value,
              nextPageIndex: currentScreenIndex + 1,
            }),
        },
        {
          title: t("loveLife.not_sure"),
          value: 4,
          onQuestionClick: (value: number) =>
            appendAnswerToParams({
              key: "loveLife",
              value,
              nextPageIndex: currentScreenIndex + 1,
            }),
        },
      ]}
    />,
    <ListScreen
      title={""}
      description={t("astrologyLove.label")}
      questions={[
        {
          title: t("astrologyLove.believe"),
          value: 0,
          onQuestionClick: (value: number) =>
            appendAnswerToParams({
              key: "astrologyBelief",
              value,
              nextPageIndex: currentScreenIndex + 1,
            }),
        },
        {
          title: t("astrologyLove.its_fun"),
          value: 1,
          onQuestionClick: (value: number) =>
            appendAnswerToParams({
              key: "astrologyBelief",
              value,
              nextPageIndex: currentScreenIndex + 1,
            }),
        },
        {
          title: t("astrologyLove.it_helps"),
          value: 2,
          onQuestionClick: (value: number) =>
            appendAnswerToParams({
              key: "astrologyBelief",
              value,
              nextPageIndex: currentScreenIndex + 1,
            }),
        },
        {
          title: t("astrologyLove.im_curious"),
          value: 3,
          onQuestionClick: (value: number) =>
            appendAnswerToParams({
              key: "astrologyBelief",
              value,
              nextPageIndex: currentScreenIndex + 1,
            }),
        },
        {
          title: t("astrologyLove.not_sure"),
          value: 4,
          onQuestionClick: (value: number) =>
            appendAnswerToParams({
              key: "astrologyBelief",
              value,
              nextPageIndex: currentScreenIndex + 1,
            }),
        },
      ]}
    />,
    <EducationScreen
      img={StarPng}
      description={t("quiz.sun_sign")}
      currentScreenIndex={currentScreenIndex}
      appendAnswerToParams={appendAnswerToParams}
    />,
    <DateOfBirthScreen
      currentScreenIndex={currentScreenIndex}
      appendAnswerToParams={appendAnswerToParams}
    />,
    <SunSignScreen
      currentScreenIndex={currentScreenIndex}
      appendAnswerToParams={appendAnswerToParams}
      setCurrentScreenIndex={setCurrentScreenIndex}
    />,
    <RandomIconsScreen
      description={t("quiz.moon_rising_venus")}
      currentScreenIndex={currentScreenIndex}
      appendAnswerToParams={appendAnswerToParams}
    />,
    <BirthPlaceScreen
      currentScreenIndex={currentScreenIndex}
      appendAnswerToParams={appendAnswerToParams}
    />,
    <BirthTimeScreen
      currentScreenIndex={currentScreenIndex}
      appendAnswerToParams={appendAnswerToParams}
    />,
    <LoadingScreen
      currentScreenIndex={currentScreenIndex}
      appendAnswerToParams={appendAnswerToParams}
    />,
    <LoveSignsScreens
      currentScreenIndex={currentScreenIndex}
      appendAnswerToParams={appendAnswerToParams}
    />,
    <ListScreen
      title={""}
      description={t("questionLove.label")}
      questions={[
        {
          title: t("questionLove.right_person"),
          value: 0,
          onQuestionClick: (value: number) =>
            appendAnswerToParams({
              key: "loveQuestion",
              value,
              nextPageIndex: currentScreenIndex + 1,
            }),
        },
        {
          title: t("questionLove.relationship_last"),
          value: 1,
          onQuestionClick: (value: number) =>
            appendAnswerToParams({
              key: "loveQuestion",
              value,
              nextPageIndex: currentScreenIndex + 1,
            }),
        },
        {
          title: t("questionLove.keep_waiting"),
          value: 2,
          onQuestionClick: (value: number) =>
            appendAnswerToParams({
              key: "loveQuestion",
              value,
              nextPageIndex: currentScreenIndex + 1,
            }),
        },
        {
          title: t("questionLove.astrology_help"),
          value: 3,
          onQuestionClick: (value: number) =>
            appendAnswerToParams({
              key: "loveQuestion",
              value,
              nextPageIndex: currentScreenIndex + 1,
            }),
        },
        {
          title: t("questionLove.not_sure"),
          value: 4,
          onQuestionClick: (value: number) =>
            appendAnswerToParams({
              key: "loveQuestion",
              value,
              nextPageIndex: currentScreenIndex + 1,
            }),
        },
      ]}
    />,
    <ListScreen
      title={""}
      description={t("loveLife2.label")}
      questions={[
        {
          title: t("loveLife2.right_person"),
          value: 0,
          onQuestionClick: (value: number) =>
            appendAnswerToParams({
              key: "loveLifeFeeling",
              value,
              nextPageIndex: currentScreenIndex + 1,
            }),
        },
        {
          title: t("loveLife2.time_to_date"),
          value: 1,
          onQuestionClick: (value: number) =>
            appendAnswerToParams({
              key: "loveLifeFeeling",
              value,
              nextPageIndex: currentScreenIndex + 1,
            }),
        },
        {
          title: t("loveLife2.right_partner"),
          value: 2,
          onQuestionClick: (value: number) =>
            appendAnswerToParams({
              key: "loveLifeFeeling",
              value,
              nextPageIndex: currentScreenIndex + 1,
            }),
        },
        {
          title: t("loveLife2.guidance"),
          value: 3,
          onQuestionClick: (value: number) =>
            appendAnswerToParams({
              key: "loveLifeFeeling",
              value,
              nextPageIndex: currentScreenIndex + 1,
            }),
        },
        {
          title: t("loveLife2.not_sure"),
          value: 4,
          onQuestionClick: (value: number) =>
            appendAnswerToParams({
              key: "loveLifeFeeling",
              value,
              nextPageIndex: currentScreenIndex + 1,
            }),
        },
      ]}
    />,
    <ListScreen
      title={""}
      description={t("affectingRelationship.label")}
      questions={[
        {
          title: t("affectingRelationship.infidelity"),
          value: 0,
          onQuestionClick: (value: number) =>
            appendAnswerToParams({
              key: "relationshipIssues",
              value,
              nextPageIndex: currentScreenIndex + 1,
            }),
        },
        {
          title: t("affectingRelationship.depression"),
          value: 1,
          onQuestionClick: (value: number) =>
            appendAnswerToParams({
              key: "relationshipIssues",
              value,
              nextPageIndex: currentScreenIndex + 1,
            }),
        },
        {
          title: t("affectingRelationship.low_sexual_desire"),
          value: 2,
          onQuestionClick: (value: number) =>
            appendAnswerToParams({
              key: "relationshipIssues",
              value,
              nextPageIndex: currentScreenIndex + 1,
            }),
        },
        {
          title: t("affectingRelationship.postpartum_depression"),
          value: 3,
          onQuestionClick: (value: number) =>
            appendAnswerToParams({
              key: "relationshipIssues",
              value,
              nextPageIndex: currentScreenIndex + 1,
            }),
        },
        {
          title: t("affectingRelationship.anxiety"),
          value: 4,
          onQuestionClick: (value: number) =>
            appendAnswerToParams({
              key: "relationshipIssues",
              value,
              nextPageIndex: currentScreenIndex + 1,
            }),
        },
      ]}
    />,
    <EducationScreen
      img={RomComPng}
      description={t("affectingRelationship.experience_romance")}
      currentScreenIndex={currentScreenIndex}
      appendAnswerToParams={appendAnswerToParams}
    />,
    <ListScreen
      title={""}
      description={t("worries.label")}
      questions={[
        {
          title: t("worries.true_love"),
          value: 0,
          onQuestionClick: (value: number) =>
            appendAnswerToParams({
              key: "loveWorries",
              value,
              nextPageIndex: currentScreenIndex + 1,
            }),
        },
        {
          title: t("worries.wrong_person"),
          value: 1,
          onQuestionClick: (value: number) =>
            appendAnswerToParams({
              key: "loveWorries",
              value,
              nextPageIndex: currentScreenIndex + 1,
            }),
        },
        {
          title: t("worries.average_match"),
          value: 2,
          onQuestionClick: (value: number) =>
            appendAnswerToParams({
              key: "loveWorries",
              value,
              nextPageIndex: currentScreenIndex + 1,
            }),
        },
        {
          title: t("worries.not_sure"),
          value: 3,
          onQuestionClick: (value: number) =>
            appendAnswerToParams({
              key: "loveWorries",
              value,
              nextPageIndex: currentScreenIndex + 1,
            }),
        },
      ]}
    />,
    <ListScreen
      title={""}
      description={t("relationshipGoals.label")}
      questions={[
        {
          title: t("relationshipGoals.reach_goals"),
          value: 0,
          onQuestionClick: (value: number) =>
            appendAnswerToParams({
              key: "relationshipGoalsFeeling",
              value,
              nextPageIndex: currentScreenIndex + 1,
            }),
        },
        {
          title: t("relationshipGoals.feel_unsure"),
          value: 1,
          onQuestionClick: (value: number) =>
            appendAnswerToParams({
              key: "relationshipGoalsFeeling",
              value,
              nextPageIndex: currentScreenIndex + 1,
            }),
        },
        {
          title: t("relationshipGoals.feel_anxious"),
          value: 2,
          onQuestionClick: (value: number) =>
            appendAnswerToParams({
              key: "relationshipGoalsFeeling",
              value,
              nextPageIndex: currentScreenIndex + 1,
            }),
        },
        {
          title: t("relationshipGoals.not_sure"),
          value: 3,
          onQuestionClick: (value: number) =>
            appendAnswerToParams({
              key: "relationshipGoalsFeeling",
              value,
              nextPageIndex: currentScreenIndex + 1,
            }),
        },
      ]}
    />,
    <TarotCardScreen2
      currentScreenIndex={currentScreenIndex}
      appendAnswerToParams={appendAnswerToParams}
    />,
    <EmailScreen
      currentScreenIndex={currentScreenIndex}
      appendAnswerToParams={appendAnswerToParams}
    />,
    <ListScreen
      title={""}
      description={t("futureLove.label")}
      questions={[
        {
          title: t("futureLove.right_person"),
          value: 0,
          onQuestionClick: (value: number) =>
            appendAnswerToParams({
              key: "futureLoveLife",
              value,
              nextPageIndex: currentScreenIndex + 1,
            }),
        },
        {
          title: t("futureLove.prefect_for_me"),
          value: 1,
          onQuestionClick: (value: number) =>
            appendAnswerToParams({
              key: "futureLoveLife",
              value,
              nextPageIndex: currentScreenIndex + 1,
            }),
        },
        {
          title: t("futureLove.compatibility"),
          value: 2,
          onQuestionClick: (value: number) =>
            appendAnswerToParams({
              key: "futureLoveLife",
              value,
              nextPageIndex: currentScreenIndex + 1,
            }),
        },
        {
          title: t("futureLove.stopping_me"),
          value: 3,
          onQuestionClick: (value: number) =>
            appendAnswerToParams({
              key: "futureLoveLife",
              value,
              nextPageIndex: currentScreenIndex + 1,
            }),
        },
        {
          title: t("futureLove.not_sure"),
          value: 4,
          onQuestionClick: (value: number) =>
            appendAnswerToParams({
              key: "futureLoveLife",
              value,
              nextPageIndex: currentScreenIndex + 1,
            }),
        },
      ]}
    />,
    <ListScreen
      title={""}
      description={t("datingPeople.label")}
      questions={[
        {
          title: t("datingPeople.excited"),
          value: 0,
          onQuestionClick: (value: number) =>
            appendAnswerToParams({
              key: "partnerFeeling",
              value,
              nextPageIndex: currentScreenIndex + 1,
            }),
        },
        {
          title: t("datingPeople.nervous"),
          value: 1,
          onQuestionClick: (value: number) =>
            appendAnswerToParams({
              key: "partnerFeeling",
              value,
              nextPageIndex: currentScreenIndex + 1,
            }),
        },
        {
          title: t("datingPeople.curious"),
          value: 2,
          onQuestionClick: (value: number) =>
            appendAnswerToParams({
              key: "partnerFeeling",
              value,
              nextPageIndex: currentScreenIndex + 1,
            }),
        },
        {
          title: t("datingPeople.casual"),
          value: 3,
          onQuestionClick: (value: number) =>
            appendAnswerToParams({
              key: "partnerFeeling",
              value,
              nextPageIndex: currentScreenIndex + 1,
            }),
        },
        {
          title: t("datingPeople.not_sure"),
          value: 4,
          onQuestionClick: (value: number) =>
            appendAnswerToParams({
              key: "partnerFeeling",
              value,
              nextPageIndex: currentScreenIndex + 1,
            }),
        },
      ]}
    />,
    <ListScreen
      title={""}
      description={t("nextDate.label")}
      questions={[
        {
          title: t("nextDate.2_3_days"),
          value: 0,
          onQuestionClick: (value: number) =>
            appendAnswerToParams({
              key: "nextDate",
              value,
              nextPageIndex: currentScreenIndex + 1,
            }),
        },
        {
          title: t("nextDate.week"),
          value: 1,
          onQuestionClick: (value: number) =>
            appendAnswerToParams({
              key: "nextDate",
              value,
              nextPageIndex: currentScreenIndex + 1,
            }),
        },
        {
          title: t("nextDate.weeks"),
          value: 2,
          onQuestionClick: (value: number) =>
            appendAnswerToParams({
              key: "nextDate",
              value,
              nextPageIndex: currentScreenIndex + 1,
            }),
        },
        {
          title: t("nextDate.month"),
          value: 3,
          onQuestionClick: (value: number) =>
            appendAnswerToParams({
              key: "nextDate",
              value,
              nextPageIndex: currentScreenIndex + 1,
            }),
        },
        {
          title: t("nextDate.months"),
          value: 4,
          onQuestionClick: (value: number) =>
            appendAnswerToParams({
              key: "nextDate",
              value,
              nextPageIndex: currentScreenIndex + 1,
            }),
        },
        {
          title: t("nextDate.none"),
          value: 5,
          onQuestionClick: (value: number) =>
            appendAnswerToParams({
              key: "nextDate",
              value,
              nextPageIndex: currentScreenIndex + 1,
            }),
        },
      ]}
    />,
    <WeHaveHelped
      currentScreenIndex={currentScreenIndex}
      appendAnswerToParams={appendAnswerToParams}
    />,
    <ChoosePriceScreen
      currentScreenIndex={currentScreenIndex}
      appendAnswerToParams={appendAnswerToParams}
    />,
    !hideDeviceScreen && (
      <DeviceScreen
        appendAnswerToParams={appendAnswerToParams}
        currentScreenIndex={currentScreenIndex}
      />
    ),
    <ConversionScreen />,
  ];

  return compact(screens)[currentScreenIndex];
};

export const Quiz2: React.FC<Quiz2Props> = ({}) => {
  const {
    currentScreenIndex,
    appendAnswerToParams,
    setCurrentScreenIndex,
    goBack,
    hideDeviceScreen,
  } = useQuiz2();

  const { isBelowXxs } = useBreakpoint("xxs");
  const { t } = useTranslation();

  return (
    <div className="flex flex-col w-full max-w-192 mobile:max-w-[313px] items-center">
      <React.Suspense fallback={<Loading />}>
        <div
          className={classNames("mb-8", {
            hidden: currentScreenIndex > 24 || currentScreenIndex === 0,
          })}
        >
          <MoonProgress
            currentIndex={currentScreenIndex}
            totalPages={25}
            dashCount={isBelowXxs ? 40 : 45}
            activeIndexCheckpoints={[0, 5, 8, 24]}
          />
        </div>

        {currentScreenIndex !== 0 && currentScreenIndex < 24 && (
          <Button
            isIconOnly
            className={classNames(
              "bg-transparent rounded !p-2 border-0 absolute self-center top-11 left-1/4 mobile:top-10 mobile:left-2",
              quizStyles.animatedBtnGradient
            )}
            onClick={() => {
              // Wrap state updates that may suspend in startTransition
              startTransition(() => {
                goBack();
              });
            }}
            variant="faded"
          >
            <img src={YellowLeftArrow} alt="back" />
          </Button>
        )}

        <div className="h-full">
          {getScreen({
            currentScreenIndex,
            appendAnswerToParams,
            setCurrentScreenIndex,
            hideDeviceScreen,
            t,
          })}
        </div>
        {currentScreenIndex !== 0 && (
          <div
            className={classNames("mobile:scale-75 mobile:bottom-5", {
              "relative my-10": currentScreenIndex === 14,
              "my-3 bottom-0": currentScreenIndex !== 14,
            })}
          >
            <img src={MoreReadingsLogoSvg} alt="more readings" />
          </div>
        )}
      </React.Suspense>
    </div>
  );
};
